import { useState } from "react";
import Modal from "../ui/modal";
// import { postBookingData } from "../../data_featching/booking";
import PlanSelection from "./PlanSelection";
import DateAndTimeSelection from "./DateAndTimeSelection";
import ResourcesSelection from "./ResourcesSelection";
import PreviewBooking from "./PreviewBooking";
import { BookingProvider } from "../../context/BookingProvider";
import { useTranslation } from "react-i18next";
import BookingsRequested from "./BookingsRequested";

function BookingModal({ isOpen, setIsOpen, spaceDetails }) {
  const { t } = useTranslation("bookings");
  const [currentStep, setCurrentStep] = useState(1);
  const [planType, setPlanType] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [bookedShift, setBookedShift] = useState(null);
  const [isInstantBooking, setIsInstantBooking] = useState(false);
  const [initialBookingData, setInitialBookingData] = useState({});
  const [shiftType, setShiftType] = useState(null);
  // const [bookedResources, setBookedResources] = useState([]);

  const updateInitialBookingData = (data) => {
    setInitialBookingData(data);
  };
  const updateBookedShift = (data) => {
    setBookedShift(data);
  };

  const updateInstantBooking = (data) => {
    setIsInstantBooking(data);
  };

  const handlePlanSelect = (planType) => {
    setPlanType(planType);
    setCurrentStep(2);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handlePlanID = (id) => {
    setPlanId(id);
  };
  const getModalContent = (step) => {
    switch (step) {
      case 1:
        return {
          title: t("Plan Selection"),
          description: t("Select the plan that fits your requirements"),
          progress: 25,
        };
      case 2:
        return {
          title: t("Duration"),
          description: t("Select the desired Duration"),
          progress: 50,
        };
      case 3:
        return {
          title: t("Resources"),
          description: t("Select the desired resources"),
          progress: 75,
        };
      case 4:
        return {
          title: t("Check Out"),
          description: t("Review booking details"),
          progress: 100,
        };
      default:
        return {
          title: "",
          description: "",
        };
    }
  };

  const { title, description, progress } = getModalContent(currentStep);

  return (
    <BookingProvider>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={title}
        Description={description}
        progress={progress}
      >
        <div>
          {currentStep === 1 && (
            <PlanSelection
              spaceDetails={spaceDetails}
              onPlanSelect={handlePlanSelect}
            />
          )}
          {currentStep === 2 && (
            <DateAndTimeSelection
              spaceDetails={spaceDetails}
              planType={planType}
              onBack={handleBack}
              setCurrentStep={setCurrentStep}
              initialBookingData={initialBookingData}
              updateInitialBookingData={updateInitialBookingData}
              updateInstantBooking={updateInstantBooking}
              setShiftType={setShiftType}
              updateBookedShift={updateBookedShift}
              handlePlanID={handlePlanID}
            />
          )}
          {currentStep === 3 && (
            <ResourcesSelection
              spaceDetails={spaceDetails}
              planType={planType}
              planId={planId}
              onBack={handleBack}
              setCurrentStep={setCurrentStep}
              initialBookingData={initialBookingData}
              updateInitialBookingData={updateInitialBookingData}
              // bookedResources={bookedResources}
              // setBookedResources={setBookedResources}
            />
          )}
          {currentStep === 4 && (
            <PreviewBooking
              spaceDetails={spaceDetails}
              planType={planType}
              onBack={handleBack}
              setCurrentStep={setCurrentStep}
              initialBookingData={initialBookingData}
              shiftType={shiftType}
              bookedShift={bookedShift}
              isInstantBooking={isInstantBooking}
            />
          )}
          {currentStep === 5 && <BookingsRequested />}
        </div>
      </Modal>
    </BookingProvider>
  );
}

export default BookingModal;
BookingModal.propTypes = null;
