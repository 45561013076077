/* eslint-disable react/prop-types */
import { useEffect, useLayoutEffect, useState } from "react";
import "./PersonalRegister.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import { parsePhoneNumber } from "libphonenumber-js";
import Verification from "../verification/Verification";
import SetPassword from "../set_password/SetPassword";
import { TermsModal } from "../../ui/Models";

import {
  registrationValidationOnly,
  sendRegistrationOtp,
} from "../../../data_featching/registeration";
import { useTranslation } from "react-i18next";
import {
  fetchUserProfile,
  GoogleLoginWithBE,
} from "../../../data_featching/GoogleLogin.js";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
const googleClientId = import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID;
const linkedInClientId = import.meta.env.VITE_REACT_APP_LinkedIn_CLIENT_ID;
import {
  LinkedInAccessTokenExchange,
  linkedInLoginWithBE,
} from "../../../data_featching/LinkedInLogin.js";
const PersonalRegister = ({ profileType }) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <PersonalRegisterInto profileType={profileType} />
    </GoogleOAuthProvider>
  );
};
const PersonalRegisterInto = ({ profileType }) => {
  console.log("profileType", profileType);
  const [showNextButton, setShowNextButton] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  // const [countryCode, setCountryCode] = useState("");
  // const [nationalNumber, setNationalNumber] = useState("");
  const [nameInput, setNameInput] = useState({ first: "", family: "" });
  const [registerWithNafath, setRegisterWithNafath] = useState(false);
  const [registeredWithNafath, setRegisteredWithNafath] = useState(false);
  const [idNumber, setIdNumber] = useState("");
  /*verification phase */
  const [showVerificationPhase, setShowVerificationPhase] = useState(false);
  const [verificationNum, setVerificationNum] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
  });
  const [showSetPassword, setShowSetPassword] = useState(false);
  /*set password phase */
  const [password, setPassword] = useState("");

  /*show error message */
  const [nameError, setNameError] = useState({
    firstNameError: "",
    lastNameError: "",
  });
  const [showEmailError, setShowEmailError] = useState("");
  const [showPhoneError, setShowPhoneError] = useState("");
  const [VERIFIATCAION_ID, setVERIFIATCAION_ID] = useState("");
  const [VERIFIATCAION_CODE, setVERIFIATCAION_CODE] = useState(0);
  const [LinkedInAccessToken, setLinkedInAccessToken] = useState(null);
  const [LinkedInAuthorizationCode, setLinkedInAuthorizationCodeode] =
    useState(null);
  const { t, i18n } = useTranslation("register");
  /*Navigattion */
  const navigate = useNavigate();
  /*set the Saudi Arabia flag as design not from the libirary flags*/
  const flags = {
    SA: () => (
      <img
        src="/KSA.png"
        alt="Saudi Arabia Flag"
        // style={{ width: "20px", height: "15px" }}
      />
    ),
    // Add other flags if needed
  };
  /*front end Form validation */
  const formValidation = () => {
    if (!emailInput.trim()) {
      return false;
    }
    if (!phoneInput) {
      return false;
    }

    if (!nameInput.first.trim()) {
      return false;
    }
    if (!nameInput.family.trim()) {
      return false;
    }
    if (!agreeTerms) {
      return false;
    }
    return true;
  };
  function handelPhoneInput(value) {
    setPhoneInput(value);
  }
  /*social login Google Login*/
  const googleLogin = useGoogleLogin({
    // flow: "auth-code", // comment this to get Access token

    onSuccess: async (codeResponse) => {
      console.log("codeResponse", codeResponse);

      // Call the function once you get the access token
      const result = await fetchUserProfile(codeResponse.access_token);
      console.log("google Login result", result);
      if (result.status == 200 || result.status == 201) {
        //you should handel to get token from the BE
        const dataForBe = {
          provider: "google",
          access_token: codeResponse.access_token,
          profile: "personal",
        };
        const backEndResult = await GoogleLoginWithBE(dataForBe);
        console.log(" Login With BE after google Login", backEndResult);

        if (backEndResult.status == 200 || backEndResult.status == 201) {
          const { token: BE_Token } = backEndResult.data.data;
          localStorage.setItem("authToken", BE_Token); // Store token in localStorage
          navigate("/");
        }
      }
    },
    onError: (errorResponse) => console.log("Error response ", errorResponse),
  });
  /*Linked in Login */
  /*call LinkedIn to get Authorization code */
  const CLIENT_ID = linkedInClientId;
  // const REDIRECT_URI = "http://localhost:5173/login";// for development
  const REDIRECT_URI = `${import.meta.env.VITE_CUSTOMER_URL}/login`; //for production
  const state = "760iz0bjh9gy71asfFqa";
  const SCOPE = "openid profile email "; // Adjust scopes as needed
  const code_challenge = "mb38T1LdP3cjL_0Ms8_J2j1gmP7ugHRn5Uufxri4tEg";
  const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
    REDIRECT_URI
  )}&scope=${encodeURIComponent(
    SCOPE
  )} &state=${state}&code_challenge=${code_challenge}&code_challenge_method="S256" `;
  const handelLinkedINLogin = () => {
    window.location.href = authorizationUrl;
  };
  console.log("REDIRECT_URI", REDIRECT_URI);
  /*this side effect to handle LinkedIn redirection to the Login  */
  useEffect(() => {
    // Get the query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);
    console.log("searchParams", searchParams);
    // Check if there is a "code" parameter in the URL
    const code = searchParams.get("code");
    if (code) {
      setLinkedInAuthorizationCodeode(code);
      console.log("authorizationCode", code);
    }
    console.log("authorizationCode", LinkedInAuthorizationCode);

    // Optionally handle errors if they exist
    const error = searchParams.get("error");
    if (error) {
      console.error(
        "Error during authorization:",
        searchParams.get("error_description")
      );
    }
  }, []);
  /* Once you have the LinkedIn Authorization Code, you can exchange it for an access token*/
  useEffect(() => {
    const getAccessToken = async () => {
      const response = await LinkedInAccessTokenExchange(
        LinkedInAuthorizationCode
      );
      if (response.status == 200 || response.status == 201) {
        console.log("getAccessToken:response", response);
        console.log("LinkedInAccessToken", response?.data?.data?.access_token);
        setLinkedInAccessToken(response?.data?.data?.access_token);
      }
      if (response.status !== 200 || response.status !== 201) {
        // setLoginError(response?.data?.message); //show social login error
      }
      console.log("LinkedInAuthorizationCode Code:", LinkedInAuthorizationCode);
    };
    // Now you can use the authorization code to get the access token
    if (LinkedInAuthorizationCode) {
      getAccessToken(); // Call your function to exchange the code for an access token
    }
  }, [LinkedInAuthorizationCode]);
  /*get token from BE after get LinkedIn access token */
  useEffect(() => {
    const LoginWithLinkedIn = async () => {
      const dataForBe = {
        provider: "linkedin-openid",
        access_token: LinkedInAccessToken,
        profile: "personal",
      };
      const accessTokenResultFromBE = await linkedInLoginWithBE(dataForBe);
      if (
        accessTokenResultFromBE.status == 200 ||
        accessTokenResultFromBE.status == 201
      ) {
        const { token: BE_Token } = accessTokenResultFromBE.data.data;
        localStorage.setItem("authToken", BE_Token); // Store token in localStorage
        navigate("/");
      }
    };
    if (LinkedInAccessToken) LoginWithLinkedIn();
  }, [LinkedInAccessToken]);
  /*function send otp request to API */
  /*function send otp request to API */
  const sendOtp = async () => {
    const formData = new FormData();
    formData.append("phone", phoneInput);

    const result = await sendRegistrationOtp(formData);
    if (result.status == 200 || result.status == 201) {
      setVERIFIATCAION_ID(result.data.data.id);
      console.log("sucess res", result);
      // console.log("VERIFIATCAION_ID", VERIFIATCAION_ID);
    } else if (result.status !== 201) {
      console.log("result.data.errors;", result);
    }
  };
  /*first form submiting */
  async function handleFirstPhaseSubmit() {
    /*front end validation */
    if (formValidation()) {
      const formData = new FormData();
      // Append Form data
      formData.append("first_name", nameInput.first);
      formData.append("last_name", nameInput.family);
      formData.append("phone", phoneInput);
      formData.append("email", emailInput);
      formData.append("profile", profileType);

      /*send data form to check validation */
      const result = await registrationValidationOnly(formData);
      /*Validation failed from API */
      if (result.status == 200 || result.status == 201) {
        /*if API validate the first form pass to verification phase */
        setShowVerificationPhase(true);
        sendOtp(); /*resend OTP request */
      } else if (result.status !== 200 || result.status !== 201) {
        const Errors = result.data.errors;
        console.log("personalRegister result  error", Errors);
        /*email not valid from Api */
        if ("email" in Errors) {
          console.log("email error", Errors.email[0]);
          setShowEmailError(Errors.email[0]);
        }
        /*phone not valid from Api */
        if ("phone" in Errors) {
          console.log("phone error", Errors.phone[0]);
          setShowPhoneError(Errors.phone[0]);
        }
        if ("first_name" in Errors) {
          console.log("phone error", Errors.first_name[0]);
          setNameError((prev) => ({
            ...prev,
            firstNameError: Errors.first_name[0],
          }));
        }
        if ("last_name" in Errors) {
          console.log("phone error", Errors?.last_name[0]);
          setNameError((prev) => ({
            ...prev,
            lastNameError: Errors?.last_name[0],
          }));
        }
      }
    }
  }
  /*show next Button only when pass front validation */
  useEffect(() => {
    if (formValidation()) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  }, [nameInput, emailInput, phoneInput, agreeTerms]);

  /*handle error show and welcom page  */
  useLayoutEffect(() => {
    if (nameError.firstNameError) {
      let timer;
      timer = setTimeout(() => {
        setNameError((prev) => ({ ...prev, firstNameError: "" }));
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
    if (nameError.lastNameError) {
      let timer;
      timer = setTimeout(() => {
        setNameError((prev) => ({ ...prev, lastNameError: "" }));
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
    if (showEmailError) {
      let timer;
      timer = setTimeout(() => {
        setShowEmailError("");
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
    if (showPhoneError) {
      let timer;
      timer = setTimeout(() => {
        setShowPhoneError("");
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showEmailError, showPhoneError]);
  return (
    <>
      {showTermsModal && (
        <TermsModal
          isOpen={showTermsModal}
          onClose={() => setShowTermsModal(false)}
        />
      )}
      {showVerificationPhase ? (
        <Verification
          verificationNum={verificationNum}
          setVerificationNum={setVerificationNum}
          setShowSetPassword={setShowSetPassword}
          setShowVerificationPhase={setShowVerificationPhase}
          sendOtp={sendOtp}
          VERIFIATCAION_ID={VERIFIATCAION_ID}
          VERIFIATCAION_CODE={VERIFIATCAION_CODE}
          setVERIFIATCAION_CODE={setVERIFIATCAION_CODE}
        />
      ) : !showSetPassword && !registerWithNafath ? (
        <div className="personal_main_container sm:mx-0 xl:mx-[7rem]  sm:w-full lg:w-[65%] xl:w-[36rem]">
          <div className="w-full Header_register_phase">
            <h1>{t("create")}</h1>
            <div className="flex gap-1.5 items-start">
              <div className="phase Active_phase"></div>
              <div className="phase"></div>
              <div className="phase"></div>
            </div>
          </div>
          <form
            className="flex flex-col justify-start w-full gap-8 personal_registerForm"
            onSubmit={(event) => {
              event.preventDefault();
              handleFirstPhaseSubmit();
            }}
          >
            <div className="flex flex-col w-full gap-2">
              <div className="flex md:gap-6 sm:gap-2">
                <div className="flex flex-col w-1/2 xl:w-[48%] sm:w-[48%] gap-2">
                  <label htmlFor="" className="name_input_text">
                    {t("first")}
                  </label>
                  <input
                    type="text"
                    className="name_input_personal"
                    onChange={(e) => {
                      if (e.target.value.length < 20) {
                        setNameInput((prev) => {
                          return { ...prev, first: e.target.value };
                        });
                      }
                    }}
                    value={nameInput.first}
                  />
                </div>
                <div className="flex flex-col w-1/2 xl:w-[48%]  sm:w-[48%] gap-2">
                  <label htmlFor="" className="name_input_text">
                    {t("family")}
                  </label>
                  <input
                    type="text"
                    className="name_input_personal"
                    onChange={(e) => {
                      if (e.target.value.length < 20) {
                        setNameInput((prev) => {
                          return { ...prev, family: e.target.value };
                        });
                      }
                    }}
                    value={nameInput.family}
                  />
                </div>
              </div>
              {nameError.firstNameError && (
                <div style={{ color: "red" }}>{nameError.firstNameError}</div>
              )}
              {nameError.lastNameError && (
                <div style={{ color: "red" }}>{nameError.lastNameError}</div>
              )}
            </div>
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="" className="name_input_text">
                {t("email")}
              </label>
              <input
                type="text"
                className="name_input_personal"
                onChange={(e) => setEmailInput(e.target.value)}
                value={emailInput}
              />
              {showEmailError && (
                <div style={{ color: "red" }}>{showEmailError}</div>
              )}
            </div>

            <div className="flex w-full gap-6">
              <div className="flex flex-col w-full gap-2" dir="ltr">
                <label htmlFor="" className="name_input_text rtl:text-right">
                  {t("phone")}
                </label>
                <PhoneInput
                  international={false}
                  defaultCountry="SA"
                  value={phoneInput}
                  onChange={handelPhoneInput}
                  flags={flags}
                />
                {showPhoneError && (
                  <div style={{ color: "red" }}>{showPhoneError}</div>
                )}
              </div>
            </div>
            {/* terms and conditon check */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="check_box border border-[#6B7280] rounded-sm"
                onChange={(e) => {
                  setAgreeTerms(e.target.checked);
                }}
              />
              <span
                className="underline cursor-pointer name_input_text"
                onClick={() => {
                  setShowTermsModal(true);
                }}
              >
                {t("Agree to terms & conditions")}
              </span>
            </div>
            <button
              className="w-full next_button"
              style={showNextButton ? { backgroundColor: "#009e1e" } : null}
            >
              <span className="flex items-center gap-2">
                {t("next")}
                {i18n.language === "ar" ? (
                  <svg
                    className="mt-[5px]"
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    color="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 11L2 6L7 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12 11L7 6L12 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    className="mt-[5px]"
                    width="13"
                    color="white"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 11L11 6L6 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M1 11L6 6L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                )}
              </span>
            </button>
          </form>
          <div className="flex w-full gap-4 social_buttons">
            <button
              className="submit_form_social w-50%"
              onClick={handelLinkedINLogin}
            >
              <img
                src="/LinkedIn_Logo.png
            "
                alt="lock-closed img"
              />
              <span>{t("register with linkedin")}</span>
            </button>
            <button className="submit_form_social w-50%" onClick={googleLogin}>
              <img
                src="/Google_Logo.png
            "
                alt="lock-closed img"
              />
              <span>{t("register with google")}</span>
            </button>
          </div>
          <div className="flex w-full gap-4 social_buttons mt-[-1rem]">
            <button
              onClick={() => {
                setRegisterWithNafath(true);
              }}
              className="flex w-full gap-2 submit_form_social"
            >
              <span className="text-base font-medium">
                {t("register with")}
              </span>
              <div className="w-[36px] h-[20px] mt-[-2px]">
                <img
                  src="/nafath_logo.png"
                  alt="lock-closed img"
                  className="object-cover w-full h-full"
                />
              </div>
            </button>
          </div>
        </div>
      ) : !showSetPassword && registerWithNafath && !registeredWithNafath ? (
        <div className="bg-white p-5 shadow-sm w-[34.438rem] border-[0.188rem] rounded-2xl border-[#C8CACC] my-[8%] sm:w-[28.75rem] sm:mx-0 xl:mx-[7rem]">
          <form>
            <h1 className="text-[1.75rem] font-bold text-center w-full mb-8">
              {t("nafath account")}
            </h1>
            <div className="mb-8">
              <label
                htmlFor="id-number"
                className="block mb-2 text-base font-medium"
              >
                {t("id number")}
              </label>
              <input
                value={idNumber}
                onChange={(e) => {
                  setIdNumber(e.target.value);
                }}
                type="number"
                id="id-number"
                placeholder={t("id placeholder")}
                className="px-3 py-2 text-[#9CA3AF] font-normal text-sm outline-none border w-full rounded-lg border-[#6B7280]"
              />
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (idNumber) {
                  setRegisteredWithNafath(true);
                }
              }}
              className="submit_form"
              style={
                idNumber !== ""
                  ? { backgroundColor: "var(--Primary2, #009e1e)" }
                  : null
              }
            >
              <span>{t("register")}</span>
              <img
                src="/lock-closed-white.png
  "
                alt="lock-closed img"
              />
            </button>
          </form>
        </div>
      ) : !showSetPassword && registeredWithNafath ? (
        <div className="bg-white p-5 shadow-sm my-[8%] sm:w-[28.75rem] sm:mx-0 xl:mx-[7rem] w-[34.438rem] border-[0.188rem] rounded-2xl border-[#C8CACC] ">
          <h1 className="text-[1.75rem] font-bold text-center w-full mb-4">
            {t("nafath verify")}
          </h1>
          <p className="text-[#6B7280] mb-8 font-normal text-lg text-center">
            {t("plz open app")}
          </p>
          <p className="px-6 text-center w-[5.188rem] py-4 bg-[#B8E9B7] rounded-lg mx-auto font-medium text-2xl">
            28
          </p>
        </div>
      ) : (
        <SetPassword
          setShowVerificationPhase={setShowVerificationPhase}
          password={password}
          setPassword={setPassword}
          setShowSetPassword={setShowSetPassword}
          nameInput={nameInput}
          phoneInput={phoneInput}
          emailInput={emailInput}
          VERIFIATCAION_CODE={VERIFIATCAION_CODE}
          VERIFIATCAION_ID={VERIFIATCAION_ID}
          profileType={profileType}
        />
      )}
    </>
  );
};

export default PersonalRegister;
