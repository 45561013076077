import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { loginUserInfoRequest } from "../../data_featching/login";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CompleteProfile from "./CompleteProfile";
import SuccessMessage from "./SuccessMessage";
import EmailAndPhoneHint from "./EmailAndPhoneHint";
import { updateUserPersonalInfo } from "../../data_featching/UpdateProfile";
import LanguageContext from "../../context/LanguageProvider";
import AuthContext from "../../context/AuthProvider";

function PersonalInfo() {
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userData, setUserData] = useState();
  const [pickedImage, setPickedImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [imageChanged, setimageChanged] = useState(false);
  const [goToComplete, setGoToComplete] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showCompletedProfile, setShowCompletedProfile] = useState(false);
  const [error, setError] = useState("");
  const [showHint, setShowHint] = useState(false);
  const token = localStorage.getItem("authToken");
  const fileInputRef = useRef(null);
  const timeoutRef = useRef(null);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { t } = useTranslation("profile");

  function validateName(name) {
    const pattern = /^[A-Za-z]+$/;
    return pattern.test(name);
  }
  // console.log("userInfo", userInfo);
  useEffect(() => {
    if (userInfo?.completed_profile === true && hasCompleted) {
      setShowCompletedProfile(true);

      timeoutRef.current = setTimeout(() => {
        setShowCompletedProfile(false);
      }, 8000);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [userInfo?.completed_profile, hasCompleted]);

  useEffect(() => {
    setUserData(userInfo);
    setFirstName(userInfo?.first_name);
    setLastName(userInfo?.last_name);
    setPickedImage(userInfo?.profile_image?.url);
  }, [userInfo]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setUserData((userData) => ({ ...userData, profile_image: file }));
    setimageChanged(true);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPickedImage(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  };

  function handleClick() {
    setIsEditMode(true);
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  function cancelEditMode() {
    setFirstName(userInfo.first_name);
    setLastName(userInfo.last_name);
    setIsEditMode(false);
  }

  function setUserFirstName(e) {
    if (e.target.value.length < 20) {
      setFirstName(e.target.value);
      setUserData((userData) => ({
        ...userData,
        first_name: e.target.value,
      }));
    }
  }
  function setUserLastName(e) {
    if (e.target.value.length < 20) {
      setLastName(e.target.value);
      setUserData((userData) => ({
        ...userData,
        last_name: e.target.value,
      }));
    }
  }

  const saveInfo = async () => {
    setIsClicked(true);
    if (validateName(firstName) && validateName(lastName)) {
      let requiredFields;

      if (userData?.profile_image === "" || !imageChanged) {
        requiredFields = {
          first_name: userData?.first_name,
          last_name: userData?.last_name,
        };
      } else {
        requiredFields = {
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          profile_image: userData?.profile_image,
        };
      }

      const formData = new FormData();
      Object.entries(requiredFields)?.forEach(([key, value]) => {
        formData.append(key, value);
      });
      try {
        const response = await updateUserPersonalInfo(
          token,
          formData,
          language
        );

        if (response.status == 200 || response.status == 201) {
          setIsEditMode(false);
          setUserData(response?.data?.data);
          setUserInfo(response?.data?.data);

          navigate("/profile");
        }
      } catch (error) {
        setError(error.message);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!userInfo) {
      const getUserInfo = async () => {
        if (token) {
          const result = await loginUserInfoRequest(token);
          if (result?.status === 200 || result?.status === 201) {
            setUserInfo(result?.data?.data);
          }
          console.log("result", result);
        }
      };
      getUserInfo();
    }
  }, [token, hasCompleted]);

  return (
    <div className="flex flex-col w-full h-full px-2 py-6 md:px-6">
      {goToComplete && !hasCompleted && (
        <CompleteProfile
          setGoToComplete={setGoToComplete}
          setHasCompleted={setHasCompleted}
          setShowSuccess={setShowSuccess}
        />
      )}
      {hasCompleted && showSuccess && (
        <SuccessMessage
          setShowSuccess={setShowSuccess}
          title={t("Profile Completed!")}
          subTitle={t("profile is now complete")}
          buttonText={t("ok")}
        />
      )}
      {showCompletedProfile ? (
        <div className="flex items-center py-2 px-4 rounded-t-lg mb-2 bg-[#22C55E]">
          <img
            src="/completed-profile.svg"
            alt=""
            className="w-8 h-8 ltr:mr-4 rtl:ml-4"
          />
          <p className="text-lg font-medium text-white">
            {t("cpompleted profile")}
          </p>
        </div>
      ) : userInfo?.completed_profile === false ? (
        <div className="flex items-center py-2 px-4 rounded-t-lg mb-2 bg-[#F59E0B]">
          <img
            src="/incomplete.svg"
            alt=""
            className="w-8 h-8 ltr:mr-4 rtl:ml-4"
          />
          <p className="text-lg font-medium text-white">
            {t("complete your profile")}
          </p>
          <button
            type="button"
            onClick={() => setGoToComplete(true)}
            className="text-sm ltr:ml-auto rtl:mr-auto font-medium bg-white rounded-lg py-[0.625rem] px-4"
          >
            {t("complete now")}
          </button>
        </div>
      ) : (
        <h3 className="mb-4 text-xl font-semibold">{t("Personal info")}</h3>
      )}

      {showHint && <EmailAndPhoneHint setShowHint={setShowHint} />}

      <h3 className="mb-2 text-xl font-semibold">{t("picture")}</h3>
      <div className="h-[6.25rem] w-[6.25rem] mx-auto mb-4">
        {isEditMode && userInfo?.completed_profile === true ? (
          <div className="">
            <button
              className="w-[6.25rem] relative h-full block mx-auto cursor-pointer outline-none"
              onClick={handleButtonClick}
              type="button"
            >
              <img
                src={pickedImage ? pickedImage : "/profile-placeholder2.jpg"}
                alt="Placeholder"
                className={
                  pickedImage
                    ? "rounded-full  w-[6.25rem] h-[6.25rem]"
                    : " w-full h-full"
                }
              />
              <div className="absolute bottom-[-0.5rem] right-0">
                <img src="/image-edit.svg" alt="" />
              </div>
            </button>

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              className="hidden cursor-pointer"
              id="logo"
            />
          </div>
        ) : (
          <img
            src={userInfo?.profile_image?.url || "/profile-placeholder2.jpg"}
            alt=""
            className="object-cover w-full h-full rounded-full"
          />
        )}
      </div>
      <div className="h-[1px] bg-gray-300"></div>
      <div>
        <div className="flex flex-col gap-2 px-4 md:gap-16 md:flex-row md:items-center">
          <h3 className="text-xl min-w-[8.75rem] font-semibold my-[18px]">
            {t("first_name")}
          </h3>
          {isEditMode ? (
            <input
              type="text"
              value={firstName}
              onChange={setUserFirstName}
              className="text-lg font-normal px-3 py-2 rounded-lg border border-gray-500 outline-none md:w-[33.25rem] mb-2 md:mb-0"
            />
          ) : (
            <p className="mb-2 text-lg font-normal text-gray-500 md:mb-0">
              <span className="ltr:mr-1 rtl:ml-1">{userInfo?.first_name}</span>
            </p>
          )}
        </div>
        <div className="h-[1px] bg-gray-300"></div>
        <div className="flex flex-col gap-2 px-4 md:flex-row md:items-center md:gap-16">
          <h3 className="text-xl min-w-[8.75rem] font-semibold my-[18px]">
            {t("last_name")}
          </h3>
          {isEditMode ? (
            <input
              type="text"
              value={lastName}
              onChange={setUserLastName}
              className="text-lg font-normal mb-2 md:mb-0 px-3 py-2 rounded-lg border border-gray-500 outline-none md:w-[33.25rem]"
            />
          ) : (
            <p className="mb-2 text-lg font-normal text-gray-500 md:mb-0">
              <span className="ltr:mr-1 rtl:ml-1">{userInfo?.last_name}</span>
            </p>
          )}
        </div>
        <div className="h-[1px] bg-gray-300"></div>
        <div className="flex flex-col gap-2 px-4 md:items-center md:flex-row md:gap-16">
          <h3 className="text-xl min-w-[8.75rem] font-semibold my-[18px]">
            {t("Email")}
          </h3>

          <p
            onClick={() => {
              if (isEditMode) {
                setShowHint(true);
              }
            }}
            className="mb-2 text-lg font-normal text-gray-500 cursor-pointer md:mb-0"
          >
            {userInfo?.email}
          </p>
        </div>
        {userInfo?.completed_profile === true && (
          <>
            <div className="h-[1px] bg-gray-300"></div>
            <div className="flex flex-col gap-2 px-4 md:flex-row md:items-center md:gap-16">
              <h3 className="text-xl w-[8.75rem] font-semibold my-[18px]">
                {t("Phone number")}
              </h3>

              <p
                onClick={() => {
                  if (isEditMode) {
                    setShowHint(true);
                  }
                }}
                className="mb-2 text-lg font-normal text-gray-500 cursor-pointer md:mb-0"
              >
                {userInfo?.phone}
              </p>
            </div>
          </>
        )}
        {/* <div className="h-[1px] bg-gray-300"></div> */}
        {error && <p className="my-2 font-medium text-red-400">{error}</p>}
        {!error &&
          isClicked &&
          (!validateName(firstName) || !validateName(lastName)) && (
            <p className="my-2 font-medium text-red-400">{t("name_hint")}</p>
          )}
        {/* <div
          className={`${
            isEditMode ? "justify-between" : "gap-16"
          } flex items-center  px-4`}
        >
          <h3 className="text-xl w-[8.75rem] font-semibold my-[18px]">
            {t("Date of birth")}
          </h3>
          {isEditMode ? (
            <input
              disabled
              type="text"
              defaultValue="10/28/2011"
              className="text-lg font-normal px-3 py-2 rounded-lg border border-gray-500 outline-none w-[33.25rem]"
            />
          ) : (
            <p className="text-lg font-normal text-gray-500">10/28/2011</p>
          )}
        </div> */}
        {/* <div className="h-[1px] bg-gray-300 mb-16"></div> */}
        {isEditMode ? (
          <div className="flex">
            <div className="flex items-center gap-4 text-xs font-medium rtl:mr-auto ltr:ml-auto ltr:md:mr-4 rtl:md:ml-4">
              <button
                onClick={cancelEditMode}
                className="w-[6.25rem] px-5 py-[0.875rem] rounded-lg bg-white border border-gray-300"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={saveInfo}
                className="w-[6.25rem] rounded-lg text-white  px-5 py-[0.875rem] border bg-green-600"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center mt-2">
            <button
              onClick={handleClick}
              className="flex items-center gap-2 py-[0.875rem]  rtl:md:mr-auto ltr:md:ml-auto px-[1.25rem]  rounded-lg text-white bg-green-600 w-[9.688rem] "
            >
              <p className="text-xs font-medium">{t("Edit information")}</p>
              <div className="w-4 h-4">
                <img src="/Right.svg" alt="" className="w-full h-full" />
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PersonalInfo;
