export const WHY_WORKEY_DESCRIPTION = [
  {
    imgSrc: "/card1.png",
    cardHeader: "seamless book",
    description: "effortless",
  },
  {
    imgSrc: "/card2.png",
    cardHeader: "community engage",
    description: "connect and collaborate",
  },
  {
    imgSrc: "/card3.png",
    cardHeader: "diverse options",
    description: "explore a vast",
  },
  {
    imgSrc: "/card4.png",
    cardHeader: "pro support",
    description: "expert assist",
  },
];

/*TRUSTED_SUBSCRIPERS to mock the images that come from the API */
export const TRUSTED_SUBSCRIPERS = [
  "/BG.png",
  "/BG2.png",
  "/BG3.png",
  "/BG4.png",
  "/BG5.png",
  "/BG6.png",
  "/BG.png",
  "/BG2.png",
  "/BG3.png",
  "/BG4.png",
  "/BG5.png",
  "/BG6.png",
  "/BG.png",
  "/BG2.png",
  "/BG3.png",
  "/BG4.png",
  "/BG5.png",
  "/BG6.png",
];
export const MOBILL_APP_DESC =
  "Take Workey with you wherever you go! Download our mobile app to seamlessly book workspaces, manage reservations, and stay connected with the Workey community on the move. Enjoy the flexibility of accessing our platform anytime, anywhere, and elevate your workspace experience with ease. Available on iOS and Android.";
/*Galary photo */
export const GALARY_PHOTO = [
  { id: 1, src: "/photo_GA1.webp" },
  { id: 2, src: "/photo_GA2.webp" },
  { id: 3, src: "/photo_GA3.webp" },
  { id: 4, src: "/photo_GA4.webp" },
  { id: 5, src: "/photo_GA5.webp" },
  { id: 6, src: "/photo_GA6.webp" },
  { id: 7, src: "/photo_GA7.webp" },
  { id: 8, src: "/photo_GA8.webp" },
  { id: 9, src: "/photo_GA9.webp" },
];

export const navigationToLInks = [
  {
    title: "long term",
    src: "/longterm-s.jpeg",
    linkTO: "long_term",
    icon: "/longterm.svg",
  },
  {
    title: "shared office",
    src: "/shared-s.jpeg",
    linkTO: "shared_office",
    icon: "/sharedoffice.svg",
  },
  {
    title: "private office",
    src: "/private-s.jpeg",
    linkTO: "private_office",
    icon: "/privateoffice.svg",
  },
  {
    title: "meeting room",
    src: "/meeting-s.jpeg",
    linkTO: "meeting_room",
    icon: "/meetingroom.svg",
  },
  {
    title: "event space",
    src: "/event-s.jpeg",
    linkTO: "event_space",
    icon: "/eventspace.svg",
  },
  // {
  //   title: "studio",
  //   src: "/Studio.png",
  //   linkTO: "studio",
  // },
];
