import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function BookingsRequested() {
  const navigate = useNavigate();
  const { t } = useTranslation("bookings");

  useEffect(() => {
    setTimeout(() => {
      navigate("/bookings");
    }, 2000);
  }, []);

  return (
    <div className="flex items-center flex-col px-[1.5rem] w-[31.25rem]">
      <div className="w-[9.375rem] h-[9.375rem] mb-4">
        <img src="/success.gif" alt="" className="w-full h-full" />
      </div>
      <h3 className="mb-2 text-lg font-semibold leading-[1.361rem]">
        {t("booking requested")}
      </h3>
      <p className="mb-6 text-base font-normal leading-[1.141rem] text-center text-[#6B7280]">
        {t("we will notify")}
      </p>
      <button
        onClick={() => navigate("/bookings")}
        className="bg-[#009E1E] w-[10.5rem] py-[0.875rem] px-5 text-xs font-medium text-white rounded-lg"
      >
        {t("view my bookings")}
      </button>
    </div>
  );
}

export default BookingsRequested;
