import { useState, useEffect } from "react";
import { formatDateAndTime } from "../../../helpers/timeFormat";
import { postRating, getRatingList } from "../../../data_featching/ratings";
import { Star, X, MapPin } from "lucide-react";
import { Spinner } from "../Spinner";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FeedbackForm = ({ booking, onClose }) => {
  const [ratings, setRatings] = useState({ 1: 0 });
  const [Features, setFeatures] = useState([]);
  const [comment, setComment] = useState("");
  const [isClicked, setIsClicked] = useState("");
  const token = localStorage.getItem("authToken");
  const [showSuccess, setShowSuccess] = useState(false);
  const { t, i18n } = useTranslation("bookings");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRatingList = async () => {
      const response = await getRatingList(token, i18n.language);
      setFeatures(response?.data?.data || []);
    };
    fetchRatingList();
  }, [token, i18n.language]);

  const { checkin, checkout } = formatDateAndTime(
    i18n.language,
    booking.start_at,
    booking.end_at
  );

  const handleRatingChange = (id, value) => {
    setRatings((prev) => ({
      ...prev,
      [id]: prev[id] === 0 ? value : value !== prev[id] ? value : 0,
    }));
  };

  const handleSubmit = async () => {
    setIsClicked(true);
    if (!comment.trim()) return;
    try {
      const data = {
        booking_id: booking.id,
        space_id: booking.space.id,
        ratings,
        review: comment,
      };
      const response = await postRating(token, data);
      if (response.status === 200) {
        navigate("/bookings");
        setShowSuccess(true);
      }
      console.log("response", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const quickTags = [
    "Strongly recommended",
    "Brilliant",
    "Excellent",
    "Amazing",
    "Very good",
    "Super",
  ];

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [showSuccess]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-8 bg-black bg-opacity-50">
      {!showSuccess ? (
        <div
          className="z-10 w-4/5 h-full p-6 mx-auto mt-20 mb-20 overflow-y-auto rounded-lg shadow-lg"
          style={{ backgroundColor: "#F9FAFB" }}
        >
          <div className="flex items-start justify-between mb-4">
            <div>
              <h2 className="text-2xl font-bold text-gray-800">
                {t("Feedback")}{" "}
              </h2>
            </div>
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={onClose}
            >
              <X size={24} />
            </button>
          </div>

          <div className="bookingCard flex flex-row gap-2 sm:max-h-[260px] max-h-[220px] rounded-2xl bg-white shadow-lg my-4">
            <div className="bookingCardImage md:w-[290px] sm:w-[150px] sm:h-[260px] h-[220px] relative ">
              <img
                src={booking?.space?.media[0]?.url || "photo_GA2.png"}
                alt="space"
                className="object-cover w-full h-full rounded-tl-2xl rounded-bl-2xl"
              />
            </div>
            <div className="flex flex-col justify-between w-full p-3 overflow-y-auto bookingCardDetails">
              <div>
                <div className="flex justify-between items-start w-full mb-[8px]">
                  <h3 className="mt-2 text-xl font-bold leading-6 text-gray-800 font-inter line-clamp-1">
                    {booking?.space?.name} ({booking?.space?.workspace.name}){" "}
                  </h3>
                  <span className="mt-2 mr-4 text-sm text-gray-800">
                    {t("Booking Id")}
                  </span>
                </div>
                <div className="flex items-start justify-between w-full ">
                  <p className="text-sm text-gray-500 ">{t("space")}</p>
                  <span className="mr-4 text-sm text-green-500 font-weight-500">
                    {booking.id}
                  </span>
                </div>
              </div>
              <div className="flex items-center text-base font-normal text-gray-500 ">
                <MapPin size={16} className="mr-2" color="green" />
                {booking?.space?.workspace?.address?.building_number ||
                  t("building")}
                , {booking?.space?.workspace?.address?.district.name}
              </div>
              <div className="font-normal text-base flex lg:flex-row sm:flex-col justify-start gap-3 mt-[40px] ">
                <span className="flex items-center gap-2">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#22C55E" />
                  </svg>
                  {checkin.formattedDate} - {checkin.formattedTime}
                </span>
                <span className="flex items-center gap-2">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="5" r="5" fill="#EF4444" />
                  </svg>
                  {checkout.formattedDate} - {checkout.formattedTime}
                </span>
              </div>

              <p className="flex items-center gap-6 ">
                <p className="flex items-center gap-1 cost-details">
                  <span className="text-[#252c34] text-4xl font-bold">
                    {booking?.price}
                  </span>
                  <span className="cost-currency text-[#252c34] text-base">
                    {booking?.currency.label}
                  </span>
                </p>

                {booking?.paid_at === "host" ? (
                  <p className="text-base font-normal text-destructive ">
                    {t("unpaid")}
                  </p>
                ) : (
                  <p
                    className={`font-semibold text-lg text-${booking?.state?.color}`}
                  >
                    {booking?.state?.label}
                  </p>
                )}
              </p>
            </div>
          </div>

          <div className="overflow-y-auto h-1/2 min-h-[200px]">
            <div className="flex flex-col gap-4 mb-6">
              <h1>{t("Rate your Experience")}</h1>
              <p className="text-gray-400">
                {t(
                  "We value your feedback! Please rate your experience at this co-working space and leave a comment to help others."
                )}
              </p>
            </div>
            {Features && Features.length > 0 ? (
              <>
                {Features.map(({ id, name }) => (
                  <div key={id} className="flex flex-col gap-4 mb-4">
                    <div className="flex items-center mb-1">
                      <h1>{name}</h1>
                    </div>
                    <div className="flex self-center gap-2">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                          key={star}
                          size={48}
                          className={`cursor-pointer ${
                            star <= ratings[id]
                              ? "text-yellow-400 fill-yellow-400"
                              : "text-gray-300"
                          }`}
                          onClick={() => handleRatingChange(id, star)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <Spinner />
            )}

            <textarea
              placeholder={t("Share your thoughts...")}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full p-2 mt-4 border border-gray-300 rounded-md focus:border-transparent"
              rows={4}
            />
            {isClicked && !comment?.trim() && (
              <p className="my-2 text-sm font-medium text-red-500">
                {t("please enter your feedback")}
              </p>
            )}

            <div className="flex flex-wrap gap-2 mt-4">
              {quickTags.map((tag) => (
                <button
                  key={tag}
                  className="px-3 py-1 text-sm text-gray-700 transition-colors bg-white rounded-full shadow-sm hover:bg-gray-200"
                  onClick={() =>
                    setComment((prev) => `${prev} ${t(tag)}`.trim())
                  }
                >
                  {t(tag)}
                </button>
              ))}
            </div>

            <div className="flex justify-end gap-4 mt-6">
              <button
                className="px-4 py-2 text-gray-700 transition-colors border border-gray-300 rounded-md hover:bg-gray-50"
                onClick={onClose}
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleSubmit}
                className={`${
                  !comment?.trim()
                    ? "!cursor-not-allowed bg-gray-400"
                    : "!cursor-pointer"
                } px-4 py-2 text-white  bg-green-600 rounded-md`}
              >
                {t("Submit")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="z-10 flex flex-col w-1/3 gap-4 p-4 mx-auto rounded-lg shadow-lg h-1/2"
          style={{ backgroundColor: "#F9FAFB" }}
        >
          <div className="flex items-start justify-between mb-4">
            <div>
              <h2 className="text-2xl font-bold text-gray-800">
                {t("Feedback")}
              </h2>
            </div>
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={onClose}
            >
              <X size={24} />
            </button>
          </div>
          <img
            src="/success_face.svg"
            alt="success"
            className="w-1/2 mx-auto h-1/2"
          />
          <h1 className="text-2xl font-bold text-center text-gray-800">
            {t("Thank you for your feedback!")}
          </h1>
        </div>
      )}
    </div>
  );
};

export default FeedbackForm;
FeedbackForm.propTypes = null;
