import { createContext, useState } from "react";

export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedCheckinTime, setSelectedCheckinTime] = useState(null);
  const [selectedCheckoutTime, setSelectedCheckoutTime] = useState(null);
  const [initialBookingData, setInitialBookingData] = useState({});
  const [bookedResources, setBookedResources] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [selectedDays, setSelectedDays] = useState({ from: null, to: null });
  const [selectedDailyOption, setSelectedDailyOption] = useState(null);
  const [shiftID, setShiftID] = useState(null);
  const [countMonths, setCountMonths] = useState(1);
  const [selectedMonthlyOption, setSelectedMonthlyOption] = useState(null);
  const [MonthselectedDay, setMonthSelectedDay] = useState();
  const [shiftMonthID, setShiftMonthID] = useState(null);
  const [AnnualselectedMonth, setAnnualSelectedMonth] = useState(new Date());
  const [selectedAnnualOption, setSelectedAnnualOption] = useState(null);
  const [AnnualshiftID, setAnnualShiftID] = useState(null);
  const [bookingData, setBookingData] = useState(null);

  return (
    <BookingContext.Provider
      value={{
        selectedDay,
        setSelectedDay,
        selectedCheckinTime,
        setSelectedCheckinTime,
        selectedCheckoutTime,
        setSelectedCheckoutTime,
        initialBookingData,
        setInitialBookingData,
        bookedResources,
        setBookedResources,
        selectedPlan,
        setSelectedPlan,
        selectedDays,
        setSelectedDays,
        selectedDailyOption,
        setSelectedDailyOption,
        shiftID,
        setShiftID,
        countMonths,
        setCountMonths,
        selectedMonthlyOption,
        setSelectedMonthlyOption,
        MonthselectedDay,
        setMonthSelectedDay,
        shiftMonthID,
        setShiftMonthID,
        AnnualselectedMonth,
        setAnnualSelectedMonth,
        selectedAnnualOption,
        setSelectedAnnualOption,
        AnnualshiftID,
        setAnnualShiftID,
        bookingData,
        setBookingData,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

BookingProvider.propTypes = null;
