import { useContext } from "react";
import LanguageContext from "../../context/LanguageProvider";
import { useTranslation } from "react-i18next";

function Preferences() {
  const { language, changeLanguage } = useContext(LanguageContext);
  const { t } = useTranslation("profile");

  return (
    <div className="w-full h-full px-2 py-6 md:px-6">
      <h3 className="mb-6 text-xl font-semibold">{t("Preferences")}</h3>
      <div>
        <div className="flex items-center gap-16 px-0 md:px-4">
          <h3 className="min-w-[8.75rem]  text-xl font-semibold">
            {t("Location")}
          </h3>
          <p className="text-lg font-normal text-gray-500">
            {t("Saudi Arabia")}
          </p>
          {/* <button className="flex items-center gap-1 ltr:ml-auto rtl:mr-auto">
            <span className="text-xs font-medium text-blue-600">
              {t("Change")}
            </span>
            <img
              src="/prefer-change.svg"
              alt=""
              className="mt-[2.5px] h-4 w-4"
            />
          </button> */}
        </div>
        <div className="h-[1px] bg-gray-300 my-4"></div>
        <div className="flex items-center gap-16 px-0 md:px-4">
          <h3 className="min-w-[8.75rem] text-xl font-semibold">
            {t("Currency")}
          </h3>
          <p className="text-lg font-normal text-gray-500">
            {t("Saudi Riyal")}
          </p>
          {/* <button className="flex items-center gap-1 ltr:ml-auto rtl:mr-auto">
            <span className="text-xs font-medium text-blue-600">
              {t("Change")}
            </span>
            <img
              src="/prefer-change.svg"
              alt=""
              className="mt-[2.5px] h-4 w-4"
            />
          </button> */}
        </div>
        <div className="h-[1px] bg-gray-300 my-4"></div>
        <div className="flex flex-col gap-4 px-0 md:px-4 lg:gap-16 lg:items-center lg:flex-row">
          <h3 className="min-w-[8.75rem] text-xl font-semibold">
            {t("Language")}
          </h3>
          <div className="flex flex-col w-full md:items-center md:flex-row">
            <p className="text-lg font-normal text-gray-500">
              {language === "en" ? "English" : "العربية"}
            </p>

            <button
              onClick={() => changeLanguage(language === "en" ? "ar" : "en")}
              className="flex items-center gap-1 ltr:mr-0 rtl:ml-0 ltr:lg:mr-4 ltr:ml-auto rtl:lg:ml-4 rtl:mr-auto"
            >
              <span className="text-xs font-medium text-blue-600">
                {t("Change")}
              </span>
              <img
                src="/prefer-change.svg"
                alt=""
                className="mt-[2.5px] h-4 w-4"
              />
            </button>
          </div>
        </div>
        <div className="h-[1px] bg-gray-300 mt-4"></div>
      </div>
    </div>
  );
}

export default Preferences;
