import { useContext, useEffect, useState } from "react";
import ConvDetails from "./ConvDetails";
import ConvSubSidebar from "./ConvSubSidebar";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import "./Conversations.css";
import { getAllThreads } from "../../data_featching/conversations";
import NoConversationsState from "./NoConversationsState";
// import { useNavigate } from "react-router-dom";
import Footer from "../ui/footer/Footer";
import AuthContext from "../../context/AuthProvider";
import { useTranslation } from "react-i18next";

const Conversations = () => {
  const [AllThreads, setAllThreads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [conversationMount, setConversationMount] = useState(false);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedThreadDetails, setSelectedThreadDetails] = useState({
    id: "",
    ifAnyThreadSelected: false,
    ThreadDetails: [],
  });
  const [hideSide, setHideSide] = useState(false);
  const { t } = useTranslation("conversations");
  // const pathName = location.pathname;
  // const mobilePath = "/dashboard/conversations";
  // const navigate = useNavigate();
  const { newConversation } = useContext(AuthContext);

  // const handleResizeX = () => {
  //   if (window.innerWidth < 1280 && pathName == mobilePath) {
  //     navigate("/dashboard");
  //   }
  // };
  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);

  // useEffect(() => {
  //   if (pathName == mobilePath) {
  //     window.addEventListener("resize", handleResizeX);

  //     return () => {
  //       window.removeEventListener("resize", handleResizeX);
  //     };
  //   }
  // }, []);

  const token = localStorage.getItem("authToken");
  console.log("token", token);
  /*get all conversations thread for Host */
  const pullAllConversationsThreads = async () => {
    try {
      // setIsLoading(true);
      const threadsResults = await getAllThreads(token);
      if (threadsResults?.status == 200 || threadsResults?.status == 201) {
        setConversationMount(true);
        // setIsLoading(false);
        setAllThreads(threadsResults?.data?.data);
        console.log("threadsResults", threadsResults?.data?.data);
      } else {
        throw new Error("not sucess 200");
      }
    } catch (error) {
      setConversationMount(true);

      // setIsLoading(false);
      console.log("error inside conversation", error.message);
    }
  };

  useEffect(() => {
    pullAllConversationsThreads();
  }, [selectedThreadDetails]);

  // console.log(
  //   "selectedThreadDetails.ifAnyThreadSelected ",
  //   selectedThreadDetails.ifAnyThreadSelected
  // );
  // console.log("newConversation", newConversation);
  // console.log("selectedThreadDetails.id ", selectedThreadDetails.id);

  return (
    <>
      <div className="bg-[#f9fafb] ">
        <LoginNavBar
          showBurgerLoginMenu={showBurgerLoginMenu}
          setShowBurgerLoginMenu={setShowBurgerLoginMenu}
        />
        {!showBurgerLoginMenu && (
          <>
            <div className="flex gap-4  w-full relative xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] py-10">
              {!hideSide && !selectedThreadDetails?.ifAnyThreadSelected && (
                <ConvSubSidebar
                  AllThreads={AllThreads}
                  isLoading={isLoading}
                  setSelectedThreadDetails={setSelectedThreadDetails}
                  selectedThreadDetails={selectedThreadDetails}
                />
              )}
              {selectedThreadDetails?.ifAnyThreadSelected && width > 1280 && (
                <ConvSubSidebar
                  AllThreads={AllThreads}
                  isLoading={isLoading}
                  setSelectedThreadDetails={setSelectedThreadDetails}
                  selectedThreadDetails={selectedThreadDetails}
                />
              )}

              {selectedThreadDetails.ifAnyThreadSelected ||
              newConversation != null ? (
                <ConvDetails
                  // sideBarUpload={sideBarUpload}
                  setIsLoading={setIsLoading}
                  selectedThreadDetails={selectedThreadDetails}
                  isLoading={isLoading}
                  setHideSide={setHideSide}
                  setSelectedThreadDetails={setSelectedThreadDetails}
                  hideSide={hideSide}
                />
              ) : (
                <NoConversationsState />
              )}
            </div>
            <div className="w-full py-4 bg-[#252C34] ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px]">
              <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
                <p className="text-4xl font-extrabold text-green-500">
                  {t("get workey")}
                </p>
                <div className="flex gap-8">
                  <div>
                    <img
                      src="/Group.png"
                      alt="google play img"
                      className="w-full"
                    />
                  </div>
                  <div>
                    <img
                      src="/AppleStore.png"
                      alt="Apple Store img"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default Conversations;
