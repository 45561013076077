import axios from "axios";

// Create Axios instance with base URL
const baseURL = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

// Map to track pending requests
const pendingRequests = new Map();

// Function to generate a unique key for each request
const getRequestKey = (config) => {
  const { url, method, data } = config;
  return `${url}&${method}&${JSON.stringify(data)}`;
};

// Function to add a request to the tracker, only for GET requests
const addPendingRequest = (config) => {
  const requestKey = getRequestKey(config);

  // Check if the request is already in progress
  // if (pendingRequests.has(requestKey)) {
  //   config.cancelToken = new axios.CancelToken((cancel) =>
  //     cancel("Duplicate  request canceled")
  //   );
  // } else {
  // If not, add it to the tracker
  pendingRequests.set(requestKey, config);
  // }
};

// Function to remove a request from the tracker
const removePendingRequest = (config) => {
  // Only remove if it's a GET request (because only GET requests are tracked)
  const requestKey = getRequestKey(config);
  pendingRequests.delete(requestKey);
};

// Request interceptor to handle duplicate GET requests
baseURL.interceptors.request.use(
  (config) => {
    addPendingRequest(config); // Track the request if it's GET
    return config; // Proceed with the request
  },
  (error) => Promise.reject(error)
);

// Response interceptor to clean up completed requests
baseURL.interceptors.response.use(
  (response) => {
    removePendingRequest(response.config); // Remove request from tracker
    return response; // Pass the response along
  },
  (error) => {
    removePendingRequest(error.config || {}); // Clean up on error
    return Promise.reject(error);
  }
);

// Function to set the "Accept-Language" header dynamically
export const setAcceptLanguageHeader = (language) => {
  baseURL.defaults.headers.common["Accept-Language"] = language;
};

export default baseURL;
