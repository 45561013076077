import { useEffect, useContext, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./Plans.css";
import { getActiveDaysIndexes } from "../../../helpers/timeFormat";
import { addDays } from "date-fns";
import { BookingContext } from "../../../context/BookingProvider";
import { useTranslation } from "react-i18next";
import { arSA } from "react-day-picker/locale";

const DailyBookingPlan = ({
  spaceDetails,
  onBack,
  setCurrentStep,
  // initialBookingData,
  updateInitialBookingData,
  setShiftType,
  updateBookedShift,
  updateInstantBooking,
  handlePlanID,
}) => {
  const {
    selectedDays,
    setSelectedDays,
    selectedDailyOption,
    setSelectedDailyOption,
    shiftID,
    setShiftID,
  } = useContext(BookingContext);

  const [bookingStartTime, setBookingStartTime] = useState(null);
  const [bookingEndTime, setBookingEndTime] = useState(null);
  const formattedCheckinDay = selectedDays.from?.toLocaleDateString("en-CA");
  const formattedCheckoutDay = selectedDays.to?.toLocaleDateString("en-CA");
  const { t, i18n } = useTranslation("bookings");

  function convertTime(timeString) {
    if (!timeString) return;
    const [hours, minutes] = timeString.split(":");
    let hour = parseInt(hours);
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;

    return `${hour}:${minutes.padStart(2, "0")} ${period}`;
  }

  const planDetails = spaceDetails.plans.find(
    (plan) => plan.name.key === "daily"
  );

  const planId = planDetails.id;
  handlePlanID(planId);

  useEffect(() => {
    const selectedShift = planDetails.shifts.find(
      (shift) => shift?.name?.key === selectedDailyOption
    );

    if (selectedShift) {
      updateBookedShift(selectedShift);
      setShiftID(selectedShift.id);
      setBookingStartTime(selectedShift.start_time);
      setBookingEndTime(selectedShift.end_time);
      setShiftType(selectedShift.name.label);
    }
  }, [selectedDailyOption]);

  const activeIndexes = getActiveDaysIndexes(
    spaceDetails.workspace.working_days
  );

  const handleDailyShiftChange = (event) => {
    setSelectedDailyOption(event.target.value);
  };

  const checkinDay = formattedCheckinDay;
  const checkoutDay =
    formattedCheckoutDay !== undefined
      ? formattedCheckoutDay
      : formattedCheckinDay;

  const startTime = `${checkinDay} ${bookingStartTime}`;
  const endTime = `${checkoutDay} ${bookingEndTime}`;

  const selectedBookingData = {
    shift_id: shiftID,
    start_at: startTime,
    end_at: endTime,
  };

  const isReviewButtonDisabled = () => {
    return (
      shiftID === null ||
      shiftID === undefined ||
      checkinDay === null ||
      checkinDay === undefined ||
      checkoutDay === null ||
      checkoutDay === undefined ||
      bookingStartTime === null ||
      bookingStartTime === undefined ||
      bookingEndTime === null ||
      bookingEndTime === undefined
    );
  };

  useEffect(() => {
    updateInitialBookingData(selectedBookingData);
  }, [shiftID, checkinDay, checkoutDay, bookingStartTime, bookingEndTime]);

  const handleSubmit = () => {
    updateInstantBooking(planDetails?.instant_booking);
    setCurrentStep(3);
  };
  console.log(spaceDetails);
  return (
    <div>
      <div className="flex w-full gap-5 mt-5 lg:flex-row sm:flex-col">
        <div className=" datePeckerWrapper flex sm:m-auto lg:m-0 lg:w-[400px] sm:w-[370px] h-[380px] p-1 ">
          <DayPicker
            mode="range"
            selected={selectedDays}
            onSelect={setSelectedDays}
            locale={i18n.language === "ar" ? arSA : undefined}
            className="w-full custom-day-picker"
            disabled={[
              { before: addDays(new Date(), 1) }, // Disable dates before tomorrow
              {
                dayOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
                  (day) => !activeIndexes.includes(day)
                ),
              },
            ]}
            required
            excludeDisabled
          />
        </div>

        <div className="w-full daily-plan">
          <p className="text-[#000021] font-medium text-lg ">
            {t("Please Select Shift")}
          </p>
          <div className="mt-5 checkbox-group">
            {planDetails?.shifts.map((shift, index) => {
              if (!shift?.is_active) return null;

              return (
                <label
                  key={index}
                  className={`checkbox-container font-[400] text-[#6B7280] `}
                >
                  <input
                    type="checkbox"
                    value={shift?.name?.key}
                    checked={selectedDailyOption === shift.name.key}
                    onChange={handleDailyShiftChange}
                    disabled={!shift?.is_active}
                  />
                  <span className="checkmark ltr:mr-2 rtl:ml-2"></span>
                  {shift.name.label}: {t("from")}{" "}
                  {convertTime(shift.start_time)} {`${t("to")} `}
                  {convertTime(shift.end_time)}
                </label>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-5">
        <button
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm "
          onClick={onBack}
        >
          {i18n.language === "en" ? (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11L2 6L7 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M12 11L7 6L12 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 11L11 6L6 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 11L6 6L1 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
          {t("Back")}
        </button>
        <button
          className=" bg-[#009E1E] px-4 py-2 text-white rounded-lg  "
          onClick={handleSubmit}
          disabled={isReviewButtonDisabled()}
        >
          {t("Select Resources")}
        </button>
      </div>
    </div>
  );
};

export default DailyBookingPlan;
DailyBookingPlan.propTypes = null;
