import { useState, useEffect, useContext } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./Plans.css";
import {
  convertTo24HourTime,
  convertTo12HourFormat,
} from "../../../helpers/convertInto24Hour";
import { getActiveDaysIndexes } from "../../../helpers/timeFormat";
import { getExcluedBookings } from "../../../data_featching/booking";
import { format } from "date-fns";
import { BookingContext } from "../../../context/BookingProvider";
import { useTranslation } from "react-i18next";
import { arSA } from "react-day-picker/locale";

const HourlyBookingPlan = ({
  spaceDetails,
  onBack,
  setCurrentStep,
  updateInitialBookingData,
  setShiftType,
  updateBookedShift,
  updateInstantBooking,
  handlePlanID,
}) => {
  const {
    selectedDay,
    setSelectedDay,
    selectedCheckinTime,
    setSelectedCheckinTime,
    selectedCheckoutTime,
    setSelectedCheckoutTime,
  } = useContext(BookingContext);
  const { t, i18n } = useTranslation("bookings");
  const [excludeTimes, setExcludeTimes] = useState([]);
  const [selectedWorkingDay, setSelectedWorkingDay] = useState(null);
  const [formattedSelectedDate, setFormattedSelectedDate] = useState(null);
  const [checkinTimeSlots, setCheckinTimeSlots] = useState([]);
  const [checkoutTimeSlots, setCheckoutTimeSlots] = useState([]);
  const workingDays = spaceDetails?.workspace?.working_days;
  const planDetails = spaceDetails?.plans.find(
    (plan) => plan?.name?.key === "hourly"
  );
  const capcity = spaceDetails?.quantity;
  const planId = planDetails.id;
  const bookedShift = planDetails?.shifts[0];
  const isInstantBooking = planDetails?.instant_booking;
  useEffect(() => {
    updateBookedShift(bookedShift);
  }, [bookedShift]);

  useEffect(() => {
    updateInstantBooking(isInstantBooking);
  }, [isInstantBooking]);

  useEffect(() => {
    if (selectedDay) {
      const formattedDate = format(selectedDay, "yyyy-MM-dd");
      const month = format(selectedDay, "MM");
      const year = format(selectedDay, "yyyy");
      setExcludeTimes([]);
      getExcluedBookings(spaceDetails.id, year, month).then((response) => {
        const ExcluedBookings = response.data.data;
        console.log("ExcluedBookings", ExcluedBookings);
        const selectedDayBookings = ExcluedBookings.find(
          (day) => day.day === formattedDate
        );
        if (selectedDayBookings) {
          const bookings = selectedDayBookings.bookings;
          const excludedTimes = bookings.map((booking) => {
            return {
              from: booking.start_at,
              to: booking.end_at,
            };
          });
          setExcludeTimes(excludedTimes);
        }
      });
    }
    if (selectedDay != null || selectedDay != undefined) {
      const selectedDayKey = format(selectedDay, "EEEE").toLowerCase(); // 'sunday' for example
      const workingDay = workingDays.find(
        (day) => day.day.key === selectedDayKey
      );
      setSelectedWorkingDay(workingDay);
      setFormattedSelectedDate(selectedDay.toLocaleDateString("en-CA")); // 'en-CA' outputs in YYYY-MM-DD format
    }
  }, [selectedDay]);

  useEffect(() => {
    if (selectedWorkingDay && selectedWorkingDay.is_active) {
      const { start_time, end_time } = selectedWorkingDay;

      // Helper function to round time
      const roundTime = (time) => {
        const [hour, minute] = time.split(":").map(Number);
        return minute >= 30 ? hour + 1 : hour;
      };

      // Parse start and end times
      const startHour = roundTime(start_time);
      const endHour = roundTime(end_time) - 1;

      const newCheckinTimeSlots = [];
      const now = new Date();
      const currentHour = now.getHours() + 1;

      for (let hour = startHour; hour <= endHour; hour++) {
        // Exclude hours less than the current time if the selected day is today
        if (
          selectedDay &&
          selectedDay.toDateString() === now.toDateString() &&
          hour < currentHour
        ) {
          continue;
        }

        // Check if the current hour is excluded and no capacity is available
        const isExcluded = excludeTimes.some((time) => {
          const from = roundTime(time.from);
          const to = roundTime(time.to);
          return hour >= from && hour < to;
        });

        // Count the number of bookings for the current hour
        const bookingCount = excludeTimes.reduce((count, time) => {
          const from = roundTime(time.from);
          const to = roundTime(time.to);
          if (hour >= from && hour < to) {
            return count + 1;
          }
          return count;
        }, 0);

        // Check if the number of bookings is greater than or equal to the capacity
        const noCapacity = bookingCount >= capcity;

        if (isExcluded && noCapacity) continue;

        newCheckinTimeSlots.push(convertTo12HourFormat(hour));
      }

      setCheckinTimeSlots(newCheckinTimeSlots);
      updateCheckoutTimeSlots(newCheckinTimeSlots);
    } else {
      console.log("No active working day or selected day is not available.");
    }
  }, [selectedWorkingDay, excludeTimes, selectedDay]);

  const updateCheckoutTimeSlots = (checkinTimeSlots) => {
    const newCheckoutTimeSlots = checkinTimeSlots
      .filter(
        // if there is selected checkin time, get only the time slots that are greater than the selected checkin time
        (timeSlot) =>
          !selectedCheckinTime ||
          convertTo24HourTime(timeSlot) >= selectedCheckinTime
      )

      .map((timeSlot) => {
        const [hour, suffix] = timeSlot.split(":00 ");
        const newHour = (parseInt(hour) + 1) % 12 || 12;
        const newSuffix =
          (suffix === "am" && newHour !== 12) ||
          (suffix === "pm" && newHour === 12)
            ? "am"
            : "pm";
        return `${newHour}:00 ${newSuffix}`;
      });

    setCheckoutTimeSlots(newCheckoutTimeSlots);
  };

  useEffect(() => {
    if (!selectedDay) {
      setSelectedCheckinTime("");
      setSelectedCheckoutTime("");
      setCheckinTimeSlots([]);
      setCheckoutTimeSlots([]);
    }
  }, [selectedDay]);

  const handleCheckinTimeSelect = (e) => {
    const checkinTime = convertTo24HourTime(e.target.value);
    setSelectedCheckinTime(checkinTime);
  };
  useEffect(() => {
    if (!selectedCheckinTime) return;
    const newCheckoutTimeSlots = checkinTimeSlots
      .filter(
        // get only the time slots that are greater than the selected checkin time
        (timeSlot) => convertTo24HourTime(timeSlot) >= selectedCheckinTime
      )
      .map((timeSlot) => {
        const [hour, suffix] = timeSlot.split(":00 ");
        const newHour = (parseInt(hour) + 1) % 12 || 12;
        const newSuffix =
          (suffix === "am" && newHour !== 12) ||
          (suffix === "pm" && newHour === 12)
            ? "am"
            : "pm";
        return `${newHour}:00 ${newSuffix}`;
      });

    setCheckoutTimeSlots(newCheckoutTimeSlots);
  }, [selectedCheckinTime]);
  const handleCheckoutTimeSelect = (e) => {
    const checkoutTime = convertTo24HourTime(e.target.value);
    setSelectedCheckoutTime(checkoutTime);
  };

  const isCheckoutTimeDisabled = (time) => {
    if (!selectedCheckinTime) return false;

    const selectedCheckinTimeDate = new Date(
      `1970-01-01T${selectedCheckinTime}`
    );
    const checkoutTime = convertTo24HourTime(time);
    const checkoutTimeDate = new Date(`1970-01-01T${checkoutTime}`);

    return checkoutTimeDate <= selectedCheckinTimeDate;
  };

  const activeIndexes = getActiveDaysIndexes(
    spaceDetails.workspace.working_days
  );

  const isReviewButtonDisabled = () => {
    return (
      shiftID === null ||
      shiftID === undefined ||
      formattedSelectedDate === null ||
      formattedSelectedDate === undefined ||
      selectedCheckinTime === null ||
      !selectedCheckinTime ||
      !selectedCheckoutTime ||
      selectedCheckinTime === undefined ||
      selectedCheckoutTime === null ||
      selectedCheckoutTime === undefined ||
      selectedDay == undefined
    );
  };

  const shiftID = spaceDetails.plans[0].shifts[0].id;
  const startTime = `${formattedSelectedDate} ${selectedCheckinTime}`;
  const endTime = `${formattedSelectedDate} ${selectedCheckoutTime}`;

  const selectedBookingData = {
    shift_id: shiftID,
    start_at: startTime,
    end_at: endTime,
  };

  useEffect(() => {
    updateInitialBookingData(selectedBookingData);
  }, [
    shiftID,
    formattedSelectedDate,
    selectedCheckinTime,
    selectedCheckoutTime,
  ]);

  const handleSubmit = async () => {
    setShiftType("Hourly");
    setCurrentStep(3);
    handlePlanID(planId);
  };

  return (
    <div>
      <div className="flex w-full gap-6 mt-5 lg:flex-row sm:flex-col">
        <div className=" datePeckerWrapper flex sm:m-auto lg:m-0 lg:w-[400px] sm:w-[370px] h-[380px] p-1 ">
          <DayPicker
            mode="single"
            selected={selectedDay}
            locale={i18n.language === "ar" ? arSA : undefined}
            onSelect={setSelectedDay}
            className="w-full custom-day-picker"
            disabled={[
              { before: new Date() },
              {
                dayOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
                  (day) => !activeIndexes.includes(day)
                ),
              },
            ]}
          />
        </div>

        <div className=" hourly-plan">
          <div className="mt-5 sm:mt-0">
            <p className="inline-flex items-center justify-center w-full px-5 py-2 text-sm font-medium me-2">
              {t("Check in Time")}
            </p>
            <label className="sr-only">{t("Pick a time")}</label>
            <ul
              id="checkinTime"
              className="flex flex-row flex-wrap gap-2 mt-5 "
            >
              {checkinTimeSlots?.map((time, index) => {
                return (
                  <li key={index}>
                    <input
                      type="radio"
                      id={`checkin-${time}`.replace(" ", "-")}
                      value={`${time}`}
                      className="hidden peer"
                      name="checkinTimetable"
                      onChange={handleCheckinTimeSelect}
                      checked={
                        selectedCheckinTime === convertTo24HourTime(time)
                      }
                    />
                    <label
                      htmlFor={`checkin-${time}`.replace(" ", "-")}
                      className="inline-flex items-center justify-center p-2 text-sm font-[400] text-[#111827] text-center bg-white border rounded-3xl cursor-pointer border-[#E2E8F0] peer-checked:border-[#E2E8F0] peer-checked:bg-[#009E1E] peer-checked:text-white "
                    >
                      {time.replace("am", t("am")).replace("pm", t("pm"))}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="pt-4 mt-5 sm:mt-0">
            <p className="inline-flex items-center justify-center w-full px-5 py-2 text-sm font-medium me-2">
              {t("Check out Time")}
            </p>
            <label className="sr-only">{t("Pick a time")}</label>
            <ul
              id="checkoutTime"
              className="flex flex-row flex-wrap gap-2 mt-5 "
            >
              {checkoutTimeSlots?.map((time, index) => {
                return (
                  <li key={index}>
                    <input
                      type="radio"
                      id={`checkout-${time}`.replace(" ", "-")}
                      value={`${time}`}
                      className="hidden peer"
                      name="checkoutTimetable"
                      onChange={handleCheckoutTimeSelect}
                      disabled={isCheckoutTimeDisabled(time)}
                      checked={
                        selectedCheckoutTime === convertTo24HourTime(time)
                      }
                    />
                    <label
                      htmlFor={`checkout-${time}`.replace(" ", "-")}
                      className="inline-flex items-center justify-center p-2 text-sm font-[400] text-[#111827] text-center bg-white border rounded-3xl cursor-pointer border-[#E2E8F0] peer-checked:border-[#E2E8F0] peer-checked:bg-[#009E1E] peer-checked:text-white "
                    >
                      {time.replace("am", t("am")).replace("pm", t("pm"))}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-5">
        <button
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm "
          onClick={onBack}
        >
          {i18n.language === "en" ? (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11L2 6L7 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M12 11L7 6L12 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 11L11 6L6 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 11L6 6L1 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
          {t("Back")}
        </button>
        <button
          className={`${
            isReviewButtonDisabled() ? "!cursor-not-allowed" : "cursor-pointer"
          } bg-[#009E1E] px-4 py-2 text-white rounded-lg`}
          onClick={handleSubmit}
          disabled={isReviewButtonDisabled()}
        >
          {t("Select Resources")}
        </button>
      </div>
    </div>
  );
};
export default HourlyBookingPlan;
HourlyBookingPlan.propTypes = null;
