import "./Home.css";
import "./gallary.css";
import { WHY_WORKEY_DESCRIPTION } from "./Data";
import { useState, useEffect, useContext } from "react";
import { Star } from "lucide-react";
import TrustedBy from "./TrustedBy";
import Footer from "../ui/footer/Footer";
import FastSearchLinks from "./FastSearchLinks";
import NavBar from "../NavBar/NavBar";
// import axios from "axios";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";
import { Link, useNavigate } from "react-router-dom";
import Map from "../ui/Map/Map";
import {
  getMapSpaces,
  fetchFeaturedWorkspaces,
} from "../../data_featching/workspace";
import CitySearch from "../ui/search/CitySearch";

function Home() {
  const { t } = useTranslation("home");
  const { language } = useContext(LanguageContext);
  const [workspaces, setWorkspaces] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const navigate = useNavigate();

  const fetchMapSpaces = async () => {
    try {
      const mapWorkspaces = await getMapSpaces(language);
      if (mapWorkspaces?.status === 200 || mapWorkspaces?.status === 201)
        setAddresses(mapWorkspaces.data.data);
    } catch (error) {
      console.error("Error fetching map workspaces:", error);
    }
  };
  const fetchWorkspaces = async () => {
    try {
      const response = await fetchFeaturedWorkspaces(language);
      if (response?.status === 200 || response?.status === 201)
        setWorkspaces(response.data.data.slice(0, 4));
    } catch (error) {
      console.error("Error fetching workspaces:", error);
    }
  };
  useEffect(() => {
    fetchWorkspaces();
    fetchMapSpaces();
  }, [language]);

  return (
    <>
      <NavBar
        isHostAccount={true}
        showBurgerMenu={showBurgerMenu}
        setShowBurgerMenu={setShowBurgerMenu}
      />
      {!showBurgerMenu && (
        <div className=" HomePage">
          <div className="Home_img_frame">
            <div className="Workey-Slogan">
              <p className="slogan_Header">{t("find ideal")}</p>
              <p className="leading-8 slogan_Desc">{t("unlock world")}</p>
            </div>
            <div className="search_card_containe">
              <div className="gap-4 rounded-lg shadow-md lg:gap-8 MainSearch_container">
                <div className="searchLabel">
                  <span>{t("where to work")}</span>
                </div>

                <div className="gap-8 search_details">
                  {/* <div className="locationSearch"> */}
                  <CitySearch withBorder={false} home={true} />

                  {/* </div> */}
                  <div className="flex justify-end">
                    <button
                      onClick={() => navigate("/explore")}
                      className="searchButton_Workey"
                    >
                      <span>{t("search")}</span>
                      <img src="/Search.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FastSearchLinks />

          <div className=" featureWorkSpaces_main-container">
            <h1 className="featureWorkSpaces_head">
              {t("explore featured workspaces")}
            </h1>
            {workspaces &&
              workspaces.map((item) => (
                <Link to={`/explore/workspace/${item?.id}`} key={item.id}>
                  <div
                    key={item.id}
                    className="flex items-start my-4 bg-white shadow-sm feature_workSpace_card rounded-2xl"
                  >
                    <div className="Workspace_img relative  flex items-center 2xl:w-[26.25rem] lg:w-[25rem] sm:w-full bg-white  sm:rounded-t-2xl lg:ltr:rounded-l-2xl lg:rtl:rounded-r-2xl lg:rounded-t-none">
                      <img
                        src={`${
                          item?.media?.length > 0
                            ? item?.media[0]?.url
                            : "/workspace-img.png"
                        }`}
                        alt=""
                        className="object-cover w-full h-full sm:rounded-t-2xl lg:ltr:rounded-l-2xl lg:rtl:rounded-r-2xl lg:rounded-t-none"
                      />
                      {item.rating > 0 && (
                        <div className="absolute flex items-center px-2 py-1 text-white bg-black bg-opacity-50 rounded-lg top-2 rtl:right-2 ltr:left-2">
                          <span className="mr-1 font-bold text-md">
                            {item.rating}
                          </span>
                          <Star className="w-4 h-4 text-yellow-400 fill-current" />
                        </div>
                      )}
                    </div>
                    <div className="relative flex flex-col justify-start w-full gap-4 p-4 WorkSpace_card_details">
                      <div className="flex flex-col gap-1 WorkSpace_title_address">
                        <div className="flex items-center gap-2 workSpace_title">
                          <span className="text-[#111827] text-xl font-bold">
                            {item?.name}
                          </span>
                        </div>
                        <div className="flex items-center space-x-2">
                          {item?.rating > 0 && (
                            <>
                              <div className="flex items-center my-2">
                                {Array.from({ length: item?.rating }).map(
                                  (_, index) => (
                                    <Star
                                      key={index}
                                      size={24}
                                      className="text-yellow-400"
                                      fill="currentColor"
                                    />
                                  )
                                )}
                              </div>

                              <span className="font-bold text-gray-800 rtl:pr-2">
                                ({item?.rating})
                              </span>
                              {item?.space_ratings_count && (
                                <span className="text-gray-500">
                                  {t("based on")} {item?.space_ratings_count}{" "}
                                  {t("reviews")}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="workspac_adderes text-[#6b7280] text-sm">
                          <span>
                            {item?.address?.building_number ||
                              item?.address?.street_name}
                            ,{item?.address?.district?.name}
                          </span>
                        </div>
                      </div>
                      <div className="hidden sm:flex sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 h-[135px] overflow-hidden">
                        {item?.amenities?.map((amenity, index) => (
                          <div
                            key={amenity.id}
                            className="flex items-center gap-2"
                            style={{
                              gridColumn: `${Math.floor(index / 4) + 1}`,
                              gridRow: `${(index % 4) + 1}`,
                            }}
                          >
                            <div
                              className="icon"
                              dangerouslySetInnerHTML={{
                                __html: amenity.icon,
                              }}
                            />
                            <span className="text-sm truncate">
                              {amenity.name}
                            </span>
                          </div>
                        ))}
                      </div>

                      <div className="flex flex-row items-center justify-between workspace-cost-action">
                        {item?.cheapest_plan?.shift?.price ? (
                          <div className="flex items-center gap-1 cost-details">
                            <span className="text-[#252c34] text-4xl font-bold">
                              {`${
                                item?.cheapest_plan?.shift?.price
                                  ? Number(
                                      item?.cheapest_plan?.shift?.price
                                    ).toFixed(0)
                                  : ""
                              }`}
                            </span>
                            <span className="cost-currency text-[#252c34] text-base">
                              {item?.cheapest_plan?.shift?.currency?.label}
                            </span>
                            <span className="cost-per text-[#6b7280] text-sm">
                              {item?.cheapest_plan?.name?.label
                                ? "/" + " " + item?.cheapest_plan?.name?.label
                                : ""}
                            </span>
                          </div>
                        ) : (
                          <p> </p>
                        )}
                        <button
                          onClick={() =>
                            navigate(`/explore/workspace/${item.id}`)
                          }
                          className="space-action flex items-center justify-center text-[#000021] px-5  py-2 rounded-lg bg-green-600 border cursor-pointer shadow-sm"
                        >
                          <span className="text-sm font-medium leading-5 text-white">
                            {t("explore")}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
          <div className="flex-col justify-center Map_section">
            <h1 className="map_header">{t("easily locate")}</h1>

            <div className="map_container">
              <Map addresses={addresses} />
            </div>
            <div className="Navigation_hit">{t("map note")}</div>
          </div>
          <div className="WHY_WORKEY">
            <div className="why-workey-hint-title">
              <div className="Why-workey-Hint">{t("why workey")}</div>
              <div className="Why-workey-Title">{t("transform with")}</div>
            </div>
            <div className="grid gap-4 why-workey_cards_container sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
              {WHY_WORKEY_DESCRIPTION.map((card, idx) => (
                <div className="Why-workey_card" key={idx}>
                  <div
                    className="card-img"
                    style={{ backgroundImage: `url(${card.imgSrc})` }}
                  ></div>
                  <div className="card-description">
                    <div className="description-title">
                      {t(card.cardHeader)}
                    </div>
                    <div className="hidden description-details lg:block">
                      {t(card.description)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <TrustedBy />
          <div className="Workey_mobile_App_section">
            <div className="Mobile_App_discription">
              <div className="description_App">
                <h1>{t("uncover")}</h1>
                <p>{t("take workey")}</p>
              </div>
              <div className="button_container_call_to">
                <div className="App_download">
                  <span>{t("download now")}</span>
                </div>
              </div>
            </div>
            <div className="App_presentation_img">
              <div className="QR_container sm:hidden md:block">
                <div className="QR_code"></div>
              </div>
            </div>
          </div>
          <div className="w-full py-4 bg-[#252C34] ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px]">
            <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
              <p className="text-4xl font-extrabold text-green-500">
                {t("get workey")}
              </p>
              <div className="flex gap-8">
                <div>
                  <img
                    src="/Group.png"
                    alt="google play img"
                    className="w-full"
                  />
                </div>
                <div>
                  <img
                    src="/AppleStore.png"
                    alt="Apple Store img"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}
export default Home;
