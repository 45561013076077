import { Link } from "react-router-dom";
import { navigationToLInks } from "./Data";
import { useTranslation } from "react-i18next";

const FastSearchLinks = () => {
  const { t } = useTranslation("home");
  return (
    <>
      <div className="w-full services_fast_nav">
        <h1 className="Services_nav_Head">{t("what look for")}</h1>
        <div className="flex flex-wrap w-full gap-8 pb-4">
          {navigationToLInks.map((item, idx) => (
            <Link
              key={idx}
              className="relative w-48 h-48 overflow-hidden transition-all duration-300 shadow-md Service_catigory_card rounded-xl hover:shadow-lg group"
              to="explore"
              state={{ homeSpaceType: item.linkTO }}
            >
              <div className="absolute inset-0 bg-gray-200">
                <img
                  src={item.src}
                  alt={item.title}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-300 bg-black bg-opacity-40 group-hover:bg-opacity-60">
                <div className="text-center text-white transition-all duration-300 transform -translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100">
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="w-16 h-16 mx-auto mb-2"
                  />
                </div>
                <p className="font-bold text-white transition-all duration-300 transform translate-y-full opacity-100 group-hover:translate-y-0 group-hover:opacity-100 group-hover:text-green-500">
                  {" "}
                  {t(item.title)}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default FastSearchLinks;
