import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import Footer from "../ui/footer/Footer";
import { fetchAllCompanies } from "../../data_featching/community";
import { useTranslation } from "react-i18next";
import { Spinner } from "../ui/Spinner";
import EmptyState from "../ui/EmptyState";
import { CommunityModal } from "../ui/Models";
import AuthContext from "../../context/AuthProvider";

const Community = () => {
  const [companies, setCompanies] = useState([]);
  const [Loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation("community");
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [showComModel, setShowComModel] = useState(false);
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await fetchAllCompanies(i18n.language);
      if (response?.status === 429) {
        // resend the request after 1 minute
        const message = response.data.message;
        const waitTimeMatch = message.match(/(\d+)\s*seconds/);
        const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;

        setTimeout(() => {
          fetchCompanies();
        }, waitTime * 1000);
      }
      if (response?.status === 200 || response?.status === 201) {
        setCompanies(response?.data?.data);
        setLoading(false);
      }
      if (response?.status === 404) {
        setLoading(false);
      }
    };
    fetchCompanies();
  }, [i18n.language]);
  console.log("compaines", companies);
  return (
    <div className="">
      {showComModel && (
        <CommunityModal
          isOpen={showComModel}
          onClose={() => setShowComModel(false)}
        />
      )}

      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {!showBurgerLoginMenu && (
        <>
          <div className="bg-[#F9FAFB] ">
            <div
              style={{
                backgroundImage: `url(/community.png)`,
                backgroundSize: "cover",
                backgroundPosition: "stretch",
                height: "450px",
                width: "100%",
                position: "relative",
              }}
              className="my-auto booking-image"
            >
              <div className="text-center absolute top-1/2 rtl:right-0 ltr:left-0  transform -translate-y-1/2 ltr:md:text-left rtl:md:text-right  text-white  ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px]">
                <h1 className="mb-4 text-5xl font-bold">
                  {t("our community")}
                </h1>

                <p className="mb-8 text-xl font-normal">{t("connect")}</p>
                {!userInfo?.has_business_profile && (
                  <button
                    onClick={() => setShowComModel(true)}
                    className="flex items-center justify-center gap-2 bg-[#009E1E] w-[13.688rem] py-[0.875em] px-5 rounded-lg mt-4"
                  >
                    <span className="text-base font-medium text-white ">
                      {t("Join Community")}
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_8760_1869)">
                        <path
                          d="M5.85419 2.39441C8.31589 0.820465 11.6841 0.820465 14.1458 2.39441C14.1734 3.66318 15.2092 4.6875 16.4844 4.6875C17.7769 4.6875 18.8281 3.63632 18.8281 2.34375C18.8281 1.05118 17.7769 0 16.4844 0C15.6032 0 14.8434 0.494385 14.4432 1.2149C11.7543 -0.332794 8.24554 -0.332794 5.55679 1.2149C5.15656 0.494385 4.39682 0 3.51562 0C2.22305 0 1.17188 1.05118 1.17188 2.34375C1.17188 3.63632 2.22305 4.6875 3.51562 4.6875C4.7908 4.6875 5.82657 3.66318 5.85419 2.39441Z"
                          fill="white"
                        />
                        <path
                          d="M12.3438 11.7578C12.3438 10.4652 11.2926 9.41406 10 9.41406C8.70743 9.41406 7.65625 10.4652 7.65625 11.7578C7.65625 13.0504 8.70743 14.1016 10 14.1016C11.2926 14.1016 12.3438 13.0504 12.3438 11.7578Z"
                          fill="white"
                        />
                        <path
                          d="M16.4844 4.6875C14.5457 4.6875 12.9688 6.2645 12.9688 8.20312V10C12.9688 10.3238 13.2309 10.5859 13.5547 10.5859H17.4361C16.8837 12.9298 15.2252 14.8579 13.0011 15.8124C12.3851 14.792 11.2764 14.1016 10 14.1016C8.7236 14.1016 7.6149 14.792 6.9989 15.8125C4.77463 14.8579 3.11615 12.9298 2.56393 10.5859H6.44531C6.7691 10.5859 7.03125 10.3238 7.03125 10V8.20312C7.03125 6.2645 5.45425 4.6875 3.51562 4.6875C1.577 4.6875 0 6.2645 0 8.20312V10C0 10.3238 0.262146 10.5859 0.585938 10.5859H1.3681C1.94351 13.4193 3.89709 15.7626 6.56006 16.8959C6.51138 17.1289 6.48438 17.3698 6.48438 17.6172V19.4141C6.48438 19.7379 6.74652 20 7.07031 20H12.9297C13.2535 20 13.5156 19.7379 13.5156 19.4141V17.6172C13.5156 17.3698 13.4886 17.1289 13.4399 16.8959C16.1029 15.7626 18.0565 13.4193 18.6319 10.5859H19.4141C19.7379 10.5859 20 10.3238 20 10V8.20312C20 6.2645 18.423 4.6875 16.4844 4.6875Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8760_1869">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
              </div>
            </div>
            <div className="xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] my-10">
              {companies.length === 0 && Loading && <Spinner />}
              {companies.length === 0 && !Loading && (
                <div className="flex justify-center items-center h-[470px]  w-full">
                  <EmptyState message={t("no companies")} />
                </div>
              )}
              <div className="grid gap-4 xl:grid-cols-3 md:grid-cols-2 sm:gid-cols-1 gap-y-4">
                {companies.map((company) => (
                  <Link
                    to={`/community/${company.uuid}`}
                    key={company.uuid}
                    className=" companyCard relative rounded-lg shadow-sm max-h-[335px] my-4"
                  >
                    <div
                      className={`flex flex-wrap gap-1 absolute top-[1.125rem] 
                  ${
                    i18n.language === "ar"
                      ? "right-[1.125rem]"
                      : "left-[1.125rem]"
                  }`}
                    >
                      {company?.business_segments?.map((segment) => (
                        <p
                          key={segment.id}
                          className="font-medium text-sm  bg-[#B8E9B7] px-2 py-1 rounded-3xl"
                        >
                          {segment.name}
                        </p>
                      ))}
                    </div>
                    <div className="companyImg w-full h-[124px] ">
                      <img
                        src={company.cover?.url || "/company1.png"}
                        alt="company1"
                        className="w-full h-full rounded-t-lg"
                      />
                    </div>
                    <div className="bg-white ">
                      <img
                        src={company.logo?.url || "/companyLogo.png"}
                        alt="companyLogo"
                        className="m-auto absolute rounded-full w-[100px] h-[100px]  top-20 left-1/2 transform -translate-x-1/2"
                      />
                    </div>
                    <div className="p-2 bg-white rounded-b-lg ">
                      <h1 className="mt-12 text-xl font-medium text-center">
                        {company.name}
                      </h1>
                      <p className="text-[15px] font-normal flex items-center my-4 gap-2">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.48157 14.2213L8.24417 13.7813L8.48157 14.2213ZM7.5188 14.2213L7.7562 13.7813H7.7562L7.5188 14.2213ZM13.1468 7.20428C13.1468 8.97138 12.2835 10.4243 11.2142 11.5474C10.1443 12.671 8.90372 13.4254 8.24417 13.7813L8.71897 14.6614C9.4249 14.2805 10.7669 13.4673 11.9384 12.237C13.1103 11.0061 14.1468 9.31887 14.1468 7.20428H13.1468ZM8.00018 2.05762C10.8426 2.05762 13.1468 4.36186 13.1468 7.20428H14.1468C14.1468 3.80957 11.3949 1.05762 8.00018 1.05762V2.05762ZM2.85352 7.20428C2.85352 4.36186 5.15776 2.05762 8.00018 2.05762V1.05762C4.60547 1.05762 1.85352 3.80957 1.85352 7.20428H2.85352ZM7.7562 13.7813C7.09664 13.4254 5.85603 12.671 4.78621 11.5474C3.71687 10.4243 2.85352 8.97138 2.85352 7.20428H1.85352C1.85352 9.31887 2.89006 11.0061 4.06199 12.237C5.23345 13.4673 6.57547 14.2805 7.2814 14.6614L7.7562 13.7813ZM8.24417 13.7813C8.089 13.865 7.91137 13.865 7.7562 13.7813L7.2814 14.6614C7.73291 14.9049 8.26745 14.9049 8.71897 14.6614L8.24417 13.7813ZM9.9198 7.20448C9.9198 8.26486 9.06019 9.12448 7.9998 9.12448V10.1245C9.61247 10.1245 10.9198 8.81715 10.9198 7.20448H9.9198ZM7.9998 5.28448C9.06019 5.28448 9.9198 6.14409 9.9198 7.20448H10.9198C10.9198 5.59181 9.61247 4.28448 7.9998 4.28448V5.28448ZM6.0798 7.20448C6.0798 6.14409 6.93942 5.28448 7.9998 5.28448V4.28448C6.38713 4.28448 5.0798 5.59181 5.0798 7.20448H6.0798ZM7.9998 9.12448C6.93942 9.12448 6.0798 8.26486 6.0798 7.20448H5.0798C5.0798 8.81715 6.38713 10.1245 7.9998 10.1245V9.12448Z"
                            fill="#009E1E"
                          />
                        </svg>
                        <span>{company.address}</span>
                      </p>
                      <p className="text-[15px] font-normal flex items-center my-4 gap-2">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 4.5V11.5C14.5 12.3284 13.8284 13 13 13H3C2.17157 13 1.5 12.3284 1.5 11.5V4.5M14.5 4.5C14.5 3.67157 13.8284 3 13 3H3C2.17157 3 1.5 3.67157 1.5 4.5M14.5 4.5V4.66181C14.5 5.1827 14.2298 5.6663 13.7861 5.93929L8.78615 9.01622C8.30404 9.3129 7.69596 9.3129 7.21385 9.01622L2.21385 5.93929C1.77023 5.6663 1.5 5.1827 1.5 4.66181V4.5"
                            stroke="#009E1E"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>{company.email}</span>
                      </p>
                      <p className="text-[15px] font-normal flex items-center my-4 gap-2">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.9237 10.5499L12.1583 8.78452C11.7027 8.32891 10.964 8.32891 10.5084 8.78452L9.9018 9.3911C8.48968 8.66098 7.33902 7.51032 6.6089 6.0982L7.21548 5.49163C7.67109 5.03601 7.67109 4.29732 7.21548 3.84171L5.45007 2.07629C5.01741 1.64363 4.31593 1.64363 3.88327 2.07629C2.42384 3.53572 2.25964 5.84619 3.498 7.49734L4.28572 8.54762C5.18591 9.74788 6.25213 10.8141 7.45238 11.7143L8.50266 12.502C10.1538 13.7404 12.4643 13.5762 13.9237 12.1167C14.3564 11.6841 14.3564 10.9826 13.9237 10.5499ZM9.82761 9.46528C9.82768 9.46521 9.82776 9.46514 9.82783 9.46506L9.82761 9.46528Z"
                            stroke="#009E1E"
                          />
                        </svg>

                        <span>{company.phone}</span>
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full py-4 bg-[#252C34] ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px]">
            <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
              <p className="text-4xl font-extrabold text-green-500">
                {t("get workey")}
              </p>
              <div className="flex gap-8">
                <div>
                  <img
                    src="/Group.png"
                    alt="google play img"
                    className="w-full"
                  />
                </div>
                <div>
                  <img
                    src="/AppleStore.png"
                    alt="Apple Store img"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Community;
