import { useState, useEffect, useContext } from "react";
import {
  getBookingList,
  getPaymentCheckout,
} from "../../data_featching/booking";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import { Link, useNavigate } from "react-router-dom";
// import useUnAuthorized from "../../hooks/useUnAuthorized";
import FeedbackForm from "../ui/feedback/FeedbackModel";
import { formatDateAndTime } from "../../helpers/timeFormat";
import PaginationUI from "../ui/pagination/pagination.jsx";
import { getPageList } from "../ui/pagination/pagingList.js";
import Footer from "../ui/footer/Footer";
import { Spinner } from "../ui/Spinner.jsx";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider.jsx";
import EmptyState from "../ui/EmptyState.jsx";

const BookingList = () => {
  const [bookingsList, setBookingsList] = useState([]);
  const [booking, setBooking] = useState({});
  const [pagination, setPagination] = useState({});
  const { t } = useTranslation("bookings");
  const [isLoading, setIsLoading] = useState(false);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const { language } = useContext(LanguageContext);
  const token = localStorage.getItem("authToken");

  const stateColors = {
    warning: "#F59E0B",
    success: "#22C55E",
    danger: "#EF4444",
  };

  const navigate = useNavigate();
  if (!token) {
    navigate("/login");
  }
  const getPaymentUrl = async (id) => {
    try {
      const response = await getPaymentCheckout(id, language);
      if (response.status == 200 || response.status === 201) {
        window.location.href = response.data.data.payment_url;
      } else {
        throw new Error("an error happened");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getBookings = async (page) => {
    try {
      setIsLoading(true);

      const response = await getBookingList(token, page, language);

      if (response?.status === 429) {
        const message = response.data.message;
        const waitTimeMatch = message.match(/(\d+)\s*seconds/);
        const waitTime = waitTimeMatch ? parseInt(waitTimeMatch[1], 10) : 60;
        setTimeout(() => {
          getBookings(page);
        }, waitTime * 1000);
      }
      if (response?.status === 401) {
        navigate("/login");
        setIsLoading(false);

        return;
      }

      if (response?.status === 200 || response?.status === 201) {
        setBookingsList(response.data?.data);

        const totalPageNo = response.data?.meta?.last_page;
        const currentPage = response.data?.meta?.current_page;
        const pagesList = getPageList(totalPageNo, currentPage);
        setPagination({
          page: currentPage,
          pagesCount: totalPageNo,
          pagesList,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookings(1);
  }, [language]);

  return (
    <div>
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
        disabled={token}
      />
      {showFeedbackForm && (
        <FeedbackForm
          booking={booking}
          onClose={() => setShowFeedbackForm(false)}
        />
      )}

      {!showBurgerLoginMenu && (
        <>
          <div className="">
            <div
              style={{
                backgroundImage: `url(/bookingListImg.png)`,
                backgroundSize: "cover",
                backgroundPosition: "stretch",
                height: "450px",
                width: "100%",
                position: "relative",
              }}
              className="my-auto booking-image"
            >
              <div className="text-center absolute top-1/2 rtl:right-0 ltr:left-0  transform -translate-y-1/2 ltr:md:text-left rtl:md:text-right  text-white  ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px]">
                <h1 className=" font-bold text-[48px] text-white">
                  {t("manage")}
                </h1>
                <p className="text-xl font-normal text-white">
                  {t("hero-message")}
                </p>
              </div>
            </div>
            <div className="xl:px-[112px] lg:px-[70px] md:px[48px] px-[16px] bg-[#F9FAFB] py-10">
              <p className="font-semibold text-xl my-5 text-[#000021]">
                {t("my")}
              </p>
              {bookingsList?.map((booking) => {
                const { checkin, checkout } = formatDateAndTime(
                  language,
                  booking.start_at,
                  booking.end_at
                );
                return (
                  <Link
                    to={`/bookings/${booking.id}/nostatus`}
                    key={booking.id}
                  >
                    <div className="bookingCard relative flex flex-row md:gap-2 gap-0.5 h-[270px] md:h-[220px] rounded-2xl bg-white shadow-lg my-4">
                      <p
                        className={`font-semibold text-lg absolute bottom-6 ltr:left-1 rtl:right-1 z-10 md:hidden  w-[40%]`}
                        style={{
                          color: stateColors[booking?.state?.color],
                        }}
                      >
                        {booking?.state?.label}
                      </p>
                      <div className="bookingCardImage w-[290px]   relative">
                        <img
                          src={booking?.space?.media[0]?.url || "photo_GA2.png"}
                          alt="space"
                          className="object-cover w-full h-full rounded-tl-2xl rounded-bl-2xl"
                        />
                      </div>
                      <div className="flex flex-col justify-between w-full md:p-3 bookingCardDetails p-0.5">
                        <div className="flex justify-between gap-2 fslex-row">
                          <p className="text-xl font-bold line-clamp-2">
                            {booking?.space?.name} (
                            {booking?.space?.workspace.name})
                          </p>
                          <p
                            className={`font-semibold text-lg hidden md:flex`}
                            style={{
                              color: stateColors[booking?.state?.color],
                            }}
                          >
                            {booking?.state?.label}
                          </p>
                        </div>
                        <p className="font-normal text-base flex items-center gap-2 text-[#6B7280] sm:mt-4 mt-0.5">
                          <svg
                            width="10"
                            height="11"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.39849 10.6325C6.52699 10.0237 9.66732 8.04132 9.66732 4.83332C9.66732 2.25599 7.57798 0.166656 5.00065 0.166656C2.42332 0.166656 0.333984 2.25599 0.333984 4.83332C0.333984 8.04132 3.47431 10.0237 4.60281 10.6325C4.85351 10.7678 5.14779 10.7678 5.39849 10.6325ZM5.00065 6.83332C6.10522 6.83332 7.00065 5.93789 7.00065 4.83332C7.00065 3.72875 6.10522 2.83332 5.00065 2.83332C3.89608 2.83332 3.00065 3.72875 3.00065 4.83332C3.00065 5.93789 3.89608 6.83332 5.00065 6.83332Z"
                              fill="#009E1E"
                            />
                          </svg>
                          {booking?.space?.workspace?.address
                            ?.building_number ||
                            booking?.space?.workspace?.address?.street_name}
                          , {booking?.space?.workspace?.address?.district.name}
                        </p>
                        <div className="flex flex-col justify-start gap-3 text-base font-normal lg:flex-row">
                          <span className="flex items-center gap-2">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="5" cy="5" r="5" fill="#22C55E" />
                            </svg>
                            {checkin.formattedDate} - {checkin.formattedTime}
                          </span>
                          <span className="flex items-center gap-2">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="5" cy="5" r="5" fill="#EF4444" />
                            </svg>
                            {checkout.formattedDate} - {checkout.formattedTime}
                          </span>
                        </div>
                        <div className="flex items-center justify-between gap-6 mt-2">
                          <div className="flex items-center gap-6 ">
                            <p className="flex items-center gap-2">
                              <span className="text-xl font-bold">
                                {booking?.final_price}
                              </span>
                              <span className="font-normal text-[16px]">
                                {booking?.currency.label}
                              </span>
                            </p>

                            {booking?.paid_at === "host" && (
                              <p className="text-base font-normal text-destructive">
                                {t("unpaid")}
                              </p>
                            )}
                          </div>
                          <div className="flex items-center gap-2">
                            <Link
                              to={`/bookings/${booking.id}/nostatus`}
                              className="bg-white hidden lg:flex  px-4 py-1 rounded-lg border border-[#009E1E]  items-center gap-2"
                            >
                              {t("view booking")}
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.8334 4.16797H15.0001V2.5013C15.0001 2.28029 14.9123 2.06833 14.756 1.91205C14.5997 1.75577 14.3878 1.66797 14.1667 1.66797C13.9457 1.66797 13.7338 1.75577 13.5775 1.91205C13.4212 2.06833 13.3334 2.28029 13.3334 2.5013V4.16797H6.66675V2.5013C6.66675 2.28029 6.57895 2.06833 6.42267 1.91205C6.26639 1.75577 6.05443 1.66797 5.83342 1.66797C5.6124 1.66797 5.40044 1.75577 5.24416 1.91205C5.08788 2.06833 5.00008 2.28029 5.00008 2.5013V4.16797H4.16675C3.50371 4.16797 2.86782 4.43136 2.39898 4.9002C1.93014 5.36904 1.66675 6.00493 1.66675 6.66797V7.5013H18.3334V6.66797C18.3334 6.00493 18.07 5.36904 17.6012 4.9002C17.1323 4.43136 16.4965 4.16797 15.8334 4.16797Z"
                                  fill="#000021"
                                />
                                <path
                                  d="M1.66675 15.8346C1.66675 16.4977 1.93014 17.1336 2.39898 17.6024C2.86782 18.0712 3.50371 18.3346 4.16675 18.3346H15.8334C16.4965 18.3346 17.1323 18.0712 17.6012 17.6024C18.07 17.1336 18.3334 16.4977 18.3334 15.8346V9.16797H1.66675V15.8346Z"
                                  fill="#000021"
                                />
                              </svg>
                            </Link>
                            {booking?.can_be_rated && (
                              <button
                                onClick={() => {
                                  setBooking(booking);
                                  setShowFeedbackForm(true);
                                }}
                                className="bg-[#009E1E] text-white px-4 py-1 rounded-lg lg:flex items-center gap-2 hidden "
                              >
                                {t("rate")}
                                <img
                                  src="/like_rate.svg"
                                  alt="like"
                                  className="w-5 h-8"
                                />
                              </button>
                            )}
                            {booking?.transaction?.can_pay_online && (
                              <Link to={"#"}>
                                <button
                                  onClick={() =>
                                    getPaymentUrl(booking?.transaction?.id)
                                  }
                                  className="bg-[#009E1E] text-white px-4 py-2 rounded-lg flex items-center gap-2"
                                >
                                  {t("pay now")}
                                </button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {bookingsList?.length === 0 && isLoading && <Spinner />}
              {bookingsList?.length === 0 && !isLoading && (
                <div className="flex justify-center items-center h-[470px]  w-full">
                  <EmptyState message={t("No Bookings")} />
                </div>
              )}

              <div className="flex justify-center">
                <PaginationUI
                  pagination={pagination}
                  changePageHandler={setPagination}
                  fetchDataHandler={getBookings}
                  currentLanguage={"en"}
                />
              </div>
            </div>
          </div>
          <div className="w-full py-4 bg-[#252C34] ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px]">
            <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
              <p className="text-4xl font-extrabold text-green-500">
                {t("get workey")}
              </p>
              <div className="flex gap-8">
                <div>
                  <img
                    src="/Group.png"
                    alt="google play img"
                    className="w-full"
                  />
                </div>
                <div>
                  <img
                    src="/AppleStore.png"
                    alt="Apple Store img"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};
export default BookingList;
