import { useContext, useEffect, useState } from "react";
import {
  fetchSpaceDetails,
  fetchSpaceResources,
} from "../../data_featching/spaces";
import { getThreadForWorkspace } from "../../data_featching/conversations";
import {
  addRemoveFavourites,
  getFavourites,
} from "../../data_featching/favorites";
import { useParams, useNavigate, Link } from "react-router-dom";
import Footer from "../ui/footer/Footer";
import LoginNavBar from "../ui/login-nav_bar/LoginNavBar";
import BookingModal from "../booking/BookingModal";
import { Spinner } from "../ui/Spinner";
import {
  OverallRatingItem,
  getRatingCounts,
  getOverAllRating,
} from "../ui/feedback/OverallRatingItem";
import EmptyRatings from "../ui/feedback/empty/EmptyRatings";
import EmptyReviwes from "../ui/feedback/empty/EmptyReviwes";
import ReviewCard from "../ui/feedback/ReviewCard";
import { useTranslation } from "react-i18next";
import {
  getAllRatingsForSpace,
  getAllReviewsForSpaceWithoutFilters,
} from "../../data_featching/Feedback";
import { Star } from "lucide-react";
import AuthContext from "../../context/AuthProvider";
import ScrollableCardContainer from "../ui/GenericScroll";
import WorkingHoursModal from "../ui/ScheduleModel";
import LocateMap from "../ui/Map/LocateMap";
import { AuthModal } from "../ui/Models";
import StatusBar from "../ui/status-bar/StatusBar";

const ShowSpace = () => {
  const [space, setSpace] = useState(null);
  const [resources, setResources] = useState([]);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const { id: spaceID } = useParams();
  const navigate = useNavigate();
  const { newConversation, setNewConversation, userInfo } =
    useContext(AuthContext);
  const [isFavourite, setIsFavourite] = useState(false);
  const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { t, i18n } = useTranslation("workspace");
  const [statusMessage, setStatusMessage] = useState({
    isActive: false,
    message: "",
    currentStatus: "",
  });
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    if (showScheduleModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showScheduleModal]);

  useEffect(() => {
    const getSpaceData = async () => {
      const response = await fetchSpaceDetails(spaceID, i18n.language);
      if (response?.status === 200 || response?.status === 201) {
        setSpace(response?.data?.data);
        console.log("Space:", response.data.data);
      }
    };
    getSpaceData();
  }, [i18n.language, spaceID]);

  useEffect(() => {
    const getResources = async () => {
      const response = await fetchSpaceResources(spaceID, i18n.language);
      if (response?.status === 200 || response?.status === 201) {
        setResources(response.data.data);
        console.log("Resources:", response.data.data);
      }
    };
    getResources();
  }, [i18n.language, spaceID]);

  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState([]);

  const ratingsList = async () => {
    const result = await getAllRatingsForSpace(token, spaceID);
    if (result?.status == 200 || result?.status == 201) {
      setRatings(result?.data?.data);
    }
  };
  /*get ratings for this Space */

  const reviewsList = async () => {
    const result = await getAllReviewsForSpaceWithoutFilters(
      token,
      spaceID,
      i18n.language
    );
    if (result?.status == 200 || result?.status == 201) {
      const filteredReviews = result?.data?.data.filter(
        (review) => review.review !== null && review.review !== ""
      );

      setReviews(filteredReviews);
    }
  };
  useEffect(() => {
    ratingsList();
  }, []);
  useEffect(() => {
    reviewsList();
  }, []);

  const ratingCount = getRatingCounts(ratings);
  const ratingStars = getOverAllRating(ratings);

  const handleChatWithOwner = async () => {
    const response = await getThreadForWorkspace(token, space?.workspace?.id);
    if (response?.status == 200 || response?.status == 201) {
      setNewConversation(response?.data?.data);
      console.log("newConversation", newConversation);
      navigate(`/conversations`);
      console.log("newConvResponse", response);
    }
  };

  const getAllFavorites = async () => {
    try {
      const response = await getFavourites(token);
      if (response?.status === 200 || response?.status === 201) {
        const favList = response?.data?.data;
        if (favList.length === 0) {
          setIsFavourite(false);
        } else {
          const fav = favList.find((fav) => fav.space.id === space?.id);
          setIsFavourite(fav ? true : false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("Space ID:", spaceID);
    if (space) getAllFavorites();
  }, [space]);

  const handleFavourites = async () => {
    try {
      setStatusMessage({
        isActive: true,
        message: isFavourite
          ? t("removing from favourites")
          : t("adding to favourites"),
        currentStatus: 1,
      });
      const response = await addRemoveFavourites(token, spaceID);
      if (response?.status == 200 || response?.status == 201) {
        console.log("Favourites:", response.data);
        setStatusMessage({
          isActive: true,
          message: isFavourite
            ? t("removed from favourites")
            : t("added to favourites"),
          currentStatus: 3,
        });
        setIsFavourite(!isFavourite);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setStatusMessage({
        isActive: true,
        message: t("Error to change favourites"),
        currentStatus: 2,
      });
    }
  };
  // RESET STATUS MESSAGE
  useEffect(() => {
    const timer = setTimeout(() => {
      setStatusMessage({
        isActive: false,
        message: "",
        currentStatus: "",
      });
    }, 3000);
    return () => clearTimeout(timer);
  }, [statusMessage.isActive]);
  function getDirections() {
    // Get the latitude & longitude of the location
    const latitude = space?.workspace?.address?.latitude;
    const longitude = space?.workspace?.address?.longitude;
    // Construct the Google Maps directions URL
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    // Open the URL in a new tab
    window.open(url, "_blank");
  }
  if (!space) {
    return <Spinner />;
  }

  return (
    <>
      <LoginNavBar
        showBurgerLoginMenu={showBurgerLoginMenu}
        setShowBurgerLoginMenu={setShowBurgerLoginMenu}
      />
      {showScheduleModal && (
        <WorkingHoursModal
          isOpen={showScheduleModal}
          onClose={() => setShowScheduleModal(false)}
          workingDays={space?.workspace?.working_days}
        />
      )}
      {statusMessage.isActive && (
        <StatusBar
          message={statusMessage.message}
          statusFlag={statusMessage.currentStatus}
          currentLanguage={i18n.language}
        />
      )}
      {showAuthModal && (
        <AuthModal
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
        />
      )}
      {!showBurgerLoginMenu && (
        <>
          <div className="spacePage">
            <div className="w-full">
              {space?.media?.length > 0 && (
                <img
                  src={space?.media[0]?.url}
                  alt="Meeting Room"
                  className="object-cover w-full h-96"
                  width="1200"
                  height="400"
                  style={{ aspectRatio: "1200/400", objectFit: "cover" }}
                />
              )}
            </div>
            <div className="w-full  xl:px-[112px] lg:px-[70px] md:px[48px] xs:px-[16px] mt-8">
              <div className="flex items-start justify-between gap-4 xs:flex-col lg:flex-row">
                <div className="xs:w-full">
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-2xl font-bold">
                      {space?.name} ({space?.workspace?.name})
                    </h1>
                    {!space?.is_archived && (
                      <button
                        className={` text-center align-middle rounded-full shadow-md p-2 w-[40px] h-[40px] bg-white`}
                        onClick={handleFavourites}
                      >
                        <svg
                          width="22"
                          height="19"
                          viewBox="0 0 22 19"
                          fill={isFavourite ? "#009E1E" : "none"}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.40325 11.0361L10.4846 17.6883C10.662 17.855 10.7507 17.9383 10.8472 17.9806C11.0035 18.049 11.1813 18.049 11.3376 17.9806C11.434 17.9383 11.5227 17.855 11.7001 17.6883L18.7815 11.0361C20.7739 9.16449 21.0159 6.0845 19.3401 3.9247L19.0251 3.51859C17.0204 0.934846 12.9966 1.36816 11.5881 4.31946C11.3891 4.73634 10.7957 4.73634 10.5967 4.31946C9.18812 1.36816 5.16432 0.934844 3.15969 3.51858L2.8446 3.92469C1.1689 6.0845 1.41085 9.16449 3.40325 11.0361Z"
                            stroke={isFavourite ? "#009E1E" : "#252C34"}
                            strokeWidth="1.83333"
                          />
                        </svg>
                      </button>
                    )}
                  </div>

                  <div className="flex items-center space-x-2">
                    {ratingStars > 0 && (
                      <>
                        <div className="flex items-center my-2">
                          {Array.from({ length: ratingStars }).map(
                            (_, index) => (
                              <Star
                                key={index}
                                size={24}
                                className="text-yellow-400"
                                fill="currentColor"
                              />
                            )
                          )}
                        </div>

                        <span className="text-gray-500">
                          {ratingStars} {t("based_on")} {ratingCount}{" "}
                          {`${ratingCount > 1 ? t("reviews") : t("review")}`}
                        </span>
                      </>
                    )}
                  </div>
                  <p className="flex items-center text-gray-500">
                    <svg
                      className="me-2"
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.39804 16.804L8.74336 17.4697H8.74336L8.39804 16.804ZM7.60196 16.804L7.25664 17.4697H7.25664L7.60196 16.804ZM14.25 8C14.25 10.2364 13.111 12.0661 11.7225 13.4666C10.3347 14.8664 8.76052 15.7711 8.05271 16.1382L8.74336 17.4697C9.51848 17.0677 11.2453 16.0785 12.7877 14.5227C14.3295 12.9676 15.75 10.78 15.75 8H14.25ZM8 1.75C11.4518 1.75 14.25 4.54822 14.25 8H15.75C15.75 3.71979 12.2802 0.25 8 0.25V1.75ZM1.75 8C1.75 4.54822 4.54822 1.75 8 1.75V0.25C3.71979 0.25 0.25 3.71979 0.25 8H1.75ZM7.94729 16.1382C7.23948 15.7711 5.66527 14.8664 4.27749 13.4666C2.88902 12.0661 1.75 10.2364 1.75 8H0.25C0.25 10.78 1.67049 12.9676 3.21227 14.5227C4.75474 16.0785 6.48152 17.0677 7.25664 17.4697L7.94729 16.1382ZM8.05271 16.1382C8.01637 16.157 7.98363 16.157 7.94729 16.1382L7.25664 17.4697C7.72602 17.7132 8.27398 17.7132 8.74336 17.4697L8.05271 16.1382ZM10.25 8C10.25 9.24264 9.24264 10.25 8 10.25V11.75C10.0711 11.75 11.75 10.0711 11.75 8H10.25ZM8 5.75C9.24264 5.75 10.25 6.75736 10.25 8H11.75C11.75 5.92893 10.0711 4.25 8 4.25V5.75ZM5.75 8C5.75 6.75736 6.75736 5.75 8 5.75V4.25C5.92893 4.25 4.25 5.92893 4.25 8H5.75ZM8 10.25C6.75736 10.25 5.75 9.24264 5.75 8H4.25C4.25 10.0711 5.92893 11.75 8 11.75V10.25Z"
                        fill="#000021"
                      />
                    </svg>
                    {space?.workspace?.address?.building_number ||
                      space?.workspace?.address?.street_name}
                    , {space?.workspace?.address?.district.name}
                  </p>
                  <p className="mt-10 font-[600] text-xl">{t("workspace")}</p>
                  <div className="flex items-center mt-2 space-x-2">
                    <p className=" flex items-center gap-2 font-[600] text-lg ">
                      <img
                        className=" w-[40px] h-[40px] rounded-full "
                        src={space?.workspace?.logo?.url}
                      />
                      {space?.workspace?.name}
                    </p>
                    {!space?.is_archived && (
                      <Link
                        to={`/explore/workspace/${space?.workspace?.id}`}
                        className="flex items-center gap-1 text-blue-500 ltr:pl-4 rtl:pr-4"
                      >
                        {t("explore")}
                        <svg
                          className="text-sm"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21 3V2H22V3H21ZM11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L11.7071 13.7071ZM20 11V3H22V11H20ZM21 4H13V2H21V4ZM21.7071 3.70711L11.7071 13.7071L10.2929 12.2929L20.2929 2.29289L21.7071 3.70711Z"
                            fill="#2563EB"
                          />
                          <path
                            d="M20 15V15C20 16.8692 20 17.8038 19.5981 18.5C19.3348 18.9561 18.9561 19.3348 18.5 19.5981C17.8038 20 16.8692 20 15 20H10C7.17157 20 5.75736 20 4.87868 19.1213C4 18.2426 4 16.8284 4 14V9C4 7.13077 4 6.19615 4.40192 5.5C4.66523 5.04394 5.04394 4.66523 5.5 4.40192C6.19615 4 7.13077 4 9 4V4"
                            stroke="#2563EB"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </Link>
                    )}
                  </div>
                  <h2 className="mt-4 text-xl font-bold">{t("about")}</h2>
                  <p className="mt-2 text-gray-700">{space?.about}</p>
                  <h2 className="mt-4 text-xl font-bold">
                    {t("basic amenities")}
                  </h2>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    {space?.amenities?.map((amenity) => (
                      <div
                        key={crypto.randomUUID()}
                        className="flex items-center "
                      >
                        <div
                          className="icon"
                          dangerouslySetInnerHTML={{
                            __html: amenity.icon,
                          }}
                        />
                        <span className="rtl:mr-2 ltr:ml-2">
                          {amenity.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="xs:w-full lg:max-w-[400px]">
                  <div className="">
                    <h2 className="text-xl font-bold">{t("plans")}</h2>
                    <div className="mt-2 border border-[#DCE1E9] rounded-lg">
                      <div className="p-4 border-b-2">
                        {space?.plans?.map((plan, planIndex) => (
                          <div key={planIndex} className="mt-2">
                            {plan?.is_active && (
                              <>
                                {plan?.shifts?.map((shift, shiftIndex) => (
                                  <div
                                    key={shiftIndex}
                                    className="flex items-center justify-between gap-2 mt-2"
                                  >
                                    {shift?.is_active && (
                                      <span className="whitespace-nowrap">
                                        <span className="text-xl font-bold">
                                          {shift?.price}
                                        </span>{" "}
                                        {shift?.currency?.label} /
                                        {plan?.name?.label}
                                        <span className="ml-4 text-sm text-gray-500">
                                          {shift?.name?.key !== "all" &&
                                            '"' + shift?.name?.label + '"'}
                                        </span>
                                      </span>
                                    )}
                                    {plan?.instant_booking &&
                                      shift?.is_active && (
                                        <span className="bg-[#2563EB] text-white text-[12px] flex items-center rounded-3xl px-2 py-1 whitespace-nowrap">
                                          {t("instant")}
                                          <svg
                                            className="ltr:ml-1 rtl:mr-1"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10.334 4.97272C10.2739 4.83525 10.138 4.74646 9.98788 4.74646H6.84685L8.35982 0.504986C8.42014 0.335815 8.35307 0.147495 8.19937 0.0545691C8.04556 -0.0384069 7.84767 -0.0102874 7.72592 0.121744L1.73388 6.61943C1.63214 6.72974 1.60533 6.88984 1.6656 7.02732C1.72577 7.16479 1.86168 7.25358 2.01175 7.25358H4.05375C4.26253 7.25358 4.4317 7.08436 4.4317 6.87563C4.4317 6.6669 4.26253 6.49768 4.05375 6.49768H2.87439L7.02505 1.99678L5.95469 4.99736C5.91347 5.11312 5.93292 5.24172 6.00377 5.34216C6.07457 5.44259 6.19174 5.50231 6.31465 5.50231H9.12519L4.97514 10.0027L6.04565 7.00262C6.08697 6.88687 6.06943 6.75826 5.99858 6.65783C5.92773 6.5574 5.81258 6.49763 5.68967 6.49763H5.68594C5.5083 6.49763 5.36065 6.62019 5.32059 6.78533L3.64006 11.4949C3.57969 11.6641 3.64677 11.8524 3.80047 11.9453C3.86119 11.9821 3.92882 11.9999 3.99589 11.9999C4.09865 11.9999 4.20024 11.958 4.27391 11.8781L10.2656 5.38051C10.3674 5.27025 10.3943 5.1102 10.334 4.97272Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        ))}
                      </div>

                      <div className="flex items-center justify-between mt-2">
                        {/* <span>500 SAR insurance to be paid at the host</span> */}
                      </div>
                      <div className="p-4">
                        {space?.type?.key === "long_term" &&
                          !space?.is_archived && (
                            <Link
                              className=" flex items-center justify-center  w-full font-medium bg-[#009E1E] p-4 rounded-xl text-[16px] text-white"
                              onClick={() =>
                                !userInfo
                                  ? setShowAuthModal(true)
                                  : handleChatWithOwner()
                              }
                            >
                              {t("chat")}
                              <svg
                                className="ltr:ml-2 rtl:mr-2"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.8571 0C8.01682 0 5.71429 2.30254 5.71429 5.14286C5.71429 5.44284 5.74003 5.73726 5.78956 6.02392C5.84075 6.32021 5.76517 6.56957 5.62108 6.71365L0.66947 11.6653C0.240816 12.0939 0 12.6753 0 13.2815V15.4286C0 15.7442 0.255837 16 0.571429 16H3.42857C3.74416 16 4 15.7442 4 15.4286V14.2857H5.14286C5.45845 14.2857 5.71429 14.0299 5.71429 13.7143V12.5714H6.85714C7.0087 12.5714 7.15404 12.5112 7.2612 12.4041L9.28635 10.3789C9.43043 10.2348 9.67979 10.1593 9.97608 10.2104C10.2627 10.26 10.5572 10.2857 10.8571 10.2857C13.6975 10.2857 16 7.98318 16 5.14286C16 2.30254 13.6975 0 10.8571 0ZM10.8571 2.28571C10.5416 2.28571 10.2857 2.54155 10.2857 2.85714C10.2857 3.17273 10.5416 3.42857 10.8571 3.42857C11.8039 3.42857 12.5714 4.19608 12.5714 5.14286C12.5714 5.45845 12.8273 5.71429 13.1429 5.71429C13.4584 5.71429 13.7143 5.45845 13.7143 5.14286C13.7143 3.5649 12.4351 2.28571 10.8571 2.28571Z"
                                  fill="white"
                                />
                              </svg>
                            </Link>
                          )}
                        {space?.type?.key != "long_term" &&
                          !space?.is_archived && (
                            <button
                              className=" flex items-center justify-center  w-full font-medium bg-[#009E1E] p-4 rounded-xl text-[16px] text-white"
                              onClick={() =>
                                !userInfo
                                  ? setShowAuthModal(true)
                                  : setBookingModalOpen(true)
                              }
                            >
                              {t("pass")}
                              <svg
                                className="ltr:ml-2 rtl:mr-2"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.8571 0C8.01682 0 5.71429 2.30254 5.71429 5.14286C5.71429 5.44284 5.74003 5.73726 5.78956 6.02392C5.84075 6.32021 5.76517 6.56957 5.62108 6.71365L0.66947 11.6653C0.240816 12.0939 0 12.6753 0 13.2815V15.4286C0 15.7442 0.255837 16 0.571429 16H3.42857C3.74416 16 4 15.7442 4 15.4286V14.2857H5.14286C5.45845 14.2857 5.71429 14.0299 5.71429 13.7143V12.5714H6.85714C7.0087 12.5714 7.15404 12.5112 7.2612 12.4041L9.28635 10.3789C9.43043 10.2348 9.67979 10.1593 9.97608 10.2104C10.2627 10.26 10.5572 10.2857 10.8571 10.2857C13.6975 10.2857 16 7.98318 16 5.14286C16 2.30254 13.6975 0 10.8571 0ZM10.8571 2.28571C10.5416 2.28571 10.2857 2.54155 10.2857 2.85714C10.2857 3.17273 10.5416 3.42857 10.8571 3.42857C11.8039 3.42857 12.5714 4.19608 12.5714 5.14286C12.5714 5.45845 12.8273 5.71429 13.1429 5.71429C13.4584 5.71429 13.7143 5.45845 13.7143 5.14286C13.7143 3.5649 12.4351 2.28571 10.8571 2.28571Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-4">
                    <h2 className="mb-4 text-xl font-bold">{t("location")}</h2>

                    <LocateMap address={space?.workspace?.address} />
                    <button
                      onClick={getDirections}
                      className="mt-4 p-3 bg-[#252C34] rounded-lg text-white absolute bottom-10 left-1/2 -translate-x-1/2 "
                    >
                      {t("get directions")}
                    </button>
                  </div>
                  <div className="mt-4">
                    <h2 className="text-xl font-bold">{t("working-hours")}</h2>
                    <button
                      className="mt-2 text-blue-500"
                      onClick={() => setShowScheduleModal(true)}
                    >
                      {t("check schedule")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full  py-[44px] ltr:pl-4 rtl:pr-4 ltr:lg:pl-[70px] rtl:lg:pr-[70px] ltr:xl:pl-[116px] rtl:xl:pr-[116px]">
              {resources.length > 0 && (
                <div className="flex flex-col w-full gap-6 mb-10">
                  <h1 className="mt-10 text-xl font-bold">
                    {t("availableResources")}
                  </h1>
                  <ScrollableCardContainer>
                    {/* <div className="w-[100px] flex-shrink-0"></div> */}

                    {resources.map((item) => (
                      <div className="Resources_cards" key={item.id}>
                        <div className="Resou__card h-[192px] flex w-[400px] items-start rounded-lg shadow-md bg-white">
                          <img
                            src={item.photo.url || "/Ellipse 257.png"}
                            alt=""
                            className="border-lg w-[160px] h-full rounded-lg"
                          />
                          <div className="py-2 px-[10px] flex flex-col justify-start gap-4 ">
                            <div className="flex flex-col justify-start ">
                              <h1 className="text-[#111827] font-bold text-base font_family">
                                {item.name.length > 40
                                  ? `${item.name.slice(0, 40)}...`
                                  : item.name}
                              </h1>
                              <h3 className="text-[#6B7280] font-normal text-sm font_family">
                                {item.type.label}
                              </h3>
                            </div>
                            <div className="Resources-plans ">
                              {item.plans.map(
                                (plan) =>
                                  plan.is_active && (
                                    <div
                                      className="flex items-center gap-2"
                                      key={plan.id}
                                    >
                                      <div className="flex items-center gap-1">
                                        <p className="text-lg font-bold text-[#252C34]">
                                          {plan.price === "0.00"
                                            ? 0
                                            : plan.price}{" "}
                                        </p>
                                        <span className="font-normal text-sm text-[#252C34]">
                                          {plan.currency.label}
                                        </span>
                                      </div>
                                      <span className="font-normal text-sm text-[#6B7280]">
                                        {"/ "}
                                        {plan.name.label}
                                      </span>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ScrollableCardContainer>
                </div>
              )}
              {space?.media?.length > 0 && (
                <div className="">
                  <h2 className="mt-10 text-xl font-bold">
                    {t("space photos")}
                  </h2>
                  <ScrollableCardContainer>
                    {space?.media?.map((photo) => (
                      <img
                        src={photo.url ? photo.url : "/photo_GA3.png"}
                        alt={photo.name}
                        key={photo.id}
                        className="w-[300px] h-[250px] object-cover"
                        width="100"
                        height="100"
                        style={{ aspectRatio: "100/100", objectFit: "cover" }}
                      />
                    ))}
                  </ScrollableCardContainer>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-6 w-full mb-10 mt-10 ltr:pl-4 rtl:pr-4 ltr:lg:pl-[70px] rtl:lg:pr-[70px] ltr:xl:pl-[116px] rtl:xl:pr-[116px]  py-[44px]">
              <h1 className="text-xl font-bold Basic_Amenities">
                {t("testimonials")}
              </h1>
              {reviews?.length == 0 && <EmptyReviwes />}
              <ScrollableCardContainer>
                {reviews.map((testimonial, index) => (
                  <ReviewCard
                    key={index}
                    avatar={
                      testimonial.user.profile_image?.url ||
                      "/person-avatar.svg"
                    }
                    name={`${testimonial.user.first_name} ${testimonial.user.last_name}`}
                    title={
                      testimonial.user.has_business_profile
                        ? "Business Profile"
                        : "User"
                    }
                    rating={parseFloat(testimonial.overall_rating)}
                    testimonial={testimonial.review}
                  />
                ))}
              </ScrollableCardContainer>
            </div>
            <div className="flex flex-col gap-6 w-full ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px] py-[44px]">
              <h1 className="text-xl font-bold Basic_Amenities ">
                {t("ratings")}
              </h1>
              {reviews?.length == 0 ? (
                <EmptyRatings />
              ) : (
                <OverallRatingItem
                  itemNo={false}
                  ratingItem={ratings}
                  removeTitle={true}
                />
              )}
            </div>
            {/* if it long term space */}
            {space?.type?.key === "long_term" && (
              <div className="flex flex-col gap-6 w-full ltr:pl-4 rtl:pr-4 ltr:lg:pl-[70px] rtl:lg:pr-[70px] ltr:xl:pl-[116px] rtl:xl:pr-[116px] py-[44px]">
                <div className="max-w-md p-6 bg-white border-2 border-red-500 rounded-lg">
                  <h3 className="mb-4 text-lg font-semibold text-gray-900">
                    {i18n.language === "en"
                      ? "REGA Verified Information"
                      : "معلومات موثقة من REGA"}
                  </h3>

                  <div className="flex justify-between mb-2">
                    <span className="text-gray-500">
                      {i18n.language === "en"
                        ? "FAL License Number"
                        : "رقم ترخيص FAL"}
                    </span>
                    <span className="font-semibold text-gray-900">
                      1200029691
                    </span>
                  </div>

                  {space?.advertisement_number && (
                    <div className="flex justify-between mb-2">
                      <span className="text-gray-500">
                        {i18n.language === "en"
                          ? "Advertising License Number"
                          : "رقم ترخيص الإعلان"}
                      </span>
                      <span className="font-semibold text-gray-900">
                        {space?.advertisement_number}
                      </span>
                    </div>
                  )}

                  {space?.advertisement_date && (
                    <div className="flex justify-between mb-2">
                      <span className="text-gray-500">
                        {i18n.language === "en"
                          ? "License Issue Date"
                          : "تاريخ إصدار الترخيص"}
                      </span>
                      <span className="font-semibold text-gray-900">
                        {space?.advertisement_date}
                      </span>
                    </div>
                  )}

                  <div className="flex justify-between">
                    <span className="text-gray-500">
                      {i18n.language === "en"
                        ? "License Expiry Date"
                        : "تاريخ انتهاء الترخيص"}
                    </span>
                    <span className="font-semibold text-gray-900">
                      7800265218
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="w-full py-4 bg-[#252C34] ltr:px-4 rtl:px-4 ltr:lg:px-[70px] rtl:lg:px-[70px] ltr:xl:px-[116px] rtl:xl:px-[116px]">
              <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
                <p className="text-4xl font-extrabold text-green-500">
                  {t("get workey")}
                </p>
                <div className="flex gap-8">
                  <div>
                    <img
                      src="/Group.png"
                      alt="google play img"
                      className="w-full"
                    />
                  </div>
                  <div>
                    <img
                      src="/AppleStore.png"
                      alt="Apple Store img"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
          {bookingModalOpen && (
            <BookingModal
              spaceDetails={space}
              isOpen={bookingModalOpen}
              setIsOpen={setBookingModalOpen}
            />
          )}
        </>
      )}
    </>
  );
};
export default ShowSpace;
