import { Link } from "react-router-dom";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
const Footer = () => {
  const { t } = useTranslation("home");
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  function goToWebsite(website) {
    window.open(website, "_blank");
  }

  return (
    <div className="Foooter_container">
      <div className="main_footer_content">
        <div className="logo_description">
          <div className="logo_footer">
            <img src="/WORKEY_footer.png" alt="WORKEY Logo" />
          </div>
          <div className="brand_desc lg:max-w-[25rem]">
            {t("footer_description")}
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-8 footer_main_links md:grid-cols-4">
          <div className="header_links">
            <div className="Links_Title">{t("company")}</div>
            <Link to={""} className="Links_to_section">
              {t("about")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("leadership")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("blog")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("careers")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("referral_program")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("community")}
            </Link>
          </div>
          <div className="header_links">
            <div className="Links_Title">{t("product")}</div>
            <Link to={""} className="Links_to_section">
              {t("overview")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("features")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("pricing")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("documentation")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("tools_integration")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("releases")}
            </Link>
          </div>

          <div className="header_links">
            <div className="Links_Title">{t("support")}</div>
            <Link to={""} className="Links_to_section">
              {t("help_centre")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("faq")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("contact")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("press")}
            </Link>
            <Link to={""} className="Links_to_section">
              {t("status")}
            </Link>
          </div>
          <div className="flex items-start md:items-center header_links">
            <div className="Links_Title">
              {width > 1280 ? (
                <span>{t("connect with us")}</span>
              ) : (
                <span>{t("connect")}</span>
              )}
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() =>
                  goToWebsite("https://www.linkedin.com/company/workey-sa")
                }
              >
                <img src="/linkedin-footer.svg" alt="" className="w-9 h-9" />
              </button>
              <a href="mailto:info@workey.sa">
                <img src="/email-footer.svg" alt="" className="w-9 h-9" />
              </a>
            </div>

            <div className="flex items-center gap-4">
              <button
                onClick={() =>
                  goToWebsite("https://www.instagram.com/workey.sa/#")
                }
              >
                <img src="/insta-footer.svg" alt="" className="w-9 h-9" />
              </button>
              <button
                onClick={() =>
                  goToWebsite("https://www.facebook.com/workeyksa/")
                }
              >
                <img src="/face-footer.svg" alt="" className="w-9 h-9" />
              </button>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => goToWebsite("https://www.tiktok.com/@workey.sa")}
              >
                <img src="/tiktok-footer.svg" alt="" className="w-9 h-9" />
              </button>
              <a href="https://wa.me/00966530175777" target="_blank">
                <img src="/whatsapp-footer.svg" alt="" className="w-9 h-9" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="terms_condition">
        <div className="terms_links">
          <Link to={""}>{t("terms_of_service")}</Link>
          <Link to={""}>{t("privacy_policy")}</Link>
          <Link to={""}>{t("security")}</Link>
          <Link to={""}>{t("sitemap")}</Link>
        </div>
        <div className="copy_rights">{t("copy_rights")}</div>
      </div>
    </div>
  );
};

export default Footer;
