import { AlertTriangle } from "lucide-react";
import { status } from "./StatusConstant";

const StatusBar = ({
  message = "default pending message",
  statusFlag = 1,
  currentLanguage = "en",
}) => {
  return (
    <div
      className="fixed top-0 left-0 w-full rounded-t-lg"
      style={{ zIndex: 10000 }}
    >
      {statusFlag == status.pending && (
        <div
          className={`flex gap-2 px-4 py-2 items-center bg-[#DCE1E9] rounded-t-lg ${
            currentLanguage == "ar" && "flex-row-reverse"
          }`}
        >
          <div className="flex flex-col items-center justify-center w-8 h-8 p-2 bg-white rounded-md">
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_16525_75226)">
                <path
                  d="M10 16H14C14.55 16 15 15.55 15 15V9.99997H16.59C17.48 9.99997 17.93 8.91997 17.3 8.28997L12.71 3.69997C12.32 3.30997 11.69 3.30997 11.3 3.69997L6.71 8.28997C6.08 8.91997 6.52 9.99997 7.41 9.99997H9V15C9 15.55 9.45 16 10 16ZM6 18H18C18.55 18 19 18.45 19 19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19C5 18.45 5.45 18 6 18Z"
                  fill="#009E1E"
                />
              </g>
              <defs>
                <clipPath id="clip0_16525_75226">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={`font-medium text-lg text-[#009E1E] `}>{message}</div>
        </div>
      )}
      {statusFlag == status.error && (
        <div
          className={`flex gap-2 px-4 py-2 items-center bg-[#EF4444] rounded-t-lg ${
            currentLanguage == "ar" && "flex-row-reverse"
          }`}
        >
          <div className="bg-[#F78A8A]  rounded-md w-8 h-8 flex flex-col justify-center items-center p-2">
            <AlertTriangle size={24} color="#EF4444" />
          </div>
          <div className="font-medium text-lg text-[#FFFFFF] ">{message}</div>
        </div>
      )}
      {statusFlag == status.success && (
        <div
          className={`flex gap-2 px-4 py-2 items-center bg-[#22C55E] rounded-t-lg ${
            currentLanguage == "ar" && "flex-row-reverse"
          }`}
        >
          <div className="bg-[#89D98B]  rounded-md w-8 h-8 flex flex-col justify-center items-center p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_16523_75140)">
                <path
                  d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_16523_75140">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="font-medium text-lg text-[#FFFFFF] ">{message}</div>
        </div>
      )}
    </div>
  );
};

export default StatusBar;
