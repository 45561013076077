import { useState, useEffect, useContext } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./Plans.css";
import { getActiveDaysIndexes } from "../../../helpers/timeFormat";
import { addDays } from "date-fns";
import { BookingContext } from "../../../context/BookingProvider";
import { useTranslation } from "react-i18next";
import { arSA } from "react-day-picker/locale";

const MonthlyPlan = ({
  spaceDetails,
  onBack,
  setCurrentStep,
  // initialBookingData,
  updateInitialBookingData,
  setShiftType,
  updateBookedShift,
  updateInstantBooking,
  handlePlanID,
}) => {
  const [bookingStartTime, setBookingStartTime] = useState(null);
  const [bookingEndTime, setBookingEndTime] = useState(null);
  const {
    MonthselectedDay,
    setMonthSelectedDay,
    selectedMonthlyOption,
    setSelectedMonthlyOption,
    shiftMonthID,
    setShiftMonthID,
    countMonths,
    setCountMonths,
  } = useContext(BookingContext);
  const { t, i18n } = useTranslation("bookings");
  const planDetails = spaceDetails.plans.find(
    (plan) => plan?.name?.key === "monthly"
  );
  const checkinDay = MonthselectedDay?.toLocaleDateString("en-CA");
  // console.log("checkinDay", checkinDay);
  const planId = planDetails.id;
  // console.log("planId", planId);
  handlePlanID(planId);

  function addMonthsToDate(dateString, months) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + months);

    date.setDate(date.getDate() - 1);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const checkoutDay = addMonthsToDate(checkinDay, countMonths);
  // console.log("checkoutDay", checkoutDay);

  function convertTime(timeString) {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(":");
    let hour = parseInt(hours);
    const period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${hour}:${minutes.padStart(2, "0")} ${period}`;
  }

  useEffect(() => {
    const selectedShift = planDetails.shifts.find(
      (shift) => shift?.name?.key === selectedMonthlyOption
    );

    if (selectedShift) {
      updateBookedShift(selectedShift);
      setShiftMonthID(selectedShift.id);
      setBookingStartTime(selectedShift.start_time);
      setBookingEndTime(selectedShift.end_time);
      setShiftType(selectedShift.name.label);
    }
  }, [selectedMonthlyOption]);

  const handleDailyShiftChange = (event) => {
    setSelectedMonthlyOption(event.target.value);
  };
  const activeIndexes = getActiveDaysIndexes(
    spaceDetails.workspace.working_days
  );

  // console.log("activeIndexes", activeIndexes);

  const isReviewButtonDisabled = () => {
    return (
      shiftMonthID === null ||
      shiftMonthID === undefined ||
      checkinDay === null ||
      checkinDay === undefined ||
      checkoutDay === null ||
      checkoutDay === undefined ||
      bookingStartTime === null ||
      bookingStartTime === undefined ||
      bookingEndTime === null ||
      bookingEndTime === undefined ||
      countMonths < 1
    );
  };

  const increment = (event) => {
    setCountMonths((countMonths) => countMonths + 1);
    event.preventDefault();
  };

  const decrement = (event) => {
    if (countMonths > 1) {
      setCountMonths((countMonths) => countMonths - 1);
    }
    event.preventDefault();
  };

  const startTime = `${checkinDay} ${bookingStartTime}`;
  // console.log("startDate", startTime);
  const endTime = `${checkoutDay} ${bookingEndTime}`;
  // console.log("endDate", endTime);
  // console.log("shiftMonthID", shiftMonthID);

  const selectedBookingData = {
    shift_id: shiftMonthID,
    start_at: startTime,
    end_at: endTime,
  };

  useEffect(() => {
    updateInitialBookingData(selectedBookingData);
  }, [shiftMonthID, checkinDay, checkoutDay, bookingStartTime, bookingEndTime]);

  const handleSubmit = () => {
    updateInstantBooking(planDetails?.instant_booking);
    setShiftType(`Month`);
    setCurrentStep(3);
  };

  // console.log(spaceDetails);

  return (
    <div>
      <div className="flex w-full gap-5 mt-5 lg:flex-row sm:flex-col">
        <div className="datePeckerWrapper flex sm:m-auto lg:m-0 lg:w-[400px] sm:w-[370px] h-[380px] p-1">
          <DayPicker
            mode="single"
            selected={MonthselectedDay}
            onSelect={setMonthSelectedDay}
            locale={i18n.language === "ar" ? arSA : undefined}
            className="w-full custom-day-picker"
            disabled={[
              { before: addDays(new Date(), 1) },
              {
                dayOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
                  (day) => !activeIndexes.includes(day)
                ),
              },
            ]}
          />
        </div>
        <div className="flex flex-col my-4">
          <p className="text-[#111827] font-[500] text-[16px] mb-2">
            {t("Number of Months")}
          </p>
          <div className="flex items-center gap-4">
            <button
              className="w-10 h-10 bg-[#252C34] rounded-full flex items-center justify-center"
              onClick={decrement}
            >
              <span
                className={`${
                  countMonths == 1 ? "text-[#6B7280]" : "text-[#009E1E] "
                } text-3xl leading-none`}
              >
                -
              </span>
            </button>
            <span className="text-2xl font-bold text-gray-800">
              {countMonths}
            </span>
            <button
              className="w-10 h-10 bg-[#252C34] rounded-full flex items-center justify-center"
              onClick={increment}
            >
              <span className="text-3xl text-[#009E1E] leading-none">+</span>
            </button>
          </div>
          <div className="w-full mt-4 daily-plan">
            <p className="text-[#000021] font-medium text-lg">
              {t("Please Select Shift")}
            </p>
            <div className="mt-5 checkbox-group">
              {planDetails?.shifts.map((shift, index) => {
                if (!shift?.is_active) return null;

                return (
                  <label
                    key={index}
                    className={`checkbox-container font-[400] text-[#6B7280] `}
                  >
                    <input
                      type="checkbox"
                      value={shift?.name?.key}
                      checked={selectedMonthlyOption === shift?.name?.key}
                      onChange={handleDailyShiftChange}
                      disabled={!shift?.is_active}
                    />
                    <span className="checkmark ltr:mr-2 rtl:ml-2"></span>
                    {shift?.name?.label}: {t("from")}{" "}
                    {convertTime(shift.start_time)} {` ${t("to")} `}
                    {convertTime(shift.end_time)}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-5">
        <button
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm"
          onClick={onBack}
        >
          {i18n.language === "en" ? (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11L2 6L7 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M12 11L7 6L12 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              className="mt-[5px]"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 11L11 6L6 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1 11L6 6L1 1"
                stroke="#111827"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
          {t("Back")}
        </button>
        <button
          className="bg-[#009E1E] px-4 py-2 text-white rounded-lg"
          onClick={handleSubmit}
          disabled={isReviewButtonDisabled()}
        >
          {t("Select Resources")}
        </button>
      </div>
    </div>
  );
};

export default MonthlyPlan;
MonthlyPlan.propTypes = null;
