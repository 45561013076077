import { useState, useEffect, useContext } from "react";
import {
  getPreviewBookingData,
  getPaymentOptions,
  postBookingData,
} from "../../data_featching/booking";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/LanguageProvider";
import StatusBar from "../ui/status-bar/StatusBar";
import { formatDateAndTime } from "../../helpers/timeFormat";
import { BookingContext } from "../../context/BookingProvider";
const PreviewBooking = ({
  spaceDetails,
  planType,
  onBack,
  setCurrentStep,
  initialBookingData,
  shiftType,
  bookedShift,
  isInstantBooking,
}) => {
  const { bookingData, setBookingData } = useContext(BookingContext);
  const { t, i18n } = useTranslation("bookings");
  const { language } = useContext(LanguageContext);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [promoCodeSucess, setPromoCodeSucess] = useState("");
  const token = localStorage.getItem("authToken");
  const [statusMessage, setStatusMessage] = useState({
    isActive: false,
    message: "",
    currentStatus: "",
  });

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      try {
        const data = await getPaymentOptions(spaceDetails.id);
        if (data?.data) {
          setPaymentOptions(data?.data);
          // set the key of value true to the selected payment
          const selectedKey = Object.keys(data?.data).find(
            (key) => data.data[key]
          );
          console.log("selectedKey", selectedKey);
          setSelectedPayment(selectedKey);
        }
      } catch (error) {
        console.error("Failed to fetch payment options", error);
      }
    };
    fetchPaymentOptions();
  }, [spaceDetails.id]);
  const { checkin, checkout } = formatDateAndTime(
    language,
    initialBookingData.start_at,
    initialBookingData.end_at
  );

  const handlePaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };
  /*reset Promo code error feild when enter new promocode or edit   */
  useEffect(() => {
    setPromoCodeError("");
    setPromoCodeSucess("");
  }, [promoCodeValue]);
  //handel promo code
  const handelPromoCode = () => {
    console.log("promoCodeValue", promoCodeValue);
    const fetchPreviewData = async () => {
      try {
        const res = await getPreviewBookingData(
          { ...initialBookingData, promo_code: promoCodeValue },
          token,
          language
        );
        if (res?.status == 200 || res?.status == 201) {
          setBookingData(res?.data?.data); // Store the response data in state
          setPromoCodeSucess(t("Promo_Applied"));
        }
      } catch (error) {
        console.log(error);
        setPromoCodeError(error.message);
      }
    };
    if (promoCodeValue) {
      setPromoCodeError(""); //reset promocode error feild before check the promo code validity
      setPromoCodeSucess("");
      fetchPreviewData();
    } else {
      console.log("the promocode is not available");
    }
  };
  const handleSubmit = async () => {
    setWaitingForResponse(!waitingForResponse);
    setStatusMessage({
      isActive: true,
      message: t("Booking is in progress"),
      currentStatus: 1,
    });
    const response = await postBookingData(
      promoCodeValue && !promoCodeError
        ? { ...initialBookingData, promo_code: promoCodeValue }
        : initialBookingData,
      selectedPayment,
      token
    );
    if (response?.status === 200 || response?.status === 201) {
      if (response.data.data.transaction) {
        window.location.href = response.data.data.transaction.payment_url;
      }

      setStatusMessage({
        isActive: true,
        message: t("Booking is successful"),
        currentStatus: 2,
      });
      setCurrentStep(5);
    } else if (response?.status === 429) {
      console.error("Failed to post booking data", response);
      setStatusMessage({
        isActive: true,
        message: t("Too many requests, please try again later"),
        currentStatus: 3,
      });
      setWaitingForResponse(!waitingForResponse);
    } else {
      console.error("Failed to post booking data", response);
      setTimeError(response?.response?.data?.message);
      setStatusMessage({
        isActive: true,
        message:
          response?.response?.data?.message || t("Failed to post booking data"),
        currentStatus: 3,
      });
      setWaitingForResponse(!waitingForResponse);
    }
  };
  return (
    <div>
      {statusMessage.isActive && (
        <StatusBar
          message={statusMessage.message}
          statusFlag={statusMessage.currentStatus}
          currentLanguage={language}
        />
      )}
      <div className="bookedSpace">
        <div
          key={spaceDetails?.id}
          className="flex items-start my-4 bg-white shadow-sm feature_workSpace_card rounded-2xl"
        >
          <div className="Workspace_img relative  flex items-center 2xl:w-[350px] lg:w-[300px] sm:w-full bg-white rounded-l-2xl shadow-sm">
            <img
              src={`${
                spaceDetails?.media?.length > 0
                  ? spaceDetails?.media[0]?.url
                  : "/workspace-img.png"
              }`}
              alt=""
              className="w-full h-full sm:rounded-t-2xl ltr:lg:rounded-l-2xl ltr:lg:rounded-tr-none rtl:lg:rounded-r-2xl rtl:lg:rounded-tl-none"
            />
          </div>
          <div className="relative flex flex-col justify-start w-full gap-4 p-4 WorkSpace_card_details">
            <div className="flex flex-col gap-1 WorkSpace_title_address">
              <div className="flex items-center gap-2 workSpace_title">
                <span className="text-[#111827] text-xl font-bold">
                  {spaceDetails?.name}
                </span>
              </div>
              <div className="workspac_adderes text-[#6b7280] text-sm">
                <span>
                  {spaceDetails?.workspace?.address?.building_number ||
                    spaceDetails?.workspace?.address?.street_name}
                  ,{spaceDetails?.workspace?.address?.district?.name}
                </span>
              </div>
            </div>
            <div className="hidden gap-3 py-2 Available_Workspace_facilities md:flex flex-nowrap">
              <ul className="flex flex-row flex-wrap gap-4 facillity_column_group">
                {spaceDetails.amenities.map((amenity) => {
                  return (
                    <li key={amenity.id}>
                      <div className="flex items-center gap-1 facility_title">
                        <div
                          className="icon"
                          dangerouslySetInnerHTML={{
                            __html: amenity.icon,
                          }}
                        />
                        <span className="text-sm truncate">{amenity.name}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="flex flex-row items-center justify-between workspace-cost-action">
              <div className="flex items-center gap-1 cost-details">
                <span className="text-[#252c34] text-4xl font-bold">
                  {bookingData?.price}
                </span>
                <span className="cost-currency text-[#252c34] text-base">
                  {bookingData?.currency?.label}
                </span>
                <span className="cost-per text-[#6b7280] text-sm">
                  /{t(planType)} {"  "} {`"${bookedShift?.name?.label}"`}
                </span>
              </div>
              {isInstantBooking && (
                <span className="px-3 py-1 bg-[#2563EB] text-white rounded-full flex items-center gap-2 ">
                  {t("instant booking")}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.779 6.63028C13.6988 6.44698 13.5176 6.32859 13.3175 6.32859H9.12947L11.1468 0.673294C11.2272 0.447733 11.1377 0.196639 10.9328 0.0727384C10.7277 -0.0512296 10.4639 -0.0137368 10.3015 0.162304L2.31217 8.82589C2.17651 8.97297 2.14076 9.18643 2.22112 9.36973C2.30135 9.55303 2.48256 9.67142 2.68266 9.67142H5.40532C5.68369 9.67142 5.90925 9.44579 5.90925 9.16749C5.90925 8.88918 5.68369 8.66355 5.40532 8.66355H3.83284L9.36705 2.66236L7.93991 6.66313C7.88495 6.81747 7.91088 6.98894 8.00535 7.12285C8.09976 7.25677 8.25598 7.33639 8.41986 7.33639H12.1673L6.63385 13.3369L8.06119 9.33681C8.11629 9.18247 8.09291 9.011 7.99843 8.87709C7.90396 8.74317 7.75043 8.66348 7.58655 8.66348H7.58158C7.34473 8.66348 7.14786 8.82689 7.09444 9.04708L4.85375 15.3265C4.77325 15.5521 4.86268 15.8032 5.06762 15.9271C5.14858 15.976 5.23875 15.9998 5.32818 15.9998C5.46519 15.9998 5.60064 15.944 5.69888 15.8375L13.6879 7.17399C13.8236 7.02697 13.8593 6.81357 13.779 6.63028Z"
                      fill="white"
                    />
                  </svg>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bookingDetails ">
        <div className="flex items-center gap-4 my-6 justify-items-start">
          <p className="text-lg font-medium ">{t("Space type:")}</p>
          <p className=" px-2 py-1 rounded-full bg-white border border-[#9CA3AF] font-[400] text-sm ">
            {spaceDetails.type.label}
          </p>
        </div>
        <div className="flex items-center gap-4 my-4 justify-items-start">
          <p className="text-lg font-medium ">{t("Plan")}:</p>
          <p className=" px-2 py-1 rounded-full bg-white border border-[#9CA3AF] font-[400] text-sm ">
            {bookingData?.booked_slots} {t(shiftType)}
          </p>
        </div>
        <div className="flex items-center gap-4 my-4 justify-items-start">
          <p className="text-lg font-medium ">{t("Resources")}:</p>
          <p className="px-2 py-1 text-2xl font-bold ">
            {bookingData?.resources_price}{" "}
            <span className="text-lg font-normal">
              {bookingData?.currency?.label}
            </span>
          </p>
        </div>
        <div className="flex w-full gap-6 my-4 checkDates lg:flex-row sm:flex-col">
          <div className="checkIn flex items-center justify-between gap-1 mt-4 w-[320px]">
            <span className="text-[16px] font-[500]">{t("Check In")}</span>
            <div className="flex items-center gap-2">
              <span className="text-[15px] font-[400] border rounded-[24px] p-2 border-[#22C55E] text-sm ">
                {checkin.formattedDate}
              </span>
              <span className="text-[15px] font-[400] border rounded-[24px] p-2 border-[#22C55E] text-sm ">
                {checkin.formattedTime}
              </span>
            </div>
          </div>
          <div className="checkOut flex items-center justify-between mt-4 w-[320px] ">
            <span className="text-[16px] font-[500]">{t("Check Out")}:</span>
            <div className="flex items-center gap-2">
              <span className="text-[15px] font-[400] border rounded-[24px] p-2 border-[#D94C43] text-sm ">
                {checkout.formattedDate}
              </span>
              <span className="text-[15px] font-[400] border rounded-[24px] p-2 border-[#D94C43] text-sm ">
                {checkout.formattedTime}
              </span>
            </div>
          </div>
        </div>
        <div className="my-5 promoCode">
          <p className="text-lg font-medium">{t("Promo code:")}</p>

          <div className=" relative flex flex-col gap-4 my-4 w-[320px]">
            <input
              dir={language == "en" ? "ltr" : "rtl"}
              type="text"
              placeholder={t("Enter promo code")}
              className="border outline-none border-[#9CA3AF] rounded-lg px-2 py-1 w-[320px] h-[40px] "
              value={promoCodeValue || ""}
              onChange={(e) => {
                e.preventDefault();
                setPromoCodeValue(e.target.value);
              }}
            />
            <button
              className={`${
                promoCodeValue ? "bg-[#009E1E]" : "bg-[#BCC2CD]"
              } text-white rounded-lg px-4 py-1 absolute ltr:right-0 rtl:left-0 h-[40px]`}
              onClick={(e) => {
                e.preventDefault();
                handelPromoCode();
              }}
            >
              {t("Apply")}
            </button>
          </div>
          {promoCodeError && (
            <p className="my-2 text-sm text-red-500">{promoCodeError}</p>
          )}
          {promoCodeSucess && (
            <p className="my-2 text-sm text-green-500">{promoCodeSucess}</p>
          )}
        </div>
        <p className="text-lg font-medium ">{t("Price Details")}</p>

        <div className="flex flex-col gap-4 my-5 px-2 text-[#6B7280]">
          <div className="flex items-center justify-between gap-4 dotted-line">
            <p className="text-lg font-normal">
              {t("Workspace (VAT included)")}
            </p>
            <span className="flex-grow mx-2 border-b border-black border-dotted"></span>

            <p className="font-normal text-md text-[#6B7280]">
              {bookingData?.booked_slots > 0 && (
                <>
                  {bookingData?.booked_slots}
                  <span>X</span>

                  {bookingData?.price}
                </>
              )}
            </p>
            <span className="flex-grow mx-2 border-b border-black border-dotted"></span>

            <p className="font-normal text-lg text-[#252C34]">
              {bookingData?.booked_slots > 0
                ? bookingData?.price * bookingData?.booked_slots
                : bookingData?.price}
              <span className="text-[#6B7280]">
                {" "}
                {bookingData?.currency?.label}
              </span>
            </p>
          </div>

          {/* resources cost */}
          <div className="flex items-center justify-between gap-4 dotted-line">
            <p className="text-lg font-normal">
              {t("Resources (VAT included)")}
            </p>
            <span className="flex-grow mx-2 border-b border-black border-dotted"></span>

            <p className="font-normal text-lg text-[#252C34]">
              {bookingData?.resources_price}{" "}
              <span className="text-[#6B7280]">
                {bookingData?.currency?.label}
              </span>
            </p>
          </div>

          {bookingData?.discount_amount > 0 && (
            <div className="flex items-center justify-between gap-4 dotted-line">
              <p className="text-lg font-normal">{t("Promocode")}</p>
              <p className="font-normal text-lg  text-[#252C34]">
                -{bookingData?.discount_amount}{" "}
                <span className="text-[#6B7280]">
                  {bookingData?.currency?.label}
                </span>
              </p>
            </div>
          )}

          {/* dotted break line */}
          <div className="flex items-center justify-between gap-4">
            <span className="flex-grow mx-2 border-b border-black border-dotted"></span>
          </div>

          {/* TAXES */}
          <div className="flex items-center justify-between gap-4 dotted-line">
            <p className="text-lg font-normal">{t("VAT(15%)")}</p>
            <span className="flex-grow mx-2 border-b border-black border-dotted"></span>

            <p className="font-normal text-lg  text-[#252C34]">
              {bookingData?.taxes_price}{" "}
              <span className="text-[#6B7280]">
                {bookingData?.currency?.label}
              </span>
            </p>
          </div>

          {/* total cost */}
          <div className="flex justify-between items-center gap-4 font-bold text-[#252C34]">
            <p className="text-lg font-normal">{t("Total (VAT included)")}</p>
            <span className="flex-grow mx-2 border-b border-black border-dotted"></span>

            <p className="text-lg font-normal">
              {bookingData?.final_price}
              {"  "}
              <span className="text-[#6B7280]">
                {bookingData?.currency?.label}
              </span>
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4 my-5">
          <p className="text-lg font-medium ">{t("Payment:")}</p>
          <p className=" text-[#22C55E] font-medium text-lg ">
            {" "}
            ({bookingData?.final_price}{" "}
            <span>{bookingData?.currency?.label}</span> ){" "}
          </p>
        </div>
        {/* <div className="flex gap-4 paymentOptions sm:flex-col lg:flex-row ">
          <label className="flex items-center gap-2 space-x-2">
            {paymentOptions?.host && (
              <span className=" text-[#6B7280] font-normal ">
                Pay on Arrival{" "}
              </span>
            )}
            {paymentOptions?.card && (
              <span className=" text-[#6B7280] font-normal ">
                Pay with Card{" "}
              </span>
            )}
            {paymentOptions?.tabby && (
              <span className=" text-[#6B7280] font-normal ">
                Pay with Tabby{" "}
              </span>
            )}
          </label>
        </div> */}

        <div className="flex gap-4 paymentOptions sm:flex-col lg:flex-row ">
          <label
            className={`flex items-center gap-2 ${
              !paymentOptions?.host ? "line-through text-black" : ""
            } `}
          >
            <input
              type="radio"
              value="host"
              checked={selectedPayment === "host"}
              onChange={handlePaymentChange}
              className="text-green-500 form-radio"
              disabled={!paymentOptions?.host}
            />
            <span className=" text-[#6B7280] font-normal">
              {t("Pay on Arrival")}
            </span>
          </label>

          <label
            className={`flex items-center gap-2 ${
              !paymentOptions?.card ? "line-through text-black" : ""
            } `}
          >
            <input
              type="radio"
              value="card"
              checked={selectedPayment === "card"}
              onChange={handlePaymentChange}
              className="text-green-500 form-radio"
              disabled={!paymentOptions?.card}
            />
            <span className=" text-[#6B7280] font-normal flex flex-row items-center gap-2">
              {t("Pay with Card")}
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.4438 17.1594H1.55624C0.698422 17.1594 0 16.4609 0 15.5984V2.4078C0 1.54998 0.698422 0.851562 1.55624 0.851562H22.439C23.3015 0.851562 24 1.54998 24 2.4078V15.5984C24 16.4609 23.3016 17.1594 22.4438 17.1594Z"
                  fill="#505278"
                />
                <path
                  d="M22.4438 0.851562H12V17.1594H22.4437C23.3062 17.1594 24 16.4609 24 15.6031V2.4078C24 1.54998 23.3016 0.851562 22.4438 0.851562Z"
                  fill="#424566"
                />
                <path
                  d="M12.5203 0.848433C12.3609 5.66249 8.39531 9.52499 3.54375 9.52499C2.28748 9.52499 1.0875 9.26716 0 8.79374V6.69844C1.04531 7.30311 2.2547 7.64532 3.54375 7.64532C7.35937 7.64532 10.4812 4.62186 10.6406 0.84375H12.5203V0.848433H12.5203Z"
                  fill="#575982"
                />
                <path
                  d="M12 0.851562V3.575C12.3047 2.71718 12.4875 1.80313 12.5203 0.851562H12Z"
                  fill="#505278"
                />
                <path
                  d="M24 10.3798V12.2829C22.875 12.0673 21.7781 11.9735 20.6906 12.0814C18.7266 12.2782 16.9031 13.197 14.9484 14.9736C14.6344 15.2595 14.3156 15.5548 14.0109 15.8408C13.5422 16.2767 13.0734 16.7173 12.5812 17.1486H9.5437C10.6687 16.3986 11.6765 15.4564 12.7312 14.472C13.0453 14.1814 13.364 13.8767 13.6874 13.5861C15.9562 11.5283 18.1218 10.4548 20.5031 10.2158C21.6797 10.0986 22.8703 10.1923 24 10.3798Z"
                  fill="#505278"
                />
                <path
                  d="M9.5437 17.15H11.9999V15.1484C11.1984 15.8844 10.4062 16.5735 9.5437 17.15Z"
                  fill="#575982"
                />
                <path
                  d="M5.02501 8.78751H2.72344C2.37186 8.78751 2.08594 8.50158 2.08594 8.15001V6.62187C2.08594 6.2703 2.37186 5.98438 2.72344 5.98438H5.02501C5.37658 5.98438 5.66251 6.2703 5.66251 6.62187V8.15001C5.65782 8.50627 5.37654 8.78751 5.02501 8.78751Z"
                  fill="#424566"
                />
                <path
                  d="M5.02498 8.74687H2.71872C2.39061 8.74687 2.12341 8.47968 2.12341 8.15157V6.61874C2.12341 6.29063 2.39061 6.02344 2.71872 6.02344H5.02498C5.35309 6.02344 5.62029 6.29063 5.62029 6.61874V8.15157C5.62029 8.47968 5.35309 8.74687 5.02498 8.74687Z"
                  fill="#FEBD55"
                />
                <path
                  d="M5.62029 7.4375V7.34375H4.40153V6.93126L4.7156 6.61719H5.62029C5.62029 6.58437 5.6156 6.55158 5.61092 6.52344H4.69218C4.67813 6.52344 4.66872 6.52813 4.65935 6.53749L4.33124 6.86561H3.9187V6.03125H3.82496V6.86561H3.41714L3.08903 6.53749C3.07966 6.52813 3.06557 6.52344 3.0562 6.52344H2.13278C2.1281 6.55158 2.12341 6.58437 2.12341 6.61719H3.03747L3.34685 6.92657V7.34375H2.12341V7.4375H3.34685V7.85468L3.03747 8.16406H2.12341C2.12341 8.19688 2.1281 8.22967 2.13278 8.25781H3.0562C3.07025 8.25781 3.07966 8.25313 3.08903 8.24376L3.41714 7.91564H3.82496V8.75H3.9187V7.91564H4.3312L4.65932 8.24376C4.66868 8.25313 4.67805 8.25781 4.69214 8.25781H5.61088C5.61556 8.22967 5.62025 8.19688 5.62025 8.16406H4.71556L4.40149 7.85V7.4375H5.62029ZM4.30778 7.82186H3.4406V6.95467H4.30778V7.82186Z"
                  fill="#B5613C"
                />
                <path
                  d="M21.4875 8.71999C21.4641 8.71999 21.4453 8.71531 21.4266 8.70594C21.3563 8.67312 21.3282 8.58874 21.3657 8.51845C21.7266 7.80125 21.7266 6.98094 21.3657 6.26375C21.3329 6.19342 21.361 6.10908 21.4266 6.07625C21.4969 6.04343 21.5813 6.07157 21.6141 6.13718C22.0172 6.93407 22.0172 7.84812 21.6141 8.64498C21.586 8.69189 21.5391 8.71999 21.4875 8.71999ZM21.0047 8.4528C21.3844 7.7778 21.3844 6.99968 21.0047 6.32467C20.9672 6.25906 20.8828 6.23092 20.8125 6.27311C20.7469 6.31062 20.7188 6.395 20.761 6.46529C21.0938 7.05592 21.0938 7.73092 20.761 8.31686C20.7235 8.38247 20.7469 8.47153 20.8125 8.50904C20.836 8.52309 20.8594 8.52777 20.8829 8.52777C20.9297 8.52313 20.9766 8.49499 21.0047 8.4528ZM20.4047 8.26062C20.775 7.72155 20.775 7.05123 20.4047 6.50748C20.3625 6.44188 20.2735 6.42779 20.2079 6.46998C20.1423 6.51217 20.1282 6.60123 20.1704 6.66684C20.4704 7.10747 20.4704 7.66059 20.1704 8.10123C20.1282 8.16684 20.1422 8.25122 20.2079 8.29809C20.2313 8.31682 20.2594 8.32151 20.2876 8.32151C20.3344 8.32627 20.3766 8.30281 20.4047 8.26062ZM19.7719 8.08249C19.9782 7.895 20.0907 7.64654 20.0907 7.38876C20.0907 7.13097 19.9782 6.88251 19.7719 6.69502C19.7157 6.64346 19.6266 6.64346 19.575 6.70438C19.5235 6.76062 19.5235 6.84969 19.5844 6.90125C19.7297 7.03718 19.8094 7.21063 19.8094 7.38876C19.8094 7.56688 19.7297 7.74033 19.5844 7.87627C19.5282 7.92782 19.5235 8.01689 19.575 8.07313C19.6032 8.10127 19.6407 8.12 19.6782 8.12C19.7109 8.12 19.7438 8.10595 19.7719 8.08249Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M11.8078 14.7969H2.26403C2.18434 14.7969 2.12341 14.7359 2.12341 14.6562C2.12341 14.5765 2.18434 14.5156 2.26403 14.5156H11.8078C11.8875 14.5156 11.9484 14.5765 11.9484 14.6562C11.9484 14.7359 11.8828 14.7969 11.8078 14.7969Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M21.7735 14.7969H19.9641C19.8844 14.7969 19.8235 14.7359 19.8235 14.6562C19.8235 14.5765 19.8844 14.5156 19.9641 14.5156H21.7735C21.8532 14.5156 21.9141 14.5765 21.9141 14.6562C21.9141 14.7359 21.8485 14.7969 21.7735 14.7969ZM19.1954 14.6562C19.1954 14.5765 19.1344 14.5156 19.0547 14.5156H17.2454C17.1657 14.5156 17.1047 14.5765 17.1047 14.6562C17.1047 14.7359 17.1657 14.7969 17.2454 14.7969H19.0547C19.1344 14.7969 19.1954 14.7313 19.1954 14.6562Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M2.26403 4.0453C2.18434 4.0453 2.12341 3.98437 2.12341 3.90468V3.35156C2.12341 3.27186 2.18434 3.21094 2.26403 3.21094C2.34373 3.21094 2.40466 3.27186 2.40466 3.35156V3.89999C2.40466 3.97969 2.34373 4.0453 2.26403 4.0453ZM3.16872 3.90468V3.35156C3.16872 3.27186 3.1078 3.21094 3.0281 3.21094C2.94841 3.21094 2.88748 3.27186 2.88748 3.35156V3.89999C2.88748 3.97969 2.94841 4.04061 3.0281 4.04061C3.10776 4.0453 3.16872 3.97969 3.16872 3.90468ZM3.93748 3.90468V3.35156C3.93748 3.27186 3.87655 3.21094 3.79686 3.21094C3.71716 3.21094 3.65623 3.27186 3.65623 3.35156V3.89999C3.65623 3.97969 3.71716 4.04061 3.79686 4.04061C3.87183 4.0453 3.93748 3.97969 3.93748 3.90468ZM4.70155 3.90468V3.35156C4.70155 3.27186 4.64062 3.21094 4.56092 3.21094C4.48123 3.21094 4.4203 3.27186 4.4203 3.35156V3.89999C4.4203 3.97969 4.48123 4.04061 4.56092 4.04061C4.64058 4.0453 4.70155 3.97969 4.70155 3.90468Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M2.55462 12.4094H2.53116C2.29679 12.4094 2.10461 12.2172 2.10461 11.9828V11.3172C2.10461 11.0828 2.29679 10.8906 2.53116 10.8906H2.55462C2.78899 10.8906 2.98116 11.0828 2.98116 11.3172V11.9828C2.9812 12.2171 2.79371 12.4094 2.55462 12.4094ZM2.5312 11.1718C2.4515 11.1718 2.3859 11.2374 2.3859 11.3171V11.9828C2.3859 12.0625 2.4515 12.1281 2.5312 12.1281H2.55466C2.63435 12.1281 2.69996 12.0625 2.69996 11.9828V11.3171C2.69996 11.2374 2.63435 11.1718 2.55466 11.1718H2.5312Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M3.69366 12.4094H3.6702C3.43583 12.4094 3.24365 12.2172 3.24365 11.9828V11.3172C3.24365 11.0828 3.43583 10.8906 3.6702 10.8906H3.69366C3.92802 10.8906 4.1202 11.0828 4.1202 11.3172V11.9828C4.12024 12.2171 3.93275 12.4094 3.69366 12.4094ZM3.67024 11.1718C3.59054 11.1718 3.52493 11.2374 3.52493 11.3171V11.9828C3.52493 12.0625 3.59054 12.1281 3.67024 12.1281H3.69369C3.77339 12.1281 3.839 12.0625 3.839 11.9828V11.3171C3.839 11.2374 3.77339 11.1718 3.69369 11.1718H3.67024Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M4.83282 12.4094H4.80936C4.57499 12.4094 4.38281 12.2172 4.38281 11.9828V11.3172C4.38281 11.0828 4.57499 10.8906 4.80936 10.8906H4.83282C5.06718 10.8906 5.25936 11.0828 5.25936 11.3172V11.9828C5.25936 12.2171 5.06718 12.4094 4.83282 12.4094ZM4.80936 11.1718C4.72966 11.1718 4.66405 11.2374 4.66405 11.3171V11.9828C4.66405 12.0625 4.72966 12.1281 4.80936 12.1281H4.83282C4.91251 12.1281 4.97812 12.0625 4.97812 11.9828V11.3171C4.97812 11.2374 4.91251 11.1718 4.83282 11.1718H4.80936Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M5.97185 12.4094H5.9484C5.71403 12.4094 5.52185 12.2172 5.52185 11.9828V11.3172C5.52185 11.0828 5.71403 10.8906 5.9484 10.8906H5.97185C6.20622 10.8906 6.3984 11.0828 6.3984 11.3172V11.9828C6.3984 12.2171 6.20622 12.4094 5.97185 12.4094ZM5.9484 11.1718C5.8687 11.1718 5.80309 11.2374 5.80309 11.3171V11.9828C5.80309 12.0625 5.8687 12.1281 5.9484 12.1281H5.97185C6.05155 12.1281 6.11716 12.0625 6.11716 11.9828V11.3171C6.11716 11.2374 6.05155 11.1718 5.97185 11.1718H5.9484Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M7.72027 12.4094H7.69681C7.46244 12.4094 7.27026 12.2172 7.27026 11.9828V11.3172C7.27026 11.0828 7.46244 10.8906 7.69681 10.8906H7.72027C7.95463 10.8906 8.14681 11.0828 8.14681 11.3172V11.9828C8.14685 12.2171 7.95463 12.4094 7.72027 12.4094ZM7.69685 11.1718C7.61715 11.1718 7.55155 11.2374 7.55155 11.3171V11.9828C7.55155 12.0625 7.61715 12.1281 7.69685 12.1281H7.72031C7.8 12.1281 7.86561 12.0625 7.86561 11.9828V11.3171C7.86561 11.2374 7.8 11.1718 7.72031 11.1718H7.69685Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M8.8593 12.4094H8.83585C8.60148 12.4094 8.4093 12.2172 8.4093 11.9828V11.3172C8.4093 11.0828 8.60148 10.8906 8.83585 10.8906H8.8593C9.09367 10.8906 9.28585 11.0828 9.28585 11.3172V11.9828C9.28589 12.2171 9.09367 12.4094 8.8593 12.4094ZM8.83589 11.1718C8.75619 11.1718 8.69058 11.2374 8.69058 11.3171V11.9828C8.69058 12.0625 8.75619 12.1281 8.83589 12.1281H8.85934C8.93904 12.1281 9.00465 12.0625 9.00465 11.9828V11.3171C9.00465 11.2374 8.93904 11.1718 8.85934 11.1718H8.83589Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M9.99834 12.4094H9.97489C9.74052 12.4094 9.54834 12.2172 9.54834 11.9828V11.3172C9.54834 11.0828 9.74052 10.8906 9.97489 10.8906H9.99834C10.2327 10.8906 10.4249 11.0828 10.4249 11.3172V11.9828C10.4249 12.2171 10.2328 12.4094 9.99834 12.4094ZM9.97493 11.1718C9.89523 11.1718 9.82962 11.2374 9.82962 11.3171V11.9828C9.82962 12.0625 9.89523 12.1281 9.97493 12.1281H9.99838C10.0781 12.1281 10.1437 12.0625 10.1437 11.9828V11.3171C10.1437 11.2374 10.0781 11.1718 9.99838 11.1718H9.97493Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M11.1374 12.4094H11.1139C10.8796 12.4094 10.6874 12.2172 10.6874 11.9828V11.3172C10.6874 11.0828 10.8796 10.8906 11.1139 10.8906H11.1374C11.3718 10.8906 11.5639 11.0828 11.5639 11.3172V11.9828C11.564 12.2171 11.3718 12.4094 11.1374 12.4094ZM11.114 11.1718C11.0343 11.1718 10.9687 11.2374 10.9687 11.3171V11.9828C10.9687 12.0625 11.0343 12.1281 11.114 12.1281H11.1374C11.2171 12.1281 11.2827 12.0625 11.2827 11.9828V11.3171C11.2827 11.2374 11.2171 11.1718 11.1374 11.1718H11.114Z"
                  fill="#E6EAFF"
                />
                <path
                  d="M12.8859 12.4094H12.8625C12.6281 12.4094 12.4359 12.2172 12.4359 11.9828V11.3172C12.4359 11.0828 12.6281 10.8906 12.8625 10.8906H12.8859C13.1203 10.8906 13.3125 11.0828 13.3125 11.3172V11.9828C13.3125 12.2171 13.1203 12.4094 12.8859 12.4094ZM12.8625 11.1718C12.7828 11.1718 12.7172 11.2374 12.7172 11.3171V11.9828C12.7172 12.0625 12.7828 12.1281 12.8625 12.1281H12.8859C12.9656 12.1281 13.0312 12.0625 13.0312 11.9828V11.3171C13.0312 11.2374 12.9656 11.1718 12.8859 11.1718H12.8625Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M14.025 12.4094H14.0016C13.7673 12.4094 13.5751 12.2172 13.5751 11.9828V11.3172C13.5751 11.0828 13.7673 10.8906 14.0016 10.8906H14.025C14.2594 10.8906 14.4516 11.0828 14.4516 11.3172V11.9828C14.4516 12.2171 14.2594 12.4094 14.025 12.4094ZM14.0016 11.1718C13.9219 11.1718 13.8563 11.2374 13.8563 11.3171V11.9828C13.8563 12.0625 13.9219 12.1281 14.0016 12.1281H14.025C14.1047 12.1281 14.1703 12.0625 14.1703 11.9828V11.3171C14.1703 11.2374 14.1047 11.1718 14.025 11.1718H14.0016Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M15.164 12.4094H15.1405C14.9062 12.4094 14.714 12.2172 14.714 11.9828V11.3172C14.714 11.0828 14.9062 10.8906 15.1405 10.8906H15.164C15.3984 10.8906 15.5905 11.0828 15.5905 11.3172V11.9828C15.5906 12.2171 15.3984 12.4094 15.164 12.4094ZM15.1406 11.1718C15.0609 11.1718 14.9953 11.2374 14.9953 11.3171V11.9828C14.9953 12.0625 15.0609 12.1281 15.1406 12.1281H15.164C15.2437 12.1281 15.3093 12.0625 15.3093 11.9828V11.3171C15.3093 11.2374 15.2437 11.1718 15.164 11.1718H15.1406Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M16.3032 12.4094H16.2797C16.0453 12.4094 15.8531 12.2172 15.8531 11.9828V11.3172C15.8531 11.0828 16.0453 10.8906 16.2797 10.8906H16.3032C16.5375 10.8906 16.7297 11.0828 16.7297 11.3172V11.9828C16.7297 12.2171 16.5375 12.4094 16.3032 12.4094ZM16.2797 11.1718C16.2 11.1718 16.1344 11.2374 16.1344 11.3171V11.9828C16.1344 12.0625 16.2 12.1281 16.2797 12.1281H16.3032C16.3829 12.1281 16.4485 12.0625 16.4485 11.9828V11.3171C16.4485 11.2374 16.3829 11.1718 16.3032 11.1718H16.2797Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M18.0516 12.4094H18.0281C17.7937 12.4094 17.6016 12.2172 17.6016 11.9828V11.3172C17.6016 11.0828 17.7937 10.8906 18.0281 10.8906H18.0516C18.2859 10.8906 18.4781 11.0828 18.4781 11.3172V11.9828C18.4781 12.2171 18.2859 12.4094 18.0516 12.4094ZM18.0281 11.1718C17.9484 11.1718 17.8828 11.2374 17.8828 11.3171V11.9828C17.8828 12.0625 17.9484 12.1281 18.0281 12.1281H18.0516C18.1313 12.1281 18.1969 12.0625 18.1969 11.9828V11.3171C18.1969 11.2374 18.1313 11.1718 18.0516 11.1718H18.0281Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M19.1906 12.4094H19.1671C18.9328 12.4094 18.7406 12.2172 18.7406 11.9828V11.3172C18.7406 11.0828 18.9328 10.8906 19.1671 10.8906H19.1906C19.4249 10.8906 19.6171 11.0828 19.6171 11.3172V11.9828C19.6171 12.2171 19.4249 12.4094 19.1906 12.4094ZM19.1671 11.1718C19.0874 11.1718 19.0218 11.2374 19.0218 11.3171V11.9828C19.0218 12.0625 19.0874 12.1281 19.1671 12.1281H19.1905C19.2702 12.1281 19.3358 12.0625 19.3358 11.9828V11.3171C19.3358 11.2374 19.2702 11.1718 19.1905 11.1718H19.1671Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M20.3296 12.4094H20.3062C20.0718 12.4094 19.8796 12.2172 19.8796 11.9828V11.3172C19.8796 11.0828 20.0718 10.8906 20.3062 10.8906H20.3296C20.564 10.8906 20.7562 11.0828 20.7562 11.3172V11.9828C20.7562 12.2171 20.564 12.4094 20.3296 12.4094ZM20.3062 11.1718C20.2265 11.1718 20.1609 11.2374 20.1609 11.3171V11.9828C20.1609 12.0625 20.2265 12.1281 20.3062 12.1281H20.3297C20.4094 12.1281 20.475 12.0625 20.475 11.9828V11.3171C20.475 11.2374 20.4094 11.1718 20.3297 11.1718H20.3062Z"
                  fill="#CAD4FF"
                />
                <path
                  d="M21.4688 12.4094H21.4453C21.211 12.4094 21.0188 12.2172 21.0188 11.9828V11.3172C21.0188 11.0828 21.211 10.8906 21.4453 10.8906H21.4688C21.7032 10.8906 21.8953 11.0828 21.8953 11.3172V11.9828C21.8954 12.2171 21.7032 12.4094 21.4688 12.4094ZM21.4454 11.1718C21.3657 11.1718 21.3001 11.2374 21.3001 11.3171V11.9828C21.3001 12.0625 21.3657 12.1281 21.4454 12.1281H21.4688C21.5485 12.1281 21.6141 12.0625 21.6141 11.9828V11.3171C21.6141 11.2374 21.5485 11.1718 21.4688 11.1718H21.4454Z"
                  fill="#CAD4FF"
                />
              </svg>
            </span>
          </label>

          <label
            className={`flex items-center gap-2 ${
              !paymentOptions?.tabby ? "line-through text-black" : ""
            } `}
          >
            <input
              type="radio"
              value="tabby"
              checked={selectedPayment === "tabby"}
              onChange={handlePaymentChange}
              className="text-green-500 form-radio"
              disabled={!paymentOptions?.tabby}
            />
            <span className=" text-[#6B7280] font-normal flex flex-row items-center gap-2">
              {t("Pay with Tabby")}
              <img src="/tabby.png" alt="tabby" className="w-5 h-5" />
            </span>
          </label>
        </div>
        {timeError && (
          <p className="text-base font-medium text-red-400 ltr:text-right rtl:text-left">
            {timeError}
          </p>
        )}
        <div className="flex justify-end gap-4 mt-5">
          <button
            className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm "
            onClick={onBack}
          >
            {i18n.language === "en" ? (
              <svg
                className="mt-[5px]"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 11L2 6L7 1"
                  stroke="#111827"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M12 11L7 6L12 1"
                  stroke="#111827"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            ) : (
              <svg
                className="mt-[5px]"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 11L11 6L6 1"
                  stroke="#111827"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M1 11L6 6L1 1"
                  stroke="#111827"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            )}
            {t("Back")}
          </button>
          <button
            className=" bg-[#009E1E] px-4 py-2 text-white rounded-lg"
            onClick={handleSubmit}
            disabled={!selectedPayment || waitingForResponse}
          >
            {isInstantBooking ? t("Book Now") : t("Request Booking")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default PreviewBooking;
PreviewBooking.propTypes = null;
