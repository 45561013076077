import baseURL from "../../axios";
let isRequestInProgress = false;
export const postInitialBookingData = async (
  initialBookingData,
  token,
  language
) => {
  try {
    const response = await baseURL.post(
      `/api/v1/application/bookings/preview`,
      initialBookingData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": language,
        },
      }
    );
    // console.log("response", response);
    return response;
  } catch (error) {
    console.error("Error fetching initial booking data", error);
    if (error?.response?.status !== 200) {
      throw new Error(error?.response?.data?.message);
    }
  }
};
//{{base_url}}/api/v1/application/spaces/:id/bookings?year=2024&month=10 ,check available bookings
export const getExcluedBookings = async (id, year, month) => {
  try {
    const response = await baseURL.get(
      `${
        import.meta.env.VITE_BASE_URL
      }/api/v1/application/spaces/${id}/bookings`,
      {
        params: {
          year: Number(year),
          month: Number(month),
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching available bookings", error);
    return error;
  }
};

export const getAvailableResources = async (planID) => {
  try {
    const response = await baseURL.get(
      `/api/v1/application/available-resources`,
      {
        params: {
          plan_id: planID,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching available resources", error);
    return error.response;
  }
};

export const getPreviewBookingData = async (
  initialBookingData,
  token,
  lang
) => {
  try {
    const response = await baseURL.post(
      `/api/v1/application/bookings/preview`,
      initialBookingData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang,
        },
      }
    );
    console.log("response", response);
    return response;
  } catch (error) {
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
    }
  }
};

export const getPaymentOptions = async (id) => {
  try {
    const response = await baseURL.get(
      `${
        import.meta.env.VITE_BASE_URL
      }/api/v1/application/spaces/${id}/payment-options`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching payment options", error);
  }
};

export const postBookingData = async (
  initialBookingData,
  paymentOption,
  token
) => {
  try {
    const response = await baseURL.post(
      `/api/v1/application/bookings`,

      {
        ...initialBookingData,
        payment_option: paymentOption,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("response", response);
    return response;
  } catch (error) {
    console.error("Error fetching initial booking data", error);
    return error;
  }
};

export const getSpaceBookings = async (id) => {
  try {
    const response = await baseURL.get(
      `${
        import.meta.env.VITE_BASE_URL
      }/api/v1/application/spaces/:${id}/bookings`
    );
    return response;
  } catch (error) {
    console.error("Error fetching space Bookings:", error);
    return error.response;
  }
};

export const getBookingList = async (token, page = 1, lang = "en") => {
  try {
    const response = await baseURL.get(`/api/v1/application/bookings`, {
      params: {
        page: page,
        per_page: 10,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": lang,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching booking list", error);
    return error.response;
  }
};

export const getBookingDetails = async (bookingId, token, lang = "en") => {
  try {
    const response = await baseURL.get(
      `${
        import.meta.env.VITE_BASE_URL
      }/api/v1/application/bookings/${bookingId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching booking", error);
    return error;
  }
};

export const cancelBooking = async (bookingId, token) => {
  try {
    const response = await baseURL.patch(
      `${
        import.meta.env.VITE_BASE_URL
      }/api/v1/application/bookings/${bookingId}/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error cancelling booking", error);
    return error;
  }
};

export async function getPaymentCheckout(transactionId, currentLanguage) {
  if (isRequestInProgress) {
    console.log("Request already in progress. Skipping this request.");
    return;
  }

  isRequestInProgress = true;

  const url = `${
    import.meta.env.VITE_BASE_URL
  }/api/v1/application/transaction/${transactionId}/checkout`;

  try {
    const result = await baseURL.post(url, {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Accept-Language": currentLanguage,
      },
    });
    isRequestInProgress = false;
    return result;
  } catch (error) {
    isRequestInProgress = false;

    /*return repnse when post validation faild */
    console.log("error.response.data", error.response);
    if (error.response.status !== 200) {
      throw new Error(error.response.data.message);
    }
  }
}
