import "./LoginNavBar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import FixedUpperNav from "../upper_navbar/FixedUpperNav";
import cn from "../../../helpers/utils";
import Notification from "../../notification/Notification";
import { loginUserInfoRequest } from "../../../data_featching/login";
import { getUnReadedMessage } from "../../../data_featching/notification";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../../context/LanguageProvider";
import { AuthModal } from "../Models";
import CitySearch from "../search/CitySearch";
import AuthContext from "../../../context/AuthProvider";
import Logout from "../../profile/Logout";
// eslint-disable-next-line react/prop-types

function LoginNavBar({
  loginNav,
  showBurgerLoginMenu,
  setShowBurgerLoginMenu,
  disabled,
}) {
  const { t } = useTranslation("explore");
  const { language, changeLanguage } = useContext(LanguageContext);
  // const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const { userInfo, setUserInfo } = useContext(AuthContext);
  const suppliersLink = import.meta.env.VITE_SUPPLIERS_URL;
  const hostsLink = import.meta.env.VITE_HOST_URL;
  // const [showBurgerLoginMenu, setShowBurgerLoginMenu] = useState(false);
  // const [userInfo, setUserInfo] = useState(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false); //Notification
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false); //Notification
  const [NoOfNotification, setNoOfNotification] = useState(null); //Notification
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const token = localStorage.getItem("authToken");
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef();
  // const apiBaseUrl = import.meta.env.VITE_BASE_URL;
  const navigate = useNavigate();
  const [loadingUser, setLoadingUser] = useState(true);
  const navItems = [
    {
      name: t("explore"),
      path: "/explore",
    },
    {
      name: t("bookings"),
      path: "/bookings",
    },
    { name: t("suppliers"), path: "/suppliers" },
    { name: t("community"), path: "/community" },
  ];
  // const logOut = async () => {
  //   try {
  //     const response = await fetch(`${apiBaseUrl}/api/v1/auth/logout`, {
  //       method: "DELETE",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Logout failed");
  //     }
  //     localStorage.removeItem("authToken");
  //     setUserInfo(null);
  //     navigate("/");
  //   } catch (error) {
  //     console.error("Error during logout:", error);
  //   }
  // };
  useEffect(() => {
    if (isHovered) {
      timeoutRef.current = setTimeout(() => {
        setIsHovered(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isHovered]);

  useEffect(() => {
    if (userInfo) return;
    const getUserInfo = async () => {
      if (token) {
        const result = await loginUserInfoRequest(token);
        if (result?.status === 200 || result?.status === 201) {
          setUserInfo(result.data?.data);
        }
      }
    };
    setLoadingUser(true);
    getUserInfo();
    setTimeout(() => {
      setLoadingUser(false);
    }, 3000);
    console.log("userInfo", userInfo);
  }, [token]);

  const unReadeNotificationCount = async () => {
    const result = await getUnReadedMessage(token);
    if (result?.status == 200 || result?.status == 201) {
      console.log(result?.data);
      setNoOfNotification(result?.data?.data[0]?.count);
    }
  };
  useEffect(() => {
    unReadeNotificationCount();
  }, []);
  // make show burger menu false when window width is greater than 1280
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1280) {
        setShowBurgerLoginMenu(false);
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth > 1280) {
          setShowBurgerLoginMenu(false);
        }
      });
    };
  }, [window.innerWidth]);

  console.log(location.pathname);
  return (
    <div className="relative z-10 fixed-navbar">
      {showModal && (
        <AuthModal isOpen={showModal} onClose={() => setShowModal(false)} />
      )}
      {showNotificationModal && (
        <Notification
          onClose={() => setShowNotificationModal(false)}
          isClosing={isClosing}
          setIsClosing={setIsClosing}
        />
      )}
      {showLogoutModal && <Logout setShowLogoutModal={setShowLogoutModal} />}
      {showBurgerLoginMenu && (
        <div className="absolute pt-4 pb-24 md:pt-8 md:pb-0 menu_container_Login xl:hidden">
          <div className="hidden w-full userInfo">
            {token && userInfo && (
              <div className="relative z-50 flex items-center justify-between gap-2">
                {isHovered && (
                  <div
                    ref={timeoutRef}
                    className="w-[12.5rem] cursor-pointer drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)] bg-[#252C34] px-[1.563rem] absolute ltr:top-14 ltr:left-24 rtl:top-14 rtl:right-24 py-[0.625rem]"
                  >
                    <div
                      onClick={() => {
                        navigate("/profile");
                      }}
                      className="flex items-center gap-2"
                    >
                      <img src="/profile.svg" alt="" />
                      <p className="text-base font-normal text-white">
                        {t("profile")}
                      </p>
                    </div>

                    <div className="h-[1px] bg-[#89D98B] my-[0.625rem]"></div>
                    <Link to={"/favorites"} className="flex items-center gap-2">
                      <img src="/favorite.svg" alt="" />
                      <p className="text-base font-normal text-white">
                        {t("favorites")}
                      </p>
                    </Link>
                    <div className="h-[1px] bg-[#89D98B]  my-[0.625rem] "></div>
                    <div
                      onClick={() => setShowLogoutModal(true)}
                      className="flex items-center gap-2"
                    >
                      <img src="/signout.svg" alt="" className="mt-[4px]" />
                      <p className="text-base font-normal text-white">
                        {t("logout")}
                      </p>
                    </div>
                  </div>
                )}
                <div className="flex gap-2 cursor-pointer">
                  <Link to={"/profile"}>
                    <span
                      onMouseOver={() => setIsHovered(true)}
                      className="text-lg font-medium text-black"
                    >
                      {userInfo?.first_name}
                    </span>
                  </Link>
                  <Link to={"/profile"}>
                    <img
                      src={
                        userInfo?.profile_image?.url ||
                        "/profile-placeholder2.jpg"
                      }
                      alt="User"
                      className="w-10 h-10 rounded-full"
                      onMouseOver={() => setIsHovered(true)}
                    />
                  </Link>
                </div>
                <div className="flex gap-1">
                  <button
                    onClick={() => setShowNotificationModal((prev) => !prev)}
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="19.5"
                        fill="white"
                        stroke="#E5E7EB"
                      />
                      <path
                        d="M28.868 25.0318L27.6468 23.275C27.4801 23.0362 27.3904 22.7521 27.3897 22.4609V17.4189C27.3897 15.5249 26.6373 13.7084 25.298 12.3691C23.9587 11.0298 22.1422 10.2773 20.2481 10.2773C18.3541 10.2773 16.5376 11.0298 15.1983 12.3691C13.859 13.7084 13.1066 15.5249 13.1066 17.4189V22.4609C13.1059 22.7521 13.0162 23.0362 12.8495 23.275L11.6282 25.0318C11.4433 25.2367 11.3217 25.4908 11.2782 25.7633C11.2346 26.0358 11.2709 26.3151 11.3827 26.5675C11.4945 26.8198 11.677 27.0343 11.9082 27.1851C12.1393 27.3359 12.4092 27.4165 12.6852 27.4171H16.7488C16.9127 28.2243 17.3506 28.9501 17.9884 29.4713C18.6261 29.9926 19.4245 30.2773 20.2481 30.2773C21.0718 30.2773 21.8702 29.9926 22.5079 29.4713C23.1457 28.9501 23.5836 28.2243 23.7475 27.4171H27.8111C28.087 27.4165 28.3569 27.3359 28.5881 27.1851C28.8192 27.0343 29.0017 26.8198 29.1136 26.5675C29.2254 26.3151 29.2617 26.0358 29.2181 25.7633C29.1745 25.4908 29.0529 25.2367 28.868 25.0318ZM20.2481 28.8454C19.8061 28.8442 19.3753 28.7063 19.0147 28.4506C18.6542 28.1949 18.3815 27.8339 18.2342 27.4171H22.2621C22.1147 27.8339 21.8421 28.1949 21.4815 28.4506C21.121 28.7063 20.6902 28.8442 20.2481 28.8454ZM12.6852 25.9888C12.7175 25.9589 12.7462 25.9253 12.7709 25.8888L14.0207 24.0892C14.3541 23.6115 14.5335 23.0434 14.5349 22.4609V17.4189C14.5349 15.9037 15.1368 14.4505 16.2082 13.379C17.2797 12.3076 18.7329 11.7057 20.2481 11.7057C21.7634 11.7057 23.2166 12.3076 24.288 13.379C25.3595 14.4505 25.9614 15.9037 25.9614 17.4189V22.4609C25.9628 23.0434 26.1422 23.6115 26.4756 24.0892L27.7254 25.8888C27.75 25.9253 27.7788 25.9589 27.8111 25.9888H12.6852Z"
                        fill="#252C34"
                      />
                      {NoOfNotification > 0 && (
                        <circle
                          cx="34.4444"
                          cy="5.55556"
                          r="5.55556"
                          fill="#FA4F01"
                        />
                      )}
                    </svg>
                  </button>
                  <Link to={"/conversations"}>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="19.5"
                        fill="white"
                        stroke="#E5E7EB"
                      />
                      <path
                        d="M14 27.5C13.89 27.5001 13.7814 27.4758 13.682 27.4288C13.553 27.3683 13.4438 27.2724 13.3673 27.1522C13.2908 27.032 13.2501 26.8925 13.25 26.75V23.675C12.4035 23.5011 11.6429 23.0405 11.0964 22.3711C10.5499 21.7016 10.251 20.8642 10.25 20V14C10.2512 13.0058 10.6467 12.0527 11.3497 11.3497C12.0527 10.6467 13.0058 10.2512 14 10.25H21.5C22.4942 10.2512 23.4473 10.6467 24.1503 11.3497C24.8533 12.0527 25.2488 13.0058 25.25 14V20C25.2488 20.9942 24.8533 21.9473 24.1503 22.6503C23.4473 23.3533 22.4942 23.7488 21.5 23.75H18.7715L14.48 27.3267C14.3452 27.4388 14.1753 27.5001 14 27.5ZM14 11.75C13.4033 11.75 12.831 11.9871 12.409 12.409C11.9871 12.831 11.75 13.4033 11.75 14V20C11.75 20.5967 11.9871 21.169 12.409 21.591C12.831 22.0129 13.4033 22.25 14 22.25C14.1989 22.25 14.3897 22.329 14.5303 22.4697C14.671 22.6103 14.75 22.8011 14.75 23V25.1488L18.02 22.4233C18.1548 22.3112 18.3247 22.2499 18.5 22.25H21.5C22.0967 22.25 22.669 22.0129 23.091 21.591C23.5129 21.169 23.75 20.5967 23.75 20V14C23.75 13.4033 23.5129 12.831 23.091 12.409C22.669 11.9871 22.0967 11.75 21.5 11.75H14Z"
                        fill="#252C34"
                      />
                      <path
                        d="M26 29.75C25.8297 29.7501 25.6644 29.6922 25.5313 29.5858L21.9868 26.75H20C19.244 26.7485 18.5061 26.5186 17.883 26.0903C17.26 25.662 16.781 25.0554 16.5088 24.35C16.4717 24.2575 16.4534 24.1586 16.4549 24.059C16.4565 23.9593 16.4779 23.861 16.5178 23.7698C16.5578 23.6785 16.6156 23.5961 16.6877 23.5274C16.7599 23.4587 16.845 23.4051 16.9382 23.3697C17.0313 23.3342 17.1305 23.3177 17.2301 23.3211C17.3297 23.3244 17.4276 23.3476 17.5181 23.3892C17.6087 23.4309 17.69 23.4901 17.7574 23.5635C17.8247 23.6369 17.8768 23.723 17.9105 23.8168C18.0746 24.2379 18.3617 24.5998 18.7344 24.8555C19.1071 25.1111 19.5481 25.2486 20 25.25H22.25C22.4204 25.2499 22.5857 25.3078 22.7188 25.4142L25.25 27.4393V26C25.25 25.8011 25.3291 25.6103 25.4697 25.4697C25.6104 25.329 25.8011 25.25 26 25.25C26.5968 25.25 27.1691 25.0129 27.591 24.591C28.013 24.169 28.25 23.5967 28.25 23V18.5C28.25 17.9033 28.013 17.331 27.591 16.909C27.1691 16.4871 26.5968 16.25 26 16.25H24.5C24.3011 16.25 24.1104 16.171 23.9697 16.0303C23.8291 15.8897 23.75 15.6989 23.75 15.5C23.75 15.3011 23.8291 15.1103 23.9697 14.9697C24.1104 14.829 24.3011 14.75 24.5 14.75H26C26.9942 14.7512 27.9474 15.1467 28.6504 15.8497C29.3534 16.5527 29.7489 17.5058 29.7501 18.5V23C29.7491 23.8642 29.4502 24.7016 28.9037 25.3711C28.3572 26.0405 27.5966 26.5011 26.75 26.675V29C26.75 29.1989 26.671 29.3897 26.5304 29.5303C26.3897 29.671 26.199 29.75 26 29.75Z"
                        fill="#252C34"
                      />
                      <path
                        d="M14.75 18.125C15.3713 18.125 15.875 17.6213 15.875 17C15.875 16.3787 15.3713 15.875 14.75 15.875C14.1287 15.875 13.625 16.3787 13.625 17C13.625 17.6213 14.1287 18.125 14.75 18.125Z"
                        fill="#252C34"
                      />
                      <path
                        d="M17.75 18.125C18.3713 18.125 18.875 17.6213 18.875 17C18.875 16.3787 18.3713 15.875 17.75 15.875C17.1287 15.875 16.625 16.3787 16.625 17C16.625 17.6213 17.1287 18.125 17.75 18.125Z"
                        fill="#252C34"
                      />
                      <path
                        d="M21.5 18.125C22.1213 18.125 22.625 17.6213 22.625 17C22.625 16.3787 22.1213 15.875 21.5 15.875C20.8787 15.875 20.375 16.3787 20.375 17C20.375 17.6213 20.8787 18.125 21.5 18.125Z"
                        fill="#252C34"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="flex-col order-last lg:order-first terms_language_Login lg:flex-row lg:hidden text-[#6B7280]">
            <div className="flex-col menu_links_Login gap-y-11 lg:flex-row ">
              <Link to={hostsLink}>
                <p className="text-base font-medium text-[#6B7280]">
                  {t("Share your space")}
                </p>
              </Link>
              <Link to={suppliersLink}>
                <p className="text-base font-medium text-[#6B7280]">
                  {t("Supplier account")}
                </p>
              </Link>
            </div>
            <div className="flex-col w-full currency_language gap-y-11 lg:flex-row">
              {selectedLanguage == 0 && (
                <div className="currency_container">
                  <div
                    onClick={() =>
                      changeLanguage(language === "en" ? "ar" : "en")
                    }
                    className="text-[#6B7280] cursor-pointer language_Text_icon"
                  >
                    <p className="language_taxt font-medium text-[#6B7280]">
                      {language === "ar" ? "English" : "العربية"}
                    </p>
                    <img src="/globe-black.svg" alt="" />
                  </div>
                </div>
              )}
              <div className="hidden w-full md:block lg:opacity-0">
                {token && userInfo && (
                  <button
                    onClick={() => setShowLogoutModal(true)}
                    className="w-full gap-2 justify-center px-4 py-[0.625rem]  border border-gray-300 rounded-lg flex items-center"
                  >
                    <img src="/logout-red.svg" alt="" className="mt-[2px]" />
                    <span className="text-red-500">{t("logout")}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="mobile_navbar">
            <nav className="">
              <ul className="flex flex-col text-base font-medium List_links_container text-[#6B7280]">
                {navItems.map((item) => (
                  <li
                    key={item.name}
                    className={cn("LinkStyle_Login", {
                      "underline font-semibold text-black":
                        location.pathname.startsWith(item.path),
                    })}
                  >
                    <button
                      onClick={() => {
                        if (item.path === "/bookings" && !token && !userInfo) {
                          setShowModal(true);
                        } else {
                          navigate(item.path);
                        }
                      }}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div
            className="justify-center order-last hidden mx-auto cursor-pointer md:flex w-14"
            onClick={() => setShowBurgerLoginMenu(false)}
          >
            <svg
              className=""
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path d="M24 20L16 12L8 20" stroke="black" strokeWidth="2" />
            </svg>
          </div>
          <div className="flex flex-col order-first w-full">
            {!userInfo && !loadingUser && (
              <div className="flex w-full gap-4">
                <div
                  className="w-full cursor-pointer Get-started_container"
                  onClick={() => navigate("/get-started")}
                >
                  <Link to={"/get-started"}>{t("get started")}</Link>
                </div>
                <div
                  className="w-full cursor-pointer login_container"
                  style={{ backgroundColor: "unset" }}
                  onClick={() => navigate("/login")}
                >
                  <Link to={"/login"} className="login_black">
                    {t("login")}
                  </Link>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.125 9.125V7.375C6.125 4.95875 8.08375 3 10.5 3C12.9162 3 14.875 4.95875 14.875 7.375V9.125C15.8415 9.125 16.625 9.9085 16.625 10.875V15.25C16.625 16.2165 15.8415 17 14.875 17H6.125C5.1585 17 4.375 16.2165 4.375 15.25V10.875C4.375 9.9085 5.1585 9.125 6.125 9.125ZM13.125 7.375V9.125H7.875V7.375C7.875 5.92525 9.05025 4.75 10.5 4.75C11.9497 4.75 13.125 5.92525 13.125 7.375Z"
                      fill="#000021"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
          <div className="order-last w-full md:opacity-0 lg:opacity-100 lg:order-1">
            {token && userInfo && (
              <button
                onClick={() => setShowLogoutModal(true)}
                className="w-full gap-2 justify-center px-4 py-[0.625rem]  border border-gray-300 rounded-lg flex items-center"
              >
                <img src="/logout-red.svg" alt="" className="mt-[2px]" />
                <span className="text-red-500">{t("logout")}</span>
              </button>
            )}
          </div>
        </div>
      )}
      <div className="HomePage_Header">
        <div className="upperLogo_container">
          <FixedUpperNav isHostAccount={true} />
          <div className="currency_language">
            {selectedLanguage == 0 && (
              <div className="currency_container">
                <div
                  onClick={() =>
                    changeLanguage(language === "en" ? "ar" : "en")
                  }
                  className="cursor-pointer language_Text_icon"
                >
                  <div className="text-white language_taxt">
                    {language === "ar" ? "English" : "العربية"}
                  </div>
                  <img src="/Globe.png" alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full navBar_container_Login">
        <div className="w-full Login_nav_started">
          <div className="justify-between Login-nav-container 2xl:gap-8 xl:gap-4">
            <div className="items-center hidden gap-[32px] xl:flex">
              <Link
                className="Workey_logo_LOGIN w-[70px] md:w-[144px] h-[20px] md:h-[40px]"
                to={"/"}
              >
                <img src="/workeyLogo.png" alt="WORKEY_Logo" />
              </Link>

              {location.pathname == "/explore" && (
                <CitySearch home={false} border={false} />
              )}
            </div>

            <div className="flex w-full gap-8">
              <div className="flex items-center w-full gap-8 2xl:justify-center xl:w-2/3 jusitfy-center md:justify-center">
                <div className="w-full Login-nav-container 2xl:w-auto xl:hidden">
                  {token ? (
                    <div className="w-full userInfo xl:hidden">
                      {token && userInfo && (
                        <div className="relative flex items-center justify-between gap-2">
                          {isHovered && (
                            <div
                              ref={timeoutRef}
                              className="w-[12.5rem] cursor-pointer drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)] bg-[#252C34] px-[1.563rem] absolute ltr:top-14 ltr:left-24 rtl:top-14 rtl:right-24 py-[0.625rem]"
                            >
                              <div
                                onClick={() => {
                                  navigate("/profile");
                                }}
                                className="flex items-center gap-2"
                              >
                                <img src="/profile.svg" alt="" />
                                <p className="text-base font-normal text-white">
                                  {t("profile")}
                                </p>
                              </div>

                              <div className="h-[1px] bg-[#89D98B] my-[0.625rem]"></div>
                              <Link
                                to={"/favorites"}
                                className="flex items-center gap-2"
                              >
                                <img src="/favorite.svg" alt="" />
                                <p className="text-base font-normal text-white">
                                  {t("favorites")}
                                </p>
                              </Link>
                              <div className="h-[1px] bg-[#89D98B]  my-[0.625rem] "></div>
                              <div
                                onClick={() => setShowLogoutModal(true)}
                                className="flex items-center gap-2"
                              >
                                <img
                                  src="/signout.svg"
                                  alt=""
                                  className="mt-[4px]"
                                />
                                <p className="text-base font-normal text-white">
                                  {t("logout")}
                                </p>
                              </div>
                            </div>
                          )}
                          {!showBurgerLoginMenu ? (
                            <>
                              <Link className="Workey_logo_LOGIN" to={"/"}>
                                <img src="/workeyLogo.png" alt="WORKEY_Logo" />
                              </Link>
                              {location.pathname == "/explore" && (
                                <CitySearch home={false} border={false} />
                              )}
                            </>
                          ) : (
                            <>
                              <div className="flex items-center gap-2 cursor-pointer">
                                <Link to={"/profile"}>
                                  <span
                                    onMouseOver={() => setIsHovered(true)}
                                    className="text-lg font-medium text-black"
                                  >
                                    {userInfo?.first_name}
                                  </span>
                                </Link>
                                <Link to={"/profile"}>
                                  <img
                                    src={
                                      userInfo?.profile_image?.url ||
                                      "/profile-placeholder2.jpg"
                                    }
                                    alt="User"
                                    className="w-10 h-10 rounded-full"
                                    onMouseOver={() => setIsHovered(true)}
                                  />
                                </Link>
                              </div>
                              <div className="flex gap-1">
                                <button
                                  onClick={() =>
                                    setShowNotificationModal((prev) => !prev)
                                  }
                                >
                                  <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="20"
                                      cy="20"
                                      r="19.5"
                                      fill="white"
                                      stroke="#E5E7EB"
                                    />
                                    <path
                                      d="M28.868 25.0318L27.6468 23.275C27.4801 23.0362 27.3904 22.7521 27.3897 22.4609V17.4189C27.3897 15.5249 26.6373 13.7084 25.298 12.3691C23.9587 11.0298 22.1422 10.2773 20.2481 10.2773C18.3541 10.2773 16.5376 11.0298 15.1983 12.3691C13.859 13.7084 13.1066 15.5249 13.1066 17.4189V22.4609C13.1059 22.7521 13.0162 23.0362 12.8495 23.275L11.6282 25.0318C11.4433 25.2367 11.3217 25.4908 11.2782 25.7633C11.2346 26.0358 11.2709 26.3151 11.3827 26.5675C11.4945 26.8198 11.677 27.0343 11.9082 27.1851C12.1393 27.3359 12.4092 27.4165 12.6852 27.4171H16.7488C16.9127 28.2243 17.3506 28.9501 17.9884 29.4713C18.6261 29.9926 19.4245 30.2773 20.2481 30.2773C21.0718 30.2773 21.8702 29.9926 22.5079 29.4713C23.1457 28.9501 23.5836 28.2243 23.7475 27.4171H27.8111C28.087 27.4165 28.3569 27.3359 28.5881 27.1851C28.8192 27.0343 29.0017 26.8198 29.1136 26.5675C29.2254 26.3151 29.2617 26.0358 29.2181 25.7633C29.1745 25.4908 29.0529 25.2367 28.868 25.0318ZM20.2481 28.8454C19.8061 28.8442 19.3753 28.7063 19.0147 28.4506C18.6542 28.1949 18.3815 27.8339 18.2342 27.4171H22.2621C22.1147 27.8339 21.8421 28.1949 21.4815 28.4506C21.121 28.7063 20.6902 28.8442 20.2481 28.8454ZM12.6852 25.9888C12.7175 25.9589 12.7462 25.9253 12.7709 25.8888L14.0207 24.0892C14.3541 23.6115 14.5335 23.0434 14.5349 22.4609V17.4189C14.5349 15.9037 15.1368 14.4505 16.2082 13.379C17.2797 12.3076 18.7329 11.7057 20.2481 11.7057C21.7634 11.7057 23.2166 12.3076 24.288 13.379C25.3595 14.4505 25.9614 15.9037 25.9614 17.4189V22.4609C25.9628 23.0434 26.1422 23.6115 26.4756 24.0892L27.7254 25.8888C27.75 25.9253 27.7788 25.9589 27.8111 25.9888H12.6852Z"
                                      fill="#252C34"
                                    />
                                    {NoOfNotification > 0 && (
                                      <circle
                                        cx="34.4444"
                                        cy="5.55556"
                                        r="5.55556"
                                        fill="#FA4F01"
                                      />
                                    )}
                                  </svg>
                                </button>
                                <Link to={"/conversations"}>
                                  <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="20"
                                      cy="20"
                                      r="19.5"
                                      fill="white"
                                      stroke="#E5E7EB"
                                    />
                                    <path
                                      d="M14 27.5C13.89 27.5001 13.7814 27.4758 13.682 27.4288C13.553 27.3683 13.4438 27.2724 13.3673 27.1522C13.2908 27.032 13.2501 26.8925 13.25 26.75V23.675C12.4035 23.5011 11.6429 23.0405 11.0964 22.3711C10.5499 21.7016 10.251 20.8642 10.25 20V14C10.2512 13.0058 10.6467 12.0527 11.3497 11.3497C12.0527 10.6467 13.0058 10.2512 14 10.25H21.5C22.4942 10.2512 23.4473 10.6467 24.1503 11.3497C24.8533 12.0527 25.2488 13.0058 25.25 14V20C25.2488 20.9942 24.8533 21.9473 24.1503 22.6503C23.4473 23.3533 22.4942 23.7488 21.5 23.75H18.7715L14.48 27.3267C14.3452 27.4388 14.1753 27.5001 14 27.5ZM14 11.75C13.4033 11.75 12.831 11.9871 12.409 12.409C11.9871 12.831 11.75 13.4033 11.75 14V20C11.75 20.5967 11.9871 21.169 12.409 21.591C12.831 22.0129 13.4033 22.25 14 22.25C14.1989 22.25 14.3897 22.329 14.5303 22.4697C14.671 22.6103 14.75 22.8011 14.75 23V25.1488L18.02 22.4233C18.1548 22.3112 18.3247 22.2499 18.5 22.25H21.5C22.0967 22.25 22.669 22.0129 23.091 21.591C23.5129 21.169 23.75 20.5967 23.75 20V14C23.75 13.4033 23.5129 12.831 23.091 12.409C22.669 11.9871 22.0967 11.75 21.5 11.75H14Z"
                                      fill="#252C34"
                                    />
                                    <path
                                      d="M26 29.75C25.8297 29.7501 25.6644 29.6922 25.5313 29.5858L21.9868 26.75H20C19.244 26.7485 18.5061 26.5186 17.883 26.0903C17.26 25.662 16.781 25.0554 16.5088 24.35C16.4717 24.2575 16.4534 24.1586 16.4549 24.059C16.4565 23.9593 16.4779 23.861 16.5178 23.7698C16.5578 23.6785 16.6156 23.5961 16.6877 23.5274C16.7599 23.4587 16.845 23.4051 16.9382 23.3697C17.0313 23.3342 17.1305 23.3177 17.2301 23.3211C17.3297 23.3244 17.4276 23.3476 17.5181 23.3892C17.6087 23.4309 17.69 23.4901 17.7574 23.5635C17.8247 23.6369 17.8768 23.723 17.9105 23.8168C18.0746 24.2379 18.3617 24.5998 18.7344 24.8555C19.1071 25.1111 19.5481 25.2486 20 25.25H22.25C22.4204 25.2499 22.5857 25.3078 22.7188 25.4142L25.25 27.4393V26C25.25 25.8011 25.3291 25.6103 25.4697 25.4697C25.6104 25.329 25.8011 25.25 26 25.25C26.5968 25.25 27.1691 25.0129 27.591 24.591C28.013 24.169 28.25 23.5967 28.25 23V18.5C28.25 17.9033 28.013 17.331 27.591 16.909C27.1691 16.4871 26.5968 16.25 26 16.25H24.5C24.3011 16.25 24.1104 16.171 23.9697 16.0303C23.8291 15.8897 23.75 15.6989 23.75 15.5C23.75 15.3011 23.8291 15.1103 23.9697 14.9697C24.1104 14.829 24.3011 14.75 24.5 14.75H26C26.9942 14.7512 27.9474 15.1467 28.6504 15.8497C29.3534 16.5527 29.7489 17.5058 29.7501 18.5V23C29.7491 23.8642 29.4502 24.7016 28.9037 25.3711C28.3572 26.0405 27.5966 26.5011 26.75 26.675V29C26.75 29.1989 26.671 29.3897 26.5304 29.5303C26.3897 29.671 26.199 29.75 26 29.75Z"
                                      fill="#252C34"
                                    />
                                    <path
                                      d="M14.75 18.125C15.3713 18.125 15.875 17.6213 15.875 17C15.875 16.3787 15.3713 15.875 14.75 15.875C14.1287 15.875 13.625 16.3787 13.625 17C13.625 17.6213 14.1287 18.125 14.75 18.125Z"
                                      fill="#252C34"
                                    />
                                    <path
                                      d="M17.75 18.125C18.3713 18.125 18.875 17.6213 18.875 17C18.875 16.3787 18.3713 15.875 17.75 15.875C17.1287 15.875 16.625 16.3787 16.625 17C16.625 17.6213 17.1287 18.125 17.75 18.125Z"
                                      fill="#252C34"
                                    />
                                    <path
                                      d="M21.5 18.125C22.1213 18.125 22.625 17.6213 22.625 17C22.625 16.3787 22.1213 15.875 21.5 15.875C20.8787 15.875 20.375 16.3787 20.375 17C20.375 17.6213 20.8787 18.125 21.5 18.125Z"
                                      fill="#252C34"
                                    />
                                  </svg>
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <Link className="Workey_logo_LOGIN" to={"/"}>
                        <img src="/workeyLogo.png" alt="WORKEY_Logo" />
                      </Link>
                      {location.pathname == "/explore" && (
                        <CitySearch home={false} border={false} />
                      )}
                    </>
                  )}
                </div>
                <div className="hidden WORKEY_NAV_LINKS xl:flex">
                  <nav>
                    <ul className="List_links_container">
                      {navItems.map((item) => (
                        <li key={item.name} className="LinkStyle_Login">
                          <button
                            className={
                              location.pathname.startsWith(item.path)
                                ? "!underline !font-medium !text-black"
                                : ""
                            }
                            onClick={() => {
                              if (item.path === "/bookings" && !token) {
                                setShowModal(true);
                              } else {
                                navigate(item.path);
                              }
                            }}
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="!hidden Login_GetStarted_buttons xl:!flex">
                {userInfo && (
                  <div className="relative flex items-center gap-2">
                    {isHovered && (
                      <div
                        ref={timeoutRef}
                        className="w-[12.5rem] cursor-pointer drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)] bg-[#252C34] px-[1.563rem] absolute ltr:top-14 rtl:left-24 rtl:top-14  ltr:right-24 py-[0.625rem]"
                      >
                        <Link
                          to={"/profile"}
                          className="flex items-center gap-2"
                        >
                          <img src="/profile.svg" alt="" />
                          <p className="text-base font-normal text-white">
                            {t("profile")}
                          </p>
                        </Link>

                        <div className="h-[1px] bg-[#89D98B] my-[0.625rem]"></div>
                        <Link
                          to={"/favorites"}
                          className="flex items-center gap-2"
                        >
                          <img src="/favorite.svg" alt="" />
                          <p className="text-base font-normal text-white">
                            {t("favorites")}
                          </p>
                        </Link>
                        <div className="h-[1px] bg-[#89D98B]  my-[0.625rem] "></div>
                        <div
                          onClick={() => setShowLogoutModal(true)}
                          className="flex items-center gap-2"
                        >
                          <img src="/signout.svg" alt="" className="mt-[4px]" />
                          <p className="text-base font-normal text-white">
                            {t("logout")}
                          </p>
                        </div>
                      </div>
                    )}
                    <Link to={"/profile"}>
                      <span
                        onMouseOver={() => setIsHovered(true)}
                        className="font-medium text-[#252C34] text-lg"
                      >
                        {userInfo?.first_name}
                      </span>
                    </Link>
                    <div
                      className="w-10 h-10"
                      onClick={() => navigate("/profile")}
                    >
                      <img
                        src={
                          userInfo?.profile_image?.url ||
                          "/profile-placeholder2.jpg"
                        }
                        alt="User"
                        className="w-full h-full rounded-full cursor-pointer"
                        onMouseOver={() => setIsHovered(true)}
                      />
                    </div>
                    <button
                      onClick={() => setShowNotificationModal((prev) => !prev)}
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="20"
                          cy="20"
                          r="19.5"
                          fill="white"
                          stroke="#E5E7EB"
                        />
                        <path
                          d="M28.868 25.0318L27.6468 23.275C27.4801 23.0362 27.3904 22.7521 27.3897 22.4609V17.4189C27.3897 15.5249 26.6373 13.7084 25.298 12.3691C23.9587 11.0298 22.1422 10.2773 20.2481 10.2773C18.3541 10.2773 16.5376 11.0298 15.1983 12.3691C13.859 13.7084 13.1066 15.5249 13.1066 17.4189V22.4609C13.1059 22.7521 13.0162 23.0362 12.8495 23.275L11.6282 25.0318C11.4433 25.2367 11.3217 25.4908 11.2782 25.7633C11.2346 26.0358 11.2709 26.3151 11.3827 26.5675C11.4945 26.8198 11.677 27.0343 11.9082 27.1851C12.1393 27.3359 12.4092 27.4165 12.6852 27.4171H16.7488C16.9127 28.2243 17.3506 28.9501 17.9884 29.4713C18.6261 29.9926 19.4245 30.2773 20.2481 30.2773C21.0718 30.2773 21.8702 29.9926 22.5079 29.4713C23.1457 28.9501 23.5836 28.2243 23.7475 27.4171H27.8111C28.087 27.4165 28.3569 27.3359 28.5881 27.1851C28.8192 27.0343 29.0017 26.8198 29.1136 26.5675C29.2254 26.3151 29.2617 26.0358 29.2181 25.7633C29.1745 25.4908 29.0529 25.2367 28.868 25.0318ZM20.2481 28.8454C19.8061 28.8442 19.3753 28.7063 19.0147 28.4506C18.6542 28.1949 18.3815 27.8339 18.2342 27.4171H22.2621C22.1147 27.8339 21.8421 28.1949 21.4815 28.4506C21.121 28.7063 20.6902 28.8442 20.2481 28.8454ZM12.6852 25.9888C12.7175 25.9589 12.7462 25.9253 12.7709 25.8888L14.0207 24.0892C14.3541 23.6115 14.5335 23.0434 14.5349 22.4609V17.4189C14.5349 15.9037 15.1368 14.4505 16.2082 13.379C17.2797 12.3076 18.7329 11.7057 20.2481 11.7057C21.7634 11.7057 23.2166 12.3076 24.288 13.379C25.3595 14.4505 25.9614 15.9037 25.9614 17.4189V22.4609C25.9628 23.0434 26.1422 23.6115 26.4756 24.0892L27.7254 25.8888C27.75 25.9253 27.7788 25.9589 27.8111 25.9888H12.6852Z"
                          fill="#252C34"
                        />
                        {NoOfNotification > 0 && (
                          <circle
                            cx="34.4444"
                            cy="5.55556"
                            r="5.55556"
                            fill="#FA4F01"
                          />
                        )}
                      </svg>
                    </button>

                    <Link to={"/conversations"}>
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="20"
                          cy="20"
                          r="19.5"
                          fill="white"
                          stroke="#E5E7EB"
                        />
                        <path
                          d="M14 27.5C13.89 27.5001 13.7814 27.4758 13.682 27.4288C13.553 27.3683 13.4438 27.2724 13.3673 27.1522C13.2908 27.032 13.2501 26.8925 13.25 26.75V23.675C12.4035 23.5011 11.6429 23.0405 11.0964 22.3711C10.5499 21.7016 10.251 20.8642 10.25 20V14C10.2512 13.0058 10.6467 12.0527 11.3497 11.3497C12.0527 10.6467 13.0058 10.2512 14 10.25H21.5C22.4942 10.2512 23.4473 10.6467 24.1503 11.3497C24.8533 12.0527 25.2488 13.0058 25.25 14V20C25.2488 20.9942 24.8533 21.9473 24.1503 22.6503C23.4473 23.3533 22.4942 23.7488 21.5 23.75H18.7715L14.48 27.3267C14.3452 27.4388 14.1753 27.5001 14 27.5ZM14 11.75C13.4033 11.75 12.831 11.9871 12.409 12.409C11.9871 12.831 11.75 13.4033 11.75 14V20C11.75 20.5967 11.9871 21.169 12.409 21.591C12.831 22.0129 13.4033 22.25 14 22.25C14.1989 22.25 14.3897 22.329 14.5303 22.4697C14.671 22.6103 14.75 22.8011 14.75 23V25.1488L18.02 22.4233C18.1548 22.3112 18.3247 22.2499 18.5 22.25H21.5C22.0967 22.25 22.669 22.0129 23.091 21.591C23.5129 21.169 23.75 20.5967 23.75 20V14C23.75 13.4033 23.5129 12.831 23.091 12.409C22.669 11.9871 22.0967 11.75 21.5 11.75H14Z"
                          fill="#252C34"
                        />
                        <path
                          d="M26 29.75C25.8297 29.7501 25.6644 29.6922 25.5313 29.5858L21.9868 26.75H20C19.244 26.7485 18.5061 26.5186 17.883 26.0903C17.26 25.662 16.781 25.0554 16.5088 24.35C16.4717 24.2575 16.4534 24.1586 16.4549 24.059C16.4565 23.9593 16.4779 23.861 16.5178 23.7698C16.5578 23.6785 16.6156 23.5961 16.6877 23.5274C16.7599 23.4587 16.845 23.4051 16.9382 23.3697C17.0313 23.3342 17.1305 23.3177 17.2301 23.3211C17.3297 23.3244 17.4276 23.3476 17.5181 23.3892C17.6087 23.4309 17.69 23.4901 17.7574 23.5635C17.8247 23.6369 17.8768 23.723 17.9105 23.8168C18.0746 24.2379 18.3617 24.5998 18.7344 24.8555C19.1071 25.1111 19.5481 25.2486 20 25.25H22.25C22.4204 25.2499 22.5857 25.3078 22.7188 25.4142L25.25 27.4393V26C25.25 25.8011 25.3291 25.6103 25.4697 25.4697C25.6104 25.329 25.8011 25.25 26 25.25C26.5968 25.25 27.1691 25.0129 27.591 24.591C28.013 24.169 28.25 23.5967 28.25 23V18.5C28.25 17.9033 28.013 17.331 27.591 16.909C27.1691 16.4871 26.5968 16.25 26 16.25H24.5C24.3011 16.25 24.1104 16.171 23.9697 16.0303C23.8291 15.8897 23.75 15.6989 23.75 15.5C23.75 15.3011 23.8291 15.1103 23.9697 14.9697C24.1104 14.829 24.3011 14.75 24.5 14.75H26C26.9942 14.7512 27.9474 15.1467 28.6504 15.8497C29.3534 16.5527 29.7489 17.5058 29.7501 18.5V23C29.7491 23.8642 29.4502 24.7016 28.9037 25.3711C28.3572 26.0405 27.5966 26.5011 26.75 26.675V29C26.75 29.1989 26.671 29.3897 26.5304 29.5303C26.3897 29.671 26.199 29.75 26 29.75Z"
                          fill="#252C34"
                        />
                        <path
                          d="M14.75 18.125C15.3713 18.125 15.875 17.6213 15.875 17C15.875 16.3787 15.3713 15.875 14.75 15.875C14.1287 15.875 13.625 16.3787 13.625 17C13.625 17.6213 14.1287 18.125 14.75 18.125Z"
                          fill="#252C34"
                        />
                        <path
                          d="M17.75 18.125C18.3713 18.125 18.875 17.6213 18.875 17C18.875 16.3787 18.3713 15.875 17.75 15.875C17.1287 15.875 16.625 16.3787 16.625 17C16.625 17.6213 17.1287 18.125 17.75 18.125Z"
                          fill="#252C34"
                        />
                        <path
                          d="M21.5 18.125C22.1213 18.125 22.625 17.6213 22.625 17C22.625 16.3787 22.1213 15.875 21.5 15.875C20.8787 15.875 20.375 16.3787 20.375 17C20.375 17.6213 20.8787 18.125 21.5 18.125Z"
                          fill="#252C34"
                        />
                      </svg>
                    </Link>
                  </div>
                )}
                {loginNav && !userInfo && !loadingUser && (
                  <div className="Get-started_container_Login">
                    <Link to={"/get-started"}>{t("get started")}</Link>
                  </div>
                )}
                {!loginNav && !userInfo && !loadingUser && (
                  <div className="login_container_registeration">
                    <Link to={"/login"}>{t("login")}</Link>
                    <img src="/lock-closed-v.black.png" alt="" />
                  </div>
                )}
              </div>
            </div>
            <div className="block mobil_icon_humburger xl:hidden">
              <div
                className="cursor-pointer mobile_menu_Login"
                onClick={() => setShowBurgerLoginMenu((prev) => !prev)}
                disabled={disabled}
                style={
                  showBurgerLoginMenu
                    ? { backgroundImage: "url(/x.png)" }
                    : { backgroundImage: "url(/Menu.png)" }
                }
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginNavBar;
LoginNavBar.proptypes = null;
