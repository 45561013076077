import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";

function Logout({ setShowLogoutModal }) {
  const { t } = useTranslation("home");
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const apiBaseUrl = import.meta.env.VITE_BASE_URL;
  const { setUserInfo } = useContext(AuthContext);

  const logOut = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/v1/auth/logout`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }
      localStorage.removeItem("authToken");
      setUserInfo(null);
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-30">
      <div className="absolute  p-6 rounded-md bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
        <div className="w-[32rem] rounded-xl p-6">
          <div className="flex items-center justify-between mb-4">
            <p>{t("logout")}</p>
            <img
              src="/x.svg"
              alt=""
              className="mt-[-0.5rem] cursor-pointer"
              onClick={() => setShowLogoutModal(false)}
            />
          </div>
          <p className="mb-8">{t("are you sure you want to logout")}</p>
          <div className="flex items-center justify-end gap-2">
            <button
              onClick={() => setShowLogoutModal(false)}
              className="px-4 py-2 border rounded-md shadow-sm"
            >
              {t("no")}
            </button>
            <button
              onClick={logOut}
              className="rounded-md shadow-sm border px-4 py-2 text-white bg-[#EF4444]"
            >
              {t("yes,logout")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
Logout.propTypes = null;
