import { t } from "i18next";
import { Star } from "lucide-react";
import { Link } from "react-router-dom";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};

const SpaceCard = ({ space }) => (
  <div className="w-full rounded-lg shadow-lg h-[260px]">
    <div className="relative w-full h-[160px]">
      <img
        className="w-full h-[160px] object-cover rounded-t-lg"
        src={space?.media[0]?.url || "/api/placeholder/256/160"}
        alt={space?.name}
      />
      {space?.rating > 0 && (
        <div className="absolute flex items-center px-2 py-1 text-white bg-black bg-opacity-50 rounded-lg top-2 left-2">
          <span className="mr-1 font-bold text-md">{space.rating}</span>
          <Star className="w-4 h-4 text-yellow-400 fill-current" />
        </div>
      )}
    </div>
    <div className="p-4 bg-white">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-bold truncate">
          {truncateText(space?.name, 20)}
        </h3>
        <div className="text-right">
          <span className="text-xl font-bold">{space?.plan?.shift?.price}</span>
          <span className="ml-1 text-sm">
            {space?.plan?.shift?.currency?.label}
          </span>
        </div>
      </div>
      <div className="flex justify-between text-sm text-gray-600">
        <span>
          {t("capacity")} : {space?.quantity}
          {space?.quantity < 1 ? t("person") : t("persons")}
        </span>
        <span>{space?.plan?.name?.label}</span>
      </div>
    </div>
  </div>
);

const SpaceCardComponent = ({ spacesType }) => (
  <div className="gap-4 xl:px-[112px] lg:px-[70px] md:px[48px] sm:px-[16px] mt-10">
    <h2 className="mb-10 text-2xl font-bold ">
      {spacesType.type.label}({spacesType.spaces.length})
    </h2>
    <div className="grid gap-[24px] 2xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 md:grid-cols-2">
      {spacesType.spaces.map((space) => (
        <div className="" key={space.id}>
          <Link to={`/explore/space/${space.id}`}>
            <SpaceCard key={space.id} space={space} />
          </Link>
        </div>
      ))}
    </div>
  </div>
);

export default SpaceCardComponent;
