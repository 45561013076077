import { useContext, useCallback, useEffect, useState } from "react";
import { LocateFixedIcon, LocateOffIcon, Search } from "lucide-react";
import SearchContext from "../../../context/SearchContext";
import { useTranslation } from "react-i18next";

const CitySearch = ({ withBorder, home }) => {
  const { t } = useTranslation("workspace");
  const {
    searchTerm,
    selectedCity,
    selectedDistricts,
    filteredItems,
    showDropdown,
    setShowDropdown,
    isFocused,
    setIsFocused,
    setNearby,
    handleSearchChange,
    handleCitySelect,
    handleDistrictSelect,
    handleDistrictRemoval,
    clearSelection,
    Nearby,
    setSelectedCity,
  } = useContext(SearchContext);

  const [selectedText, setSelectedText] = useState("");

  const inputValue = selectedCity
    ? [selectedCity.name, ...selectedDistricts.map((d) => d.name)]
        .filter(Boolean)
        .join(", ") + (searchTerm ? ", " + searchTerm : ", ")
    : searchTerm;

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Backspace") {
        if (selectedText) {
          e.preventDefault();

          // Handle city deletion
          if (selectedCity && selectedText.includes(selectedCity.name)) {
            setSelectedCity(null);
            clearSelection();
            return;
          }

          // Handle district deletion with the new context function
          const districtsToRemove = selectedDistricts.filter((district) =>
            selectedText.includes(district.name)
          );

          if (districtsToRemove.length > 0) {
            handleDistrictRemoval(districtsToRemove);
          }

          // Handle search term
          if (searchTerm && selectedText.includes(searchTerm)) {
            handleSearchChange("");
          }

          setSelectedText("");
          return;
        }

        // Handle comma-based deletion
        if (inputValue !== "") {
          const lastCommaIndex = inputValue.lastIndexOf(",");

          if (
            lastCommaIndex !== -1 &&
            inputValue.slice(lastCommaIndex + 1).trim() === ""
          ) {
            if (selectedDistricts.length > 0) {
              e.preventDefault();
              const lastDistrict =
                selectedDistricts[selectedDistricts.length - 1];
              handleDistrictRemoval([lastDistrict]);
            } else if (selectedCity) {
              e.preventDefault();
              setSelectedCity(null);
              clearSelection();
            }
          }
        } else if (Nearby) {
          e.preventDefault();
          setNearby(false);
          clearSelection();
        }
      }
    },
    [
      inputValue,
      selectedText,
      selectedDistricts,
      selectedCity,
      searchTerm,
      Nearby,
      clearSelection,
      setNearby,
      handleDistrictRemoval,
      handleSearchChange,
      setSelectedCity,
    ]
  );

  const handleInputChange = (value) => {
    const lastCommaIndex = value.lastIndexOf(",");
    if (lastCommaIndex !== -1) {
      const searchValue = value.substring(lastCommaIndex + 1).trim();

      if (selectedCity || Nearby) {
        handleSearchChange(searchValue);
      } else {
        handleSearchChange(value);
      }
    } else {
      handleSearchChange(value);
    }
  };

  // Track text selection
  const handleSelection = useCallback(() => {
    const text = window.getSelection().toString();
    setSelectedText(text);
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", handleSelection);
    document.addEventListener("keyup", handleSelection);

    return () => {
      document.removeEventListener("mouseup", handleSelection);
      document.removeEventListener("keyup", handleSelection);
    };
  }, [handleSelection]);

  const NotEmpty =
    selectedCity || selectedDistricts.length > 0 || Nearby || searchTerm;

  const containerClasses = `flex items-center ${
    withBorder ? "border rounded-lg" : "border"
  } rounded-md relative ${
    isFocused || selectedCity ? "border-green-500" : "border-gray-300"
  }`;
  // console.log("filterditems", filteredItems);
  // console.log("selectedcity", selectedCity);
  // console.log("selecteddistrict", selectedDistricts);
  return (
    <div
      className={`relative  lg:opacity-100 ${
        home === "yes" ? "" : "xl:w-[16.25rem] w-full"
      }  ${withBorder ? "bg-[#F9FAFB] rounded-lg " : ""}`}
    >
      <div className={containerClasses}>
        {withBorder && (
          <div className="hidden text-gray-400 ltr:pl-2 sm:block rtl:pr-2">
            <Search size={18} color="gray" />
          </div>
        )}
        {home && (
          <p className="absolute ltr:left-1 rtl:right-1 top-1 text-sm font-medium ltr:sm:left-4 rtl:sm:right-4  text-[#6B7280]">
            {t("location")}
          </p>
        )}
        <input
          type="text"
          autoFocus={false}
          placeholder={t("search by location")}
          className={`${!withBorder ? "py-2  sm:px-4" : "py-[6px]"} text-xs ${
            home
              ? "placeholder:absolute placeholder:top-8 h-16"
              : "xl:!w-[16rem]"
          }  w-full  md:text-sm px-1 outline-none bg-transparent`}
          value={
            Nearby
              ? withBorder
                ? t("Search Nearby")
                : t("Use Current Location")
              : inputValue
          }
          onChange={(e) => {
            e.preventDefault();
            handleInputChange(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            setShowDropdown(true);
            setIsFocused(true);
          }}
          onBlur={() => {
            setTimeout(() => setShowDropdown(false), 200);
            setIsFocused(false);
          }}
        />

        {NotEmpty && (
          <button
            className="px-2 text-sm text-gray-500"
            onClick={clearSelection}
          >
            ×
          </button>
        )}

        {!NotEmpty && !withBorder && (
          <div className="Pin-icon ltr:right-2 ltr:md:right-4 rtl:left-2 rtl:md:left-4">
            <img src="/Pin.png" alt="" />
          </div>
        )}
      </div>

      {filteredItems.length > 0 && showDropdown && (
        <div className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border border-green-500 rounded-md shadow-md max-h-40">
          <div
            onClick={() => setNearby(!Nearby)}
            className="flex items-center gap-2 px-4 py-2 text-blue-500 border-b border-gray-300 cursor-pointer text-md hover:bg-gray-100 last:border-b-0"
          >
            {Nearby ? (
              <LocateOffIcon size={18} />
            ) : (
              <LocateFixedIcon size={18} />
            )}
            {!withBorder ? t("Use Current Location") : t("Search Nearby")}
          </div>
          {filteredItems.map((item) => (
            <div
              key={item.id}
              className="px-4 py-2 truncate border-b border-gray-300 cursor-pointer text-md text-bold hover:bg-gray-100 last:border-b-0"
              onClick={() =>
                selectedCity
                  ? handleDistrictSelect(item.id, item.name)
                  : handleCitySelect(item.id, item.name)
              }
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CitySearch;
CitySearch.propTypes = null;
